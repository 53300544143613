export const STATE = {
  balancerRequest: true,
  balancers: [],
  containers: [],
  current: '',
  currentMembers: [],
  sizePrefix: [],
  currentServer: {},
  defaultS3Key: {},
  dnsDefault: [],
  expense: [],
  files: [],
  userInfo: {},
  firewalls: [],
  multipartUploads: {},
  flavorPrice: [],
  flavorprofiles: {
    base: '6f4930e7-450b-46e7-b554-69a8c6d2b34b',

    advanced: '8368dd66-df00-47fd-8e80-3f190b95af43',
  },
  flavors: [],
  floatingips: [],
  healthMethod: ['CONNECT', 'DELETE', 'GET', 'HEAD', 'OPTIONS', 'PATCH', 'POST', 'PUT', 'TRACE'],
  healthType: ['HTTP', 'HTTPS', 'PING', 'SCTP', 'TCP', 'TLS-HELLO', 'UDP-CONNECT'],
  healthmonitors: [],
  images: [],
  isLoading: true,
  keypairs: [],
  lb_algorithm: [
    {
      name: 'LEAST_CONNECTIONS',
      key: '1',
      type: 'alg',
    },
    {
      name: 'ROUND_ROBIN',
      key: '2',
      type: 'alg',
    },
    {
      name: 'SOURCE_IP',
      key: '3',
      type: 'sticky',
    },
    {
      name: 'APP-cookie',
      key: '4',
      type: 'sticky',
    },
    {
      name: 'HTTP-cookie',
      key: '5',
      type: 'sticky',
    },
  ],
  list: [],
  listeners: [],
  localDisk: [],
  members: {},
  mode: 'grid',
  networks: [],
  noS3Key: true,
  objects: {},
  objectsOfCurrentStorage: [],
  pools: [],
  ports: [],
  prefix: [],
  price: [],
  privateImages: [],
  progressBar: 0,
  projects: [],
  quota_limits: {},
  quotasNetwork: [],
  quotasNetworkValue: [],
  quotasServer: [],
  quotasVolume: [],
  routers: [],
  rules: [],
  s3Keys: [],
  secrets: [],
  serverRequest: true,
  servers: [],
  snapshots: [],
  sort: {
    prop: 'status',
    order: 'asc',
    server: {
      prop: 'status',
      order: 'asc',
    },
    disk: {
      prop: 'status',
      order: 'asc',
    },
    image: {
      prop: 'status',
      order: 'asc',
    },
    snap: {
      prop: 'status',
      order: 'asc',
    },
    float: {
      prop: 'status',
      order: 'asc',
    },
    private: {
      prop: 'status',
      order: 'asc',
    },
    subnet: {
      prop: 'name',
      order: 'asc',
    },
    router: {
      prop: 'network_id',
      order: 'asc',
    },
    port: {
      prop: 'name',
      order: 'asc',
    },
    firewall: {
      prop: 'name',
      order: 'asc',
    },
    rule: {
      prop: 'direction',
      order: 'asc',
    },
    container: {
      prop: 'name',
      order: 'asc',
    },
    balancer: {
      prop: 'name',
      order: 'asc',
    },
    pool: {
      prop: 'name',
      order: 'asc',
    },
  },
  storageRequest: true,
  storages: [],
  storagesHeaders: {},
  subnets: [],
  tags: [],
  tools: {},
  totalSize: 0,
  typesOfDisks: {},
  updateMembers: true,
  volumes: [],
};
