import { Component } from 'vue';
import BaseButton from '@/components/Buttons/BaseButton.vue';

const ModalSizes = {
  small: 'small',
  medium: 'medium',
  big: 'big',
  large: 'large',
};

const DEFAULTS = {
  dismissable: true,
  size: 'medium',
  sizeTransition: true,
  hiddenOverflow: false,
  escapable: true,
  html: false,
  headers: false,
  closeOnBackdrop: true,
  heightLimit: null,
  on: {},
  props: {},
  onClose: () => {
    // do nothing by default
  },
  onDismiss: () => {
    // do nothing by default
  },
  onOpen: () => {
    // do nothing by default
  },
};

/**
 * @typedef ComponentListeners
 * @type {Object}
 * @prop {Function} prop
 */

/**
 * @typedef ComponentProps
 * @type {Object}
 * @prop {*} prop
 */

/**
 * @typedef ModalComponentConfig
 * @type {Object}
 * @prop {ComponentProps} props
 * @prop {ComponentListeners} on
 * @prop {Component} component
 */

/**
 * @class ModalComponent
 */
class ModalComponent {
  /**
   * @param {ModalComponentConfig} args
   */
  constructor(args) {
    this.component = args.component;
    this._init(args);
  }

  /**
   * @param {ComponentProps} value
   */
  set props(value) {
    this._props = { ...this._props, ...value };
  }
  /**
   * @returns {ComponentProps}
   */
  get props() {
    return this._props;
  }

  /**
   * @param {ComponentListeners} value
   */
  set on(value) {
    this._on = { ...this.on, ...value };
  }

  /**
   * @returns {ComponentListeners}
   */
  get on() {
    return this._on;
  }

  /**
   * Init
   * @param {Object} args
   * @param {ComponentProps} args.props
   * @param {ComponentListeners} args.on
   * @private
   */
  _init(args) {
    Object.keys(args).forEach(k => (this[k] = args[k]));
  }
}

const ACTIONS_DEFAULTS = {
  cancel: {
    color: 'primary',
    theme: 'outlined',
  },
  goto: {
    color: 'primary',
    theme: 'outlined',
  },
  confirm: {
    color: 'primary',
    theme: 'filled',
  },
};

const actionTypes = {
  cancel: 'cancel',
  confirm: 'confirm',
  goto: 'goto',
};

/**
 * @typedef ModalFooterConfig
 * @type {Object}
 * @prop {ModalComponent | boolean} [cancel]
 * @prop {ModalComponent | boolean} [goto]
 * @prop {ModalComponent | boolean} [confirm]
 * @prop {boolean=} [centered]
 * @prop {Object | boolean} [note]
 */

/**
 * @class ModalFooter
 */
class ModalFooter {
  /**
   *
   * @param {ModalFooterConfig} args
   */
  constructor(args) {
    this.cancel = args.cancel || false;
    this.goto = args.goto || false;
    this.confirm = args.confirm || false;
    this.centered = args.centered || false;
    this.note = args.note || false;
    this.top = args.top || false;
  }

  /**
   * @returns ModalComponent
   */
  get cancel() {
    return this._cancel;
  }
  get goto() {
    return this._goto;
  }

  /**
   * @param {ModalComponent | Object | boolean | undefined} args
   * @param {Object} args.props
   * @param {Object} args.on
   */
  set cancel(args) {
    if (this._cancel instanceof ModalComponent && typeof args === 'object') {
      this._cancel = this.updateButton(this._cancel, args);
    } else {
      this._cancel = args ? this.makeButton(actionTypes.cancel, args) : undefined;
    }
  }
  set goto(args) {
    if (this._goto instanceof ModalComponent && typeof args === 'object') {
      this._goto = this.updateButton(this._goto, args);
    } else {
      this._goto = args ? this.makeButton(actionTypes.goto, args) : undefined;
    }
  }

  /**
   * @returns ModalComponent
   */
  get confirm() {
    return this._confirm;
  }

  /**
   * @param {ModalComponent | Object | boolean | undefined} args
   * @param {Object} args.props
   * @param {Object} args.on
   */
  set confirm(args) {
    if (this._confirm instanceof ModalComponent && typeof args === 'object') {
      this._confirm = this.updateButton(this._confirm, args);
    } else {
      this._confirm = args ? this.makeButton(actionTypes.confirm, args) : undefined;
    }
  }

  get actions() {
    const list = {};
    if (this.confirm) list.confirm = this.confirm;
    if (this.goto) list.goto = this.goto;
    if (this.cancel) list.cancel = this.cancel;
    return list;
  }

  get top() {
    return this._top;
  }

  set top(args) {
    this._top = args && typeof args === 'object' ? new ModalComponent(args) : undefined;
  }

  /**
   *
   * @param {string} key cancel | confirm | goto
   * @param {Object | boolean} args
   * @param {ComponentProps | undefined} args.props
   * @param {ComponentListeners | undefined} args.on
   * @returns ModalComponent
   */
  makeButton(key, args) {
    const o = {
      component: BaseButton,
      props: {
        ...ACTIONS_DEFAULTS[key],
      },
    };

    if (typeof args === 'object' && (args.props || args.on)) {
      if (args.props) o.props = { ...o.props, ...args.props };
      if (args.on) o.on = { ...o.on, ...args.on };
    }
    return new ModalComponent(o);
  }

  /**
   *
   * @param {ModalComponent} button
   * @param {Object} args
   * @param {ComponentProps | undefined} args.props
   * @param {ComponentListeners | undefined} args.on
   * @returns ModalComponent
   */
  updateButton(button, args) {
    if (args.props) button.props = args.props;
    if (args.on) button.on = args.on;
    return button;
  }

  /**
   *
   * @param {Object} args
   * @param {ModalComponent | boolean | undefined} args.cancel
   * @param {ModalComponent | boolean | undefined} args.confirm
   * @param {ModalComponent | boolean | undefined} args.goto
   */
  update(args) {
    if (args[actionTypes.confirm]) this.confirm = args[actionTypes.confirm];
    if (args[actionTypes.cancel]) this.cancel = args[actionTypes.cancel];
    if (args[actionTypes.goto]) this.goto = args[actionTypes.goto];
  }
}

/**
 * @typedef ModalInstanceConfig
 * @type {Object}
 * @prop {string} name
 * @prop {string=} [title]
 * @prop {string=} [text]
 * @prop {ModalComponent=} [component]
 * @prop {boolean=} [dismissable]
 * @prop {boolean=} [escapable]
 * @prop {boolean=} [closeOnBackdrop]
 * @prop {boolean=} [closable] - set all dismissable, escapable, closeOnBackdrop
 * @prop {string} [size] - modalSizes
 * @prop {boolean} [sizeTransition] - true
 * @prop {string} [heightLimit] - limit height of body
 * @prop {ModalComponent=} [header]
 * @prop {ModalFooter=} [footer]
 * @prop {Function} [onClose]
 * @prop {Function} [onDismiss]
 * @prop {Function} [onOpen]
 * @prop {ComponentProps=} [props]
 * @prop {ComponentListeners=} [on]
 */

/**
 * @class ModalInstance
 * @extends ModalComponent
 */
export class ModalInstance extends ModalComponent {
  /**
   *
   * @param {ModalInstanceConfig} args
   */
  constructor(args) {
    super(args);
    this._initInstance(args);
  }

  /**
   *
   * @param {ModalInstanceConfig} args
   * @private
   */
  _initInstance(args) {
    const props = Object.assign({}, DEFAULTS, args);
    for (const prop in props) {
      if (prop != 'props' && prop != 'on' && prop != 'component') this[prop] = props[prop];
    }
    if (!props.header) {
      this.header = null;
    }
    if (!props.footer) {
      this.footer = null;
    }
  }

  /**
   * @param {ModalComponentConfig | null} args
   */
  set header(args) {
    this._header = args ? new ModalComponent(args) : null;
  }
  /**
   * @returns ModalComponent | null
   */
  get header() {
    return this._header;
  }

  /**
   * @param {ModalFooterConfig | null} args
   */
  set footer(args) {
    if (!args || (!args.cancel && !args.confirm && !args.goto)) {
      this._footer = null;
    } else if (this._footer && this._footer instanceof ModalFooter) {
      this._footer.update(args);
    } else {
      this._footer = new ModalFooter(args);
    }
  }

  /**
   * @returns ModalFooter | null
   */
  get footer() {
    return this._footer;
  }

  set closable(val) {
    this.escapable = val;
    this.dismissable = val;
    this.closeOnBackdrop = val;
  }
}
