<template>
  <div class="select-block">
    <!--      v-tooltip="config.disabled ? $t('restrictclientchange.on') : null"-->
    <base-select
      v-tooltip="{
        content: config.disabled ? $t('restrictclientchange.on') : null,
        placement: 'top-end',
        container: false,
      }"
      :value="internalValue"
      :allow-empty="false"
      :options="hardCodeFilter"
      :placeholder="$t('placeholder')"
      label="label"
      :show-labels="false"
      :disabled="disableBlock || config.disabled"
      :required="config.required || false"
      :size="size"
      use-reactive-validation
      :mode="mode"
      :hint="hint"
      :field-label="label"
      @input="onChange"
    />
    <!--    // (!isHiddenField && intname === 'ostemplate') ||-->
    <!--      :disabled="isNotPlesk || config.disabled"-->
  </div>
</template>

<script>
import BaseSelect from '@/components/Select/BaseSelect.vue';
import SelectComponent from '@/models/base/components/select';
export default {
  name: 'SelectBlock',
  components: { BaseSelect },
  props: {
    config: {
      type: Object,
      required: true,
      validator: obj =>
        obj instanceof SelectComponent || (obj.options && typeof obj.value !== 'undefined'),
    },
    filter: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: null,
    },
    intname: {
      type: String,
      default: null,
    },
    hint: {
      type: String,
      default: '',
    },
    lic: {
      type: String,
      default: 'none',
    },
    size: {
      type: String,
      default: 'medium',
      validator: value => ['small', 'medium'].includes(value),
    },
    mode: {
      type: String,
      default: 'default',
      validator: val => ['default', 'withimg', 'labelimg'].includes(val),
    },
  },
  data() {
    return {
      internalValue: this.config.internalValue,

      initInternalValue: {},
      autoprolondOptions: [
        { value: 'null', label: 'Отключено' },
        { value: '1', label: 'Включено' },
      ],
    };
  },
  computed: {
    disableBlock() {
      return (
        (this.intname === 'SupportLevel' ||
          this.intname === 'cms' ||
          this.intname === 'pleskpanel') &&
        this.isHiddenField
      );
    },
    isTrashHardChangeFor2TariffVps() {
      return (
        this.$route.name === 'vpsOrderDetails' &&
        [7821, 7721].includes(this.$route?.params?.id) &&
        this.intname === 'pleskpanel'
      );
    },
    isSupportLevel() {
      return this.intname === 'SupportLevel';
    },
    isHiddenField() {
      // return !this.filter && (this.intname === 'ostemplate' || this.intname === 'cms');
      return (
        (!this.filter && this.intname === 'cms') ||
        (!this.filter && this.intname === 'SupportLevel') ||
        this.isTrashHardChangeFor2TariffVps ||
        (!this.filter && this.intname === 'pleskpanel' && this.lic === 'Plesk')
      );
    },
    hardCodeFilter() {
      if (this.isTrashHardChangeFor2TariffVps)
        return this.config.options.filter(x => x.label.includes('нет'));
      else if (this.filter && this.lic === 'Plesk' && this.intname === 'ostemplate') {
        return this.config.options.filter(x => x.label.includes('Plesk'));
      } else if (this.filter && this.lic === 'ISP' && this.intname === 'ostemplate') {
        return this.config.options.filter(x => x.label.toLowerCase().includes('isp'));
      } else if (this.filter && this.intname === 'cms') {
        return this.config.options;
      } else if (!this.filter && this.intname === 'cms') {
        return this.config.options.filter(x => x.label.includes('нет'));
      } else if (!this.filter && this.intname === 'SupportLevel') {
        return this.config.options.filter(x => x.base);
      } else if (this.lic === 'none' && this.intname === 'ostemplate') {
        return this.config.options.filter(
          x => !x.label.includes('Plesk') && !x.label.toLowerCase().includes('isp')
        );
      } else if (!this.filter && this.lic === 'ISP') {
        return this.config.options.filter(x => !x.label.includes('Plesk'));
      } else if (!this.filter && this.lic === 'Plesk') {
        return this.config.options.filter(x => x.label.includes('Plesk'));
      } else if (this.label === 'Автопродление') {
        // попытка отключить сумму автопродления
        return this.autoprolondOptions;
      } else return this.config.options.filter(x => !x.label.includes('Plesk'));
    },
  },
  watch: {
    config(val) {
      if (val.value === 'qiwi') {
        this.internalValue = this.config.options.filter(x => x.value === 'qiwi');
      }
      if (val.value === 'sbp') {
        this.internalValue = this.config.options.filter(x => x.value === 'sbp');
      }
    },
    filter(val) {
      //хардкод для слежки за лицензи ей плеска и ISP
      if (this.isTrashHardChangeFor2TariffVps) {
        this.initInternalValue = this.config.options.find(x => x.label.includes('нет'));
        this.internalValue = this.config.options.find(x => x.label.includes('нет'));
        this.config.value = this.internalValue.value;
        this.$emit('change', {
          ...this.internalValue,
          value: this.internalValue.value,
          sum: this.internalValue.cost,
        });
      } else if (
        !val &&
        this.intname === 'cms' &&
        this.internalValue.value !== this.initInternalValue.value
      ) {
        this.internalValue = this.initInternalValue;
        this.config.value = this.internalValue.value;
        this.$emit('change', {
          ...this.internalValue,
          value: this.internalValue.value,
          sum: this.internalValue.cost,
        });
      } else if (
        !val &&
        this.intname === 'SupportLevel' &&
        this.internalValue.value !== this.initInternalValue.value
      ) {
        this.internalValue = this.initInternalValue;
        this.config.value = this.internalValue.value;
        this.$emit('change', {
          ...this.internalValue,
          value: this.internalValue.value,
          sum: this.internalValue.cost,
        });
      } else if (
        val &&
        this.intname === 'cms' &&
        this.internalValue.value !== this.initInternalValue.value
      ) {
      }
    },
    isTrashHardChangeFor2TariffVps: {
      handler: function (event) {
        if (event) {
          this.initInternalValue = this.config.options.find(x => x.label.includes('нет'));
          this.internalValue = this.config.options.find(x => x.label.includes('нет'));
          this.config.value = this.internalValue.value;
          this.$emit('change', {
            ...this.internalValue,
            value: this.internalValue.value,
            sum: this.internalValue.cost,
          });
        }
      },
      immediate: true,
    },

    lic(val) {
      // console.log(val);
      if (this.isTrashHardChangeFor2TariffVps) {
        this.initInternalValue = this.config.options.find(x => x.label.includes('нет'));
        this.internalValue = this.config.options.find(x => x.label.includes('нет'));
        this.config.value = this.internalValue.value;
        this.$emit('change', {
          ...this.internalValue,
          value: this.internalValue.value,
          sum: this.internalValue.cost,
        });
      } else if (val === 'Plesk' && this.intname === 'ostemplate') {
        this.initInternalValue = this.config.options.find(x => x.label.includes('Plesk'));
        this.internalValue = this.config.options.find(x => x.label.includes('Plesk'));
        this.config.value = this.internalValue.value;
        this.$emit('change', {
          ...this.internalValue,
          value: this.internalValue.value,
          sum: this.internalValue.cost,
        });
      } else if (val === 'ISP' && this.intname === 'ostemplate') {
        this.initInternalValue = this.config.options.find(x =>
          x.label.toLowerCase().includes('isp')
        );
        this.internalValue = this.config.options.find(x => x.label.toLowerCase().includes('isp'));
        this.config.value = this.internalValue.value;
        this.$emit('change', {
          ...this.internalValue,
          value: this.internalValue.value,
          sum: this.internalValue.cost,
        });
      } else if (val === 'none' && this.intname === 'ostemplate') {
        this.initInternalValue = this.config.options.find(
          x => !x.label.includes('Plesk') && !x.label.toLowerCase().includes('isp')
        );
        this.internalValue = this.config.options.find(
          x => !x.label.includes('Plesk') && !x.label.toLowerCase().includes('isp')
        );
        this.config.value = this.internalValue.value;
        this.$emit('change', {
          ...this.internalValue,
          value: this.internalValue.value,
          sum: this.internalValue.cost,
        });
      } else if (val === 'none' && this.intname === 'SupportLevel') {
        this.initInternalValue = this.config.options.find(x => x.base);
        this.internalValue = this.config.options.find(x => x.base);
        this.config.value = this.internalValue.value;
        this.$emit('change', {
          ...this.internalValue,
          value: this.internalValue.value,
          sum: this.internalValue.cost,
        });
      }
    },
  },
  mounted() {
    if (this.intname === 'countryPhone')
      this.internalValue = {
        code: '+7',
        image: 'https://bill.rusonyx.ru/manimg/common/flag/RU.png',
        label: 'Российская Федерация',
        mask: '+7 (999) 999-99-99',
        value: '182',
      };
    this.initInternalValue = this.internalValue;
    if (this.intname === 'SupportLevel') {
      this.$emit('change', {
        ...this.internalValue,
        value: this.internalValue.value,
        sum: this.internalValue.cost,
      });
    }
  },
  methods: {
    onChange(obj) {
      this.internalValue = obj;
      this.config.value = obj.value;
      this.$emit('change', { ...obj, value: obj.value, sum: obj.cost });
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "placeholder": "Выберите из списка"
  }
}
</i18n>
