<template>
  <div class="new-ticket-edo">
    <transition-group tag="div" name="fade" mode="out-in">
      <div key="title" class="l-col">
        <label class="standart-text">
          {{ $t('message.label') }}
        </label>
      </div>
      <div key="provider" class="l-col">
        <label class="standart-text">
          {{ $t('provider') }}
        </label>
        <base-input
          v-model="provider"
          type="text"
          maxlength="100"
          :required="true"
          :placeholder="$t('placeholder.provider')"
          class="paragraf-up"
        />
      </div>
      <div key="id" class="l-col">
        <label class="standart-text">
          {{ $t('id') }}
        </label>
        <base-input
          v-model="id"
          type="text"
          maxlength="50"
          :placeholder="$t('placeholder.id')"
          :required="true"
          :custom-error-messages="customValidationMsgs"
          autocomplete="off"
          use-reactive-validation
          :pattern="pattern ? id : 'false'"
          class="paragraf-up"
        />
      </div>
    </transition-group>
  </div>
</template>

<script>
import BaseInput from '@/components/BaseInput/BaseInput.vue';
import setFocus from '@/mixins/setFocus';
import BaseSelect from '@/components/Select/BaseSelect';
import vue2Dropzone from 'vue2-dropzone';
import AttachmentList from './AttachmentList';
import showErrorModal from '@/mixins/showErrorModal';
import BaseRadio from '@/components/Checkboxes/BaseRadio.vue';
import { NOTWORKDAY } from '../../../../public/config';
import { format } from 'date-fns';
import Vue from 'vue';
import { isEqual } from 'lodash';
export default {
  name: 'NewTicketEdo',
  components: {
    BaseInput,
  },
  mixins: [setFocus, showErrorModal],
  props: {
    ticketThemes: {
      type: String,
      default: '',
    },
    profile: { type: Object, default: () => ({}) },
    department: {
      type: String,
      default: '',
    },
    subjectValue: {
      type: String,
      default: '',
    },
    textValue: {
      type: String,
      default: '',
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      message: '',
      status: '',
      provider: '',
      id: '',
      sendingText: this.ticketText || '',
      params: {},
      customValidationMsgs: {
        confirm: { patternMismatch: this.$t('patternMismatch') },
      },
    };
  },
  computed: {
    hasChanges() {
      return !!this.subject || !!this.sendingText;
    },
    isValid() {
      return this.pattern && !!this.id && !!this.provider;
    },
    sendingMessage() {
      return `Оператор: ${this.provider}
Идентификатор: ${this.id}
      `;
    },
    pattern() {
      let regex = new RegExp('^[0-9a-zA-Z\-]+$');
      return regex.test(this.id);
    },
  },
  watch: {
    sendingMessage(event) {
      if (event && this.isValid)
        this.$emit('change', {
          message: this.sendingMessage,
          theme: this.ticketThemes,
          department: this.department,
          info: this.profile,
        });
    },
    hasChanges(val) {
      this.$emit('ready');
    },
    isValid(event) {
      if (!!event) {
        this.$emit('change', {
          message: this.sendingMessage,
          theme: this.ticketThemes,
          department: this.department,
          info: this.profile,
        });
      } else this.$emit('notready');
    },
  },
  mounted() {
    this.$emit('notready');
  },
  methods: {
    success() {
      this.tempAttachments = [];
      this.attachments = [];
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "provider": "Оператор:",
    "id": "Идентификатор:",
      "placeholder": {
        "provider": "Введите название оператора",
        "id": "Введите идентификатор оператора"
      },
    "message": {
      "label": "Введите ваши данные для подключения по ЭДО, и мы направим вам приглашение.",
      "placeholder": "Содержимое"
    },
    "add_file": "Загрузить файл",
    "patternMismatch": "Допускается комбинация цифр, латинских букв (заглавных и строчных) и тире.",
    "btn": {
      "send": "Отправить",
      "ok": "Понятно"
    },
    "error": {
      "title": "Добавление файла",
      "null": "Сообщение не должно быть пустым",
      "text": "Добавляемых файлов должно быть не более 5-ти, размер каждого не должен превышать 10 МБ и они должны отличаться друг от друга"
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.paragraf {
  margin-bottom: 10px;
  &-up{
    margin-top: 10px;

  }
}
</style>
