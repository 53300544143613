import { sortFuncsByOrder } from '@/utils/sortFunctions';
import { sortObjectsByOrder } from '@/utils/sortObjects';
import sortMapped from '@/utils/sortMapped';

export const GETTERS = {
  GET_SORTED_LIST: state => {
    const list = [...state.list];
    const { prop, order } = state.sort;
    list.sort((a, b) => sortFuncsByOrder[order](a[prop], b[prop]));
    return list;
  },
  GET_SORTED_LIST_SERVERS: state => {
    const list = [...state.servers];
    const { prop, order } = state.sort.server;
    list.sort((a, b) => {
      if (prop === 'name')
        return sortFuncsByOrder[order](a[prop].toLowerCase(), b[prop].toLowerCase());
      else return sortFuncsByOrder[order](a[prop], b[prop]);
    });
    return list;
  },
  GET_SORTED_LIST_CONTAINERS: state => {
    const list = [...state.containers];
    const { prop, order } = state.sort.container;
    list.sort((a, b) => {
      if (prop === 'name')
        return sortFuncsByOrder[order](a[prop].toLowerCase(), b[prop].toLowerCase());
      else return sortFuncsByOrder[order](a[prop], b[prop]);
    });
    return list;
  },
  GET_SORTED_LIST_FIREWALLS: state => {
    const list = [...state.firewalls];
    const { prop, order } = state.sort.firewall;
    list.sort((a, b) => {
      // console.log(a.name, b.name);
      if (prop === 'name')
        return sortFuncsByOrder[order](a?.[prop]?.toLowerCase(), b?.[prop]?.toLowerCase());
      else return sortFuncsByOrder[order](a?.[prop], b?.[prop]);
    });
    return list;
  },
  GET_SORTED_LIST_BALANCERS: state => {
    const list = [...state.balancers];
    const { prop, order } = state.sort.balancer;
    list.sort((a, b) => sortFuncsByOrder[order](a[prop], b[prop]));
    return list;
  },
  GET_SORTED_LIST_POOLS: state => {
    const list = [...state.pools];
    const { prop, order } = state.sort.pool;
    list.sort((a, b) => sortFuncsByOrder[order](a[prop], b[prop]));
    return list;
  },
  GET_SORTED_LIST_RULES: state => {
    const list = [...state.rules];
    const { prop, order } = state.sort.rule;
    list.sort((a, b) => {
      if (prop === 'name')
        return sortFuncsByOrder[order](a[prop].toLowerCase(), b[prop].toLowerCase());
      else return sortFuncsByOrder[order](a[prop], b[prop]);
    });
    return list;
  },
  GET_SORTED_LIST_DISKS: state => {
    const list = [...state.volumes];
    const { prop, order } = state.sort.disk;
    list.sort((a, b) => {
      if (prop === 'name')
        return sortFuncsByOrder[order](a[prop].toLowerCase(), b[prop].toLowerCase());
      else return sortFuncsByOrder[order](a[prop], b[prop]);
    });
    return list;
  },
  GET_SORTED_LIST_SNAP: state => {
    const list = [...state.snapshots];
    const { prop, order } = state.sort.snap;
    list.sort((a, b) => {
      if (prop === 'name')
        return sortFuncsByOrder[order](a[prop].toLowerCase(), b[prop].toLowerCase());
      else return sortFuncsByOrder[order](a[prop], b[prop]);
    });
    return list;
  },
  GET_SORTED_LIST_FLOAT: state => {
    const list = [...state.floatingips];
    const { prop, order } = state.sort.float;
    list.sort((a, b) => {
      if (prop === 'name')
        return sortFuncsByOrder[order](a[prop].toLowerCase(), b[prop].toLowerCase());
      else return sortFuncsByOrder[order](a[prop], b[prop]);
    });
    return list;
  },
  GET_SORTED_LIST_IMAGES: state => {
    const list = [...state.privateImages];
    const { prop, order } = state.sort.image;
    list.sort((a, b) => {
      if (prop === 'name')
        return sortFuncsByOrder[order](a[prop].toLowerCase(), b[prop].toLowerCase());
      else return sortFuncsByOrder[order](a[prop], b[prop]);
    });
    return list;
  },
  GET_SORTED_LIST_PORTS: state => {
    const list = [...state.ports];
    const { prop, order } = state.sort.port;
    prop === 'fixed_ips'
      ? list.sort((a, b) => sortObjectsByOrder[order](a[prop], b[prop]))
      : list.sort((a, b) => sortFuncsByOrder[order](a[prop], b[prop]));
    return list;
  },
  GET_SORTED_LIST_NETWORK: state => {
    const list = [...state.networks];
    const { prop, order } = state.sort.private;
    list.sort((a, b) => {
      if (prop === 'name')
        return sortFuncsByOrder[order](a[prop].toLowerCase(), b[prop].toLowerCase());
      else return sortFuncsByOrder[order](a[prop], b[prop]);
    });
    return list;
  },
  GET_SORTED_LIST_SUBNET: state => {
    const list = [...state.subnets];
    const { prop, order } = state.sort.subnet;
    list.sort((a, b) => {
      if (prop === 'name')
        return sortFuncsByOrder[order](a[prop].toLowerCase(), b[prop].toLowerCase());
      else return sortFuncsByOrder[order](a[prop], b[prop]);
    });
    return list;
  },
  GET_SORTED_LIST_ROUTER: state => {
    const list = [...state.ports];
    const { prop, order } = state.sort.router;
    prop === 'fixed_ips'
      ? list.sort((a, b) => sortObjectsByOrder[order](a[prop], b[prop]))
      : list.sort((a, b) => sortFuncsByOrder[order](a[prop], b[prop]));
    return list;
  },
  GET_CURRENT: state => {
    return state.current;
  },
  getStorages: state => {
    // return state.storages ? state.storages : [];
    return state.storages;
  },
  getObjectsOfCurrentStorage: state => {
    return state.objectsOfCurrentStorage;
  },
  // getS3Key: state => state.s3Keys[0],
  // getCurrentStorage: state => name => {
  //   console.log(name);
  //   return state.objects[name];
  // },
  GET_CURRENT_SERVER: state => {
    return state.current ? state.list.find(i => i.id === state.current) : {};
  },
  getCurrentServer: state => state.currentServer,
  project_id: state => (state.projects.length ? state.projects[0].id : null),
  servers: state => state.servers,
  aviableSnapshots: state => {
    try {
      return state.quotasVolume.maxTotalSnapshots - state.quotasVolume.totalSnapshotsUsed;
    } catch (e) {
      // console.log(e);
    }
  },
  aviableQuotaSnapshots: state =>
    +state.quota_limits.snapshots - state.quotasVolume.maxTotalSnapshots,
  aviableQuotaDisks: state => +state.quota_limits.volumes - state.quotasVolume.maxTotalVolumes,
  aviableSpaseVolume: state => {
    try {
      return state.quotasVolume.maxTotalVolumeGigabytes - state.quotasVolume.totalGigabytesUsed;
    } catch (e) {
      // console.log(e);
    }
  },
  aviableDisks: state => {
    try {
      return state.quotasVolume.maxTotalVolumes - state.quotasVolume.totalVolumesUsed;
    } catch (e) {
      // console.log(e);
    }
  },
  aviableCpu: state => {
    try {
      return state.quotasServer.cores.limit - state.quotasServer.cores.in_use;
    } catch (e) {
      // console.log(e);
    }
  },
  aviableRam: state => {
    try {
      return state.quotasServer.ram.limit - state.quotasServer.ram.in_use;
    } catch (e) {
      // console.log(e);
    }
  },
  snapshots: state => state.snapshots,
  getCurrentMembers: state => id => {
    return state.members[id];
  },
  images: state => state.images,
  imagesSort: state => state.images.sort((a, b) => b.os_distro - a.os_distro),
  disks: state => state.volumes,
  GET_FILES: state => {
    return state.files;
  },
  getTags: state => state.tags.sort(sortMapped),

  getMembers: state => {
    try {
      return state.currentMembers;
    } catch (e) {
      console.log(e);
    }
  },
};
