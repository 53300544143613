import Send from '@/components/VerificationSMS/Send.vue';
import SendCodeAdv from '@/components/VerificationSMS/SendCodeAdv.vue';
import Vue from 'vue';

export default {
  data() {
    return {
      phoneForVerification: '',
    };
  },
  computed: {
    needPhoneValidate() {
      return (
        this.$store.getters['moduleProfile/need_phone_validate'] ||
        this.$store.getters['moduleStart/phone'] === 'Проверить'
      );
    },
  },
  methods: {
    sendPhone(phone, code, route) {
      return new Promise(() => {
        const selfName = 'PhoneSend';
        this.$modals.open({
          name: selfName,
          size: 'small',
          component: Send,
          closeOnBackdrop: false,
          props: {
            phoneSend: phone,
          },
          text: this.$t('sure.text'),
          on: {
            phone: data => {
              {
                phone = data.replace('+', '+7');
              }
            },
          },
          onOpen: inst => (this.modal = inst),
          onClose: () => (this.modal = null),
          onDismiss: () => (this.modal = null),
          footer: {
            centered: true,
            confirm: {
              props: { title: this.$t('sure.confirm') },
              on: {
                click: () => {
                  this.phoneForVerification = phone;
                  this.sendPhoneNumber(phone).then(data => {
                    console.log(data);
                    if (data) {
                      this.$modals.close({ name: selfName });

                      setTimeout(() => this.openModalsCode(code, phone, route), 200);
                    }
                  });
                },
              },
            },
          },
        });
      });
    },
    sendPhoneNumber(phone) {
      return this.$store.dispatch('moduleProfile/sendPhoneNumber', phone).catch(e => {
        const msg =
          'Указанный Вами номер телефона уже был использован для активации учетной записи, номер телефона должен быть уникальным, укажите, пожалуйста, другой номер.';
        if (e.type === 'exists' && e.object === 'valid_phone') {
          this.showError(msg);
        } else this.showError(e);
      });
    },
    sendCode(code) {
      // console.log('code', code);
      // return code;
      return this.$store.dispatch('moduleProfile/sendCode', code).catch(e => this.showError(e));
    },
    openModalsCode(code, phone, route) {
      return new Promise(resolve => {
        const selfName = 'CodeSend';
        this.$modals.open({
          name: selfName,
          size: 'medium',
          // component: SendCode,
          component: SendCodeAdv,
          closeOnBackdrop: false,
          props: {
            phone: this.phoneForVerification,
          },
          on: {
            code: data => {
              code = data;
              resolve(true);
            },
          },
          onOpen: inst => (this.modal = inst),
          onClose: () => (this.modal = null),
          footer: {
            confirm: {
              props: { title: this.$t('sure.confirm') },
              on: {
                click: () => {
                  this.sendCode(code).then(data => {
                    if (data && data.ok) {
                      this.$modals.close({ name: selfName });
                      this.$store.dispatch('moduleProfile/setPhoneValidationResult');
                      // this.$store.dispatch('moduleStart/fetchDasboardInfo');
                      this.$store.dispatch('moduleStart/fetchDasboardInfo', {
                        func: 'dashboard.info',
                      });
                      this.showResModal(this.$t('sure.success'), data);
                    }
                  });
                },
              },
            },
            cancel: {
              props: { title: this.$t('sure.repeat') },
              on: {
                click: () => {
                  this.$modals.close({ name: selfName });
                  setTimeout(() => this.sendPhone(phone), 200);
                  if (route) this.$router.push({ name: route });
                },
              },
            },
          },
        });
      });
    },
    makeModal(props = {}) {
      this.$modals.open({
        name: 'AddModal',
        size: 'small',
        onOpen: inst => (this.modal = inst),
        onClose: () => (this.modal = null),
        onDismiss: () => (this.modal = null),
        ...props,
      });
    },
    verificationPhone(title, text, phone, code, props = {}) {
      if (!this.modal) this.makeModal(props);
      Vue.set(this.modal, 'size', 'medium');
      Vue.set(this.modal, 'component', null);
      Vue.set(this.modal, 'closable', true);
      Vue.set(this.modal, 'title', title);
      Vue.set(this.modal, 'text', text);
      Vue.set(this.modal, 'footer', {
        confirm: {
          props: { title: this.$t('sure.send') },
          on: {
            click: () => {
              this.$modals.close({ name: 'AddModal' });
              this.sendPhone(phone, code)
                .then(() => {})
                .finally(() => this.$modals.close);
            },
          },
        },
        cancel: {
          props: { title: this.$t('close') },
          on: {
            click: () => {
              this.$modals.close();
            },
          },
        },
      });
    },
    showResModal(res, props = {}) {
      if (!this.modal) this.makeModal(props);
      Vue.set(this.modal, 'component', null);
      Vue.set(this.modal, 'closable', true);
      Vue.set(this.modal, 'text', res);
      Vue.set(this.modal, 'footer', {
        cancel: {
          props: { title: this.$t('close') },
          on: {
            click: () => {
              this.$modals.close();
            },
          },
        },
      });
    },
  },
};
