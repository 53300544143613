import { parseDateRu } from '@/utils/parsers/dateParser';
import { parseCost } from '@/utils/parsers/costParser';
import { BillMgrStatus, BillMgrStatusSpecial } from './status';
import BaseConfigurator from '../base/BaseConfigurator';
import { BillMgrAddon } from './BillMgrAddon';

export class SSLTariff extends BaseConfigurator {
  constructor(args) {
    super(args, BillMgrAddon);
    if (this.isValid(args)) this._init(args);
  }

  isValid(args) {
    return args.id && args.pricelist && args.status;
  }

  _init(args) {
    this.id = +args.id;
    this.change_pricelist = args.change_pricelist;
    this.name = args.name.replace(/SSL Certificate -/gi, '');
    this.costsuffix = args.cost_suffix;
    this.pricelist = args.pricelist.replace(/SSL Certificate -/gi, '');
    this.cost = parseCost(args.cost);
    this.licid = args.licid;
    this.CN = args.CN;
    this.period = args.period;
    this.period_cost = args.period_cost || '';
    this.datacentername = args.datacentername;
    this.expiredate = Date.parse(args.expiredate) || parseDateRu(args.expiredate);
    this.createdate = Date.parse(args.createdate) || parseDateRu(args.createdate);
    this.in_pay = args.in_pay;
    this.prolong_available = args.prolong_available || 'off';
    this.employeesuspend = args.employeesuspend || 'off';
    this.abusesuspend = args.abusesuspend || 'off';
    this.order_disabled = args.order_disabled;
    this.subaccount = args.subaccount;
    this.transition = args.transition;
    this._history = [];
    // this.status = new BillMgrStatus(args.status);
    this.status = new BillMgrStatus(
      this.employeesuspend === 'on' ? 8 : this.abusesuspend === 'on' ? 7 : args.status
    );
    this.specialstatus = new BillMgrStatusSpecial(args.specialstatus);
    this.autoprolong_available = args.autoprolong_available || 'off';
    this.autoprolong_id = args.autoprolong_id || 'null';
    this.autoprolong_period = args.autoprolong_period || args.autoprolong_id || 'null';
    this.autoprolong = args.autoprolong || '';
    this.promise = args.promise || 'null';
    this.autoprolong_name = args.autoprolong || args.autoprolong_name || '';
  }

  update(args) {
    this._init(args);
  }

  get specs() {
    return {
      datacentername: this.datacentername,
      cost: this.cost,
    };
  }

  get title() {
    return this.name;
  }

  set info({ customfields, slist, model, details }) {
    this._info = {
      customfields,
      slist,
      model,
      details: null,
    };
    if (details && details.main.period_cost) this.period_cost = details.main.period_cost;
    // if (details) {
    //   const addons = details.addon.reduce((acc, i) => {
    //     acc[`addon_${i.id}`] = i;
    //     return acc;
    //   }, {});
    //   this._info.details = {
    //     main: details.main,
    //     addons,
    //   };
    // }
  } //TODO:remove

  get allow_processing_prolong() {
    return this.info && this.info.model && this.info.model.allow_processing_prolong
      ? this.info.model.allow_processing_prolong
      : this.prolong_available
      ? this.prolong_available
      : 'off';
  }

  get prolongAvailable() {
    if (this.employeesuspend === 'on' || this.abusesuspend === 'on') return false;
    return this.prolong_available ? this.prolong_available === 'on' : false;
  }

  get autoprolongAvailable() {
    if (this.employeesuspend === 'on' || this.abusesuspend === 'on') return false;
    return this.autoprolong_available ? this.autoprolong_available === 'on' : false;
  }

  get autoprolongSettings() {
    const value = this.autoprolong_period || this.info.model.autoprolong;
    return {
      list:
        this.info && this.info.model && this.info.slist.autoprolong
          ? this.info.slist.autoprolong
          : [],
      value,
      state: value && value !== 'null' ? 'on' : 'off',
      period: this.autoprolong || '',
    };
  }

  get info() {
    return this._info;
  }
  // get addons() {
  //   if (!this.info || !this.info.details || !this.info.details.addons) return null;
  //   return this.info.details.addons;
  // // }
  // get addons() {
  //   if (!this.info || !this.info.customfields) return null;
  //   return this.info.customfields;
  // }
  get addons() {
    return [
      {
        label: 'Доменное имя',
        value_d: this.CN,
      },
      {
        label: 'Дата создания',
        // value: this.textCreatedate,
        value: this.createdate,
      },
      {
        label: 'Истекает',
        // value: this.textExpiredate,
        value: this.expiredate,
      },
      // {
      //   label: 'Файлы сертификата',
      //   value_href: 'Тут будет ссылка',
      // },
    ];
  }
  //   textCreatedate() {
  //     return this.$t(`${this.isPast ? 'wasActiveUnder' : 'isActiveUnderOn'}`, {
  //       date: this.$d(this.tariff.createdate, 'middle'),
  //     });
  //   }
  //   textExpiredate() {
  //     return this.$t(`${this.isPast ? 'wasActiveUnder' : 'isActiveUnderOff'}`, {
  //       date: this.$d(this.tariff.expiredate, 'middle'),
  //     });
  //   }
  set history(elem) {
    this._history = elem;
  }

  get history() {
    return this._history;
  }
}
