export const STATE = {
  list: [],
  current: '',
  isLoading: true,
  sort: {
    prop: 'expiredate',
    order: 'asc',
  },
  mode: 'grid',
  countOfDeployMe: 0,
  tools: {},
  toolsGitFlic: {},
  toolsDeployMe: {},
};
