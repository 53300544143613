import BillMgrApi from '@/services/api/BillMgrApi';

export default {
  computed: {
    list() {
      return this.$store.state.moduleAll.list;
    },
    isLoading() {
      return this.$store.state.moduleAll.isLoading;
    },
  },
  methods: {
    fetchList: async function (item) {
      return new Promise((resolve, reject) => {
        const url = `/api2/billmgr/${item}`;
        const params = {
          metadata: 'on',
          newface: 'on',
          p_cnt: 1000,
          // ...payload,
        };
        BillMgrApi.get(url, { params })
          .then(async data => {
            let dataRaw = data?.elem;
            if (dataRaw) {
              dataRaw = dataRaw.map(x => {
                const obj = Object.assign({}, x);
                obj.func = item;

                return obj;
              });
              resolve(dataRaw);
            }
          })
          .catch(error => {
            reject(error);
          })
          .finally(async () => {});
      });
    },
    async fetchItems(payload) {
      return this.$store.dispatch('moduleAll/fetchItemDetail', payload).catch(e => console.log(e));
    },
  },
};
