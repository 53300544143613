import { StackTools } from '@/models/BillMgr/Tools';
import Vue from 'vue';
import { StackTariff } from '@/models/BillMgr/StackTariff';
import { OPTariff } from '@/models/OP/OPTariff';
import { OPState } from '@/models/OP/OPState';
import { OPTask } from '@/models/OP/OPTask';
import { BillMgrStatus } from '@/models/BillMgr/status';
import { IspExpense, IspExpenses } from '@/models/base/IspExpense';
import { OPBalancerStatusOperating, OPBalancerStatusProvisioning } from '@/models/OP/OPBalancer';
import { filterObject, filterObjectStorage } from '@/utils/postpaid';
import { OPStorage } from '@/models/OP/OPStorage';
import { CloudTariff } from '@/models/BillMgr/CloudTariff';
import { OPObject } from '@/models/OP/OPObgect';
// import {BillMgrStatus} from "@/models/BillMgr/status";
const sortOrders = ['asc', 'desc'];
const vpsModes = ['grid', 'table'];

export const MUTATIONS = {
  SET_LIST: (state, list) => {
    try {
      if (list) state.list = list.map(i => new StackTariff(i));
    } catch (e) {
      console.error(e);
    }
  },
  // setServerInfo: (state, tariff) => {
  //   try {
  //     if (tariff) state;
  //   } catch (e) {
  //     console.error(e);
  //   }
  // },
  SET_EXPENSE: (state, list) => {
    try {
      // state.expense = [...list];
      state.expense = list.map(i => new IspExpense(i));
    } catch (e) {
      console.error(e);
    }
  },
  SET_CURRENT: (state, payload) => {
    state.current = payload;
  },
  SET_LOCAL_DISK: (state, payload) => {
    // state.localDisk = payload;
    // state.localDisk.find(x => x.id === payload.id) ? state.localDisk.find(x => x.id === payload.id) : state.localDisk.push(payload)
    if (state.localDisk.find(x => x.id === payload.id)) {
      state.localDisk.find(x => x.id === payload.id).local = payload.existslocaldisk;
    } else {
      state.localDisk.push(payload);
    }
  },
  setPrice: (state, payload) => {
    state.price = payload;
  },
  setS3KeyState: (state, payload) => {
    state.noS3Key = payload;
  },
  setS3KeyDefault: (state, payload) => {
    state.defaultS3Key = Object.assign({}, payload);
  },

  setS3Keys: (state, payload) => {
    if (!state.defaultS3Key?.access || !state.defaultS3Key?.secret) {
      // console.log('set key');
      state.defaultS3Key = Object.assign({}, payload[0]);
    }
    state.s3Keys = payload;
  },
  setPrefixes: (state, payload) => {
    state.prefix = payload;
  },
  setDnsNames: (state, payload) => {
    state.dnsDefault = payload;
  },
  getBmQuotas: (state, payload) => {
    if (payload.public_network && !state.quotasNetworkValue.find(x => x.name === 'public'))
      state.quotasNetworkValue.push({ name: 'public', value: +payload.public_network });
    state.quota_limits = payload;
  },
  setPriceFlavor: (state, payload) => {
    state.flavorPrice = payload;
  },
  SET_OTHER_INFO_ABOUT_TARIFF: (state, { prop, value, id }) => {
    const innerId = id || state.current;
    const tariff = state.list.find(i => i.id === innerId);
    if (tariff && tariff instanceof StackTariff) {
      Vue.set(tariff, prop, value);
    }
  },
  SET_STATUS: (state, payload) => {
    if (state.list.filter(x => x.id === payload.id).length > 0)
      state.list.filter(x => x.id === payload.id)[0].status = new BillMgrStatus(payload.status);
  },
  SET_IS_LOADING: (state, value) => {
    state.isLoading = value;
  },
  setUpdateMembers: (state, value) => {
    state.updateMembers = value;
  },
  SET_SORT: (state, payload) => {
    if (payload.prop && sortOrders.includes(payload.order)) {
      state.sort = payload;
    }
  },
  SET_SERVER_SORT: (state, payload) => {
    if (payload.prop && sortOrders.includes(payload.order)) {
      state.sort.server = payload;
    }
  },
  SET_FIREWALL_SORT: (state, payload) => {
    if (payload.prop && sortOrders.includes(payload.order)) {
      state.sort.firewall = payload;
    }
  },
  SET_RULE_SORT: (state, payload) => {
    if (payload.prop && sortOrders.includes(payload.order)) {
      state.sort.rule = payload;
    }
  },
  SET_SUBNET_SORT: (state, payload) => {
    if (payload.prop && sortOrders.includes(payload.order)) {
      state.sort.subnet = payload;
    }
  },
  SET_ROUTER_SORT: (state, payload) => {
    // console.log(payload);
    if (payload.prop && sortOrders.includes(payload.order)) {
      state.sort.router = payload;
    }
  },
  SET_DISK_SORT: (state, payload) => {
    if (payload.prop && sortOrders.includes(payload.order)) {
      state.sort.disk = payload;
    }
  },
  SET_CONTAINER_SORT: (state, payload) => {
    if (payload.prop && sortOrders.includes(payload.order)) {
      state.sort.container = payload;
    }
  },
  SET_IMAGE_SORT: (state, payload) => {
    if (payload.prop && sortOrders.includes(payload.order)) {
      state.sort.image = payload;
    }
  },
  SET_NETWORK_SORT: (state, payload) => {
    if (payload.prop && sortOrders.includes(payload.order)) {
      state.sort.private = payload;
    }
  },
  SET_BALANCER_SORT: (state, payload) => {
    if (payload.prop && sortOrders.includes(payload.order)) {
      state.sort.balancer = payload;
    }
  },
  SET_FLOAT_SORT: (state, payload) => {
    if (payload.prop && sortOrders.includes(payload.order)) {
      state.sort.float = payload;
    }
  },
  SET_SNAP_SORT: (state, payload) => {
    if (payload.prop && sortOrders.includes(payload.order)) {
      state.sort.snap = payload;
    }
  },
  SET_POOL_SORT: (state, payload) => {
    if (payload.prop && sortOrders.includes(payload.order)) {
      state.sort.pool = payload;
    }
  },
  SET_PORT_SORT: (state, payload) => {
    if (payload.prop && sortOrders.includes(payload.order)) {
      state.sort.port = payload;
    }
  },
  SET_MODE: (state, value) => {
    if (vpsModes.includes(value)) state.mode = value;
  },
  SET_TOOLS: (state, value) => {
    try {
      state.tools = new StackTools(value);
    } catch (e) {
      console.error(e);
    }
  },
  setCurrentServer: (state, payload) => {
    state.currentServer = payload;
  },
  resetCurrentServer: state => {
    state.currentServer = {};
    // console.log(state.currentServer);
  },
  updateStatus: (state, payload) => {
    if (state[payload.type].find(x => x.id === payload.id)) {
      state[payload.type].find(x => x.id === payload.id).status = new OPState(
        payload.status.toLowerCase().split('-').join('')
      );
    }
  },
  updateServerTags: (state, payload) => {
    // console.log(payload);
    const oldTags = state.tags;
    const newTags = payload.tags;
    state.tags = Array.from(new Set(oldTags.concat(newTags))).sort((a, b) => a - b);

    if (state[payload.type].find(x => x.id === payload.id)) {
      state[payload.type].find(x => x.id === payload.id).tags = payload.tags;
    }
    // payload.tags.forEach(el => {
    // state[payload.type].find(x => x.id === payload.id).tags.forEach(el => {

    // if (state[payload.type].find(x => x.id === payload.id).tags === el)

    // })
  },
  updateBalancerStatus: (state, payload) => {
    // console.log('updateBalancerStatus', payload);
    if (state[payload.type].find(x => x.id === payload.id)) {
      state[payload.type].find(x => x.id === payload.id).provisioning_status =
        new OPBalancerStatusProvisioning(
          // payload.status.toLowerCase().split('-').join('')
          payload.status.split('-').join('')
        );
      state[payload.type].find(x => x.id === payload.id).operating_status =
        new OPBalancerStatusOperating(
          // payload.state.toLowerCase().split('-').join('')
          payload.state.split('-').join('')
        );
    }
  },
  updatePoolStatus: (state, payload) => {
    // console.log('updatePoolStatus', payload);
    if (state[payload.type].find(x => x.id === payload.id)) {
      state[payload.type].find(x => x.id === payload.id).provisioning_status =
        new OPBalancerStatusProvisioning(
          // payload.status.toLowerCase().split('-').join('')
          payload.status.split('-').join('')
        );
      state[payload.type].find(x => x.id === payload.id).operating_status =
        new OPBalancerStatusOperating(
          // payload.state.toLowerCase().split('-').join('')
          payload.state.split('-').join('')
        );
    }
  },
  updateTask: (state, payload) => {
    // console.log(payload);
    if (state[payload.type].find(x => x.id === payload.id)) {
      state[payload.type].find(x => x.id === payload.id).task = new OPTask(
        // payload.status.toLowerCase().split('-').join('')
        payload.task ? payload.task : 'нет'
      );
    }
  },
  updateImage: (state, payload) => {
    state[payload.type].find(x => x.id === payload.id).size = payload.size;
  },
  updateCurrentImage: (state, payload) => {
    state.privateImages.filter(x => x.id === payload.id)[0] = Object.assign(
      {},
      new OPTariff(payload.data)
    );
    state.privateImages.find(x => x.id === payload.id).tags = payload.data.tags;
  },
  updateImageTag: (state, payload) => {
    if (payload.method === 'put')
      state[payload.type].find(x => x.id === payload.id).tags.push(payload.tags);
    else
      state[payload.type].find(x => x.id === payload.id).tags = state[payload.type]
        .find(x => x.id === payload.id)
        .tags.filter(x => x !== payload.tags);
  },
  updateAttachment: (state, payload) => {
    if (state[payload.type].find(x => x.id === payload.id))
      state[payload.type].find(x => x.id === payload.id).attachments = payload.attachments;
  },
  updateFlavors: (state, payload) => {
    state.servers.find(x => x.id === payload.id).flavor = state.flavors.find(
      x => x.id === payload.flavor
    );
  },
  updateDisk: (state, payload) => {
    if (state.volumes.find(x => x.id === payload.id))
      state.volumes.find(x => x.id === payload.id).size = payload.size;
  },
  SET_FILES: (state, files) => {
    try {
      state.files.push(files);
    } catch (e) {
      console.error(e);
    }
  },
  DELETE_FILES: (state, key) => {
    try {
      state.files.splice(key, 1);
    } catch (e) {
      console.error(e);
    }
  },
  RESET_FILES: state => {
    state.files.length = 0;
  },
  UPDATE_LIST: (state, list) => {
    // console.log(list);
    const compareList = list.map(i => ({ id: i.id, isset: false, data: { ...i } }));
    const filteredOldList = state.list.reduce((acc, item) => {
      const compare = compareList.find(i => i.id == item.id);
      if (!!compare) {
        item.update(compare.data);
        acc.push(item);
        compare.isset = true;
      }
      return acc;
    }, []);
    const newList = compareList.filter(i => !i.isset).map(i => new StackTariff(i.data));
    state.list = [...filteredOldList, ...newList];
  },
  servers: (state, { payload, action }) => {
    state.servers = payload.map(i => new OPTariff(i, action));
  },
  tags: (state, payload) => {
    // console.log('tags', payload);
    state.tags = payload || [];
  },
  loadbalancers: (state, { payload, action }) => {
    try {
      state.balancers = payload.map(i => new OPTariff(i, action));
    } catch (e) {
      state.balancers = [];
    }
  },
  object: (state, { payload, id }) => {
    // console.log(payload, id);
    if (payload)
      try {
        state.objects[id] = payload.map(i => new OPObject(i));
        state.objectsOfCurrentStorage = payload.map(i => new OPObject(i));
      } catch (e) {
        console.log(e);
      }
  },
  setPrefixSize: (state, { bucket, prefix, size, count, keys, isPrefix }) => {
    const storageOfPrefix = state.sizePrefix.find(x => x.bucket === bucket && x.prefix === prefix);
    if (storageOfPrefix) {
      storageOfPrefix.size = size;
      storageOfPrefix.count = count;
    } else {
      state.sizePrefix.push({
        bucket: bucket,
        prefix: prefix,
        isPrefix: isPrefix,
        size: size,
        count: count,
        keys: keys,
      });
    }
    const storage = state.storages.find(x => x.name === bucket);
    if (storage) {
      storage.objects.forEach(obj => {
        if (obj.prefix === prefix) {
          obj.size = size;
          obj.count = count;
        }
      });
    }
  },
  newObject: (state, { payload, id }) => {
    // console.log(payload, id);
    if (payload)
      try {
        state.objects[id] = payload.map(i => {
          // console.log(i);
          return new OPObject(i);
        });
        state.objectsOfCurrentStorage = payload.map(i => new OPObject(i));
      } catch (e) {
        console.log(e);
      }
  },
  updateMultipartUploads: (state, { payload, id }) => {
    console.log(payload, id);
    state.multipartUploads[id] = payload;
  },
  setUserInfo: (state, { payload }) => {
    state.userInfo = payload;
  },
  updateObject: (state, { prop, value, storage, object }) => {
    // console.log(prop, value, storage, object);
    // console.log(state.storages.map(x => x.name));
    const tariff = state.storages.find(x => x.name === storage).objects.find(x => x.key === object);
    // console.log(tariff);

    // const tariff = state.objects[storage].find(i => i.name === object);
    // const testTariff = state.objectsOfCurrentStorage.find(i => i.name === object);
    if (tariff && tariff instanceof OPObject) {
      // console.log(tariff, 'update');
      Vue.set(tariff, prop, value);
    }
    // if (testTariff && testTariff instanceof OPObject) {
    // console.log(testTariff, 'update');
    // Vue.set(testTariff, prop, value);
    // }
  },
  updateStorage: (state, { prop, value, id }) => {
    const tariff = state.storages.find(x => x.name === id);
    // console.log(tariff, prop, value);
    if (tariff && tariff instanceof OPStorage) {
      // console.log(tariff, prop, value);
      Vue.set(tariff, prop, value);
    }
  },

  updateTypeStorage: (state, { prop, value, id }) => {
    const tariff = state.storages.find(x => x.name === id);
    // console.log(tariff, prop, value);
    if (tariff && tariff instanceof OPStorage) {
      Vue.set(tariff, prop, value);
    }
  },

  storages: (state, { payload, action }) => {
    // console.log(payload, action);
    try {
      state.storages = payload.map(i => new OPStorage(i, action));
    } catch (e) {
      state.storages = [];
    }
  },
  resetStorage: state => {
    console.log('resetStorage');
    state.storages = [];
    state.defaultS3Key = {};
    state.objects = [];
    state.s3Keys = [];
  },
  secrets: (state, { payload, action }) => {
    state.secrets = payload.map(i => new OPTariff(i, action));
  },
  containers: (state, { payload, action }) => {
    state.containers = payload.map(i => new OPTariff(i, action));
  },
  floatingips: (state, { payload, action }) => {
    state.floatingips = payload.map(i => new OPTariff(i, action));
  },
  flavors: (state, { payload, action }) => {
    state.flavors = payload.map(i => new OPTariff(i, action));
  },
  ports: (state, { payload, action }) => {
    state.ports = payload.map(i => new OPTariff(i, action));
  },
  volumes: (state, { payload, action }) => {
    state.volumes = payload.map(i => new OPTariff(i, action));
  },
  networks: (state, { payload, action }) => {
    state.networks = payload.map(i => new OPTariff(i, action));
  },
  images: (state, { payload, action }) => {
    state.images = payload
      .sort((a, b) => (a.name < b.name && -1) || (a.name > b.name && 1) || 0)
      .map(i => new OPTariff(i, action));
  },
  routers: (state, { payload, action }) => {
    state.routers = payload.map(i => new OPTariff(i, action));
  },
  pools: (state, { payload, action }) => {
    try {
      state.pools = payload.map(i => new OPTariff(i, action));
    } catch {
      state.pools = [];
    }
  },
  members: (state, { payload, action }) => {
    state.members = payload.map(i => new OPTariff(i, action));
  },
  listeners: (state, { payload, action }) => {
    try {
      state.listeners = payload.map(i => new OPTariff(i, action));
    } catch {
      state.listeners = [];
    }
  },
  flavorprofiles: (state, { payload, action }) => {
    state.flavorprofiles = payload.map(i => new OPTariff(i, action));
  },
  firewalls: (state, { payload, action }) => {
    state.firewalls = payload.map(i => new OPTariff(i, action));
    // console.log(state.firewalls);
  },
  rules: (state, { payload, action }) => {
    state.rules = payload.map(i => new OPTariff(i, action));
  },
  subnets: (state, { payload, action }) => {
    state.subnets = payload.map(i => new OPTariff(i, action));
  },
  healthmonitors: (state, { payload, action }) => {
    try {
      state.healthmonitors = payload.map(i => new OPTariff(i, action));
    } catch {
      state.healthmonitors = [];
    }
  },
  snapshots: (state, { payload, action }) => {
    state.snapshots = payload.map(i => new OPTariff(i, action));
  },
  privateImages: (state, { payload, action }) => {
    state.privateImages = payload.map(i => new OPTariff(i, action));
  },
  updateNetwork: (state, payload) => {
    state.networks.find(network => network.id === payload.id)[payload.item] = payload.state;
  },
  updateNetworkDescription: (state, payload) => {
    state.networks.find(network => network.id === payload.id)[payload.item] = payload.state;
  },
  updateBalancers: (state, payload) => {
    Object.keys(payload).forEach(key => {
      state.balancers.find(balancer => balancer.id === payload.id)[key] = payload[key];
    });
  },
  updateHealthMonitor: (state, payload) => {
    Object.keys(payload).forEach(key => {
      state.healthmonitors.find(hm => hm.id === payload.id)[key] = payload[key];
    });
  },
  updateListner: (state, payload) => {
    Object.keys(payload).forEach(key => {
      state.listeners.find(hm => hm.id === payload.id)[key] = payload[key];
    });
  },
  updatePool: (state, payload) => {
    const find = state.pools.find(pool => pool.id === payload.id);
    if (find) {
      Object.keys(payload).forEach(key => {
        if (key === 'provisioning_status') {
          find[key] = new OPBalancerStatusProvisioning(payload[key].split('-').join(''));
        } else if (key === 'operating_status') {
          find[key] = new OPBalancerStatusOperating(payload[key].split('-').join(''));
        } else find[key] = payload[key];
      });
    } else {
      state.pools.push(new OPTariff(payload, 'pools'));
    }
  },
  updateMember: (state, { payload, action }) => {
    state.members[payload.id] = payload.data.map(i => new OPTariff(i, action));
  },
  updateRuleMember: (state, payload) => {
    if (payload.delete) {
      state.members[payload.ruleId] = state.members[payload.ruleId].filter(
        x => x.id !== payload.memberId
      );
    } else {
      Object.keys(payload.state).forEach(key => {
        state.members[payload.ruleId].filter(x => x.id === payload.state.id)[0][key] =
          payload.state[key];
      });
    }
  },
  updateRouter: (state, payload) => {
    state.routers.find(router => router.id === payload.id)[payload.item] = payload.state;
  },
  updateFirewall: (state, payload) => {
    state.firewalls.find(firewall => firewall.id === payload.id)[payload.item] = payload.state;
  },
  updateFirewallDescription: (state, payload) => {
    state.firewalls.find(firewall => firewall.id === payload.id)[payload.item] = payload.state;
  },
  updateSubnets: (state, payload) => {
    state.subnets.find(subnet => subnet.id === payload.id)[payload.item] = payload.state;
  },
  updateSubnetDescription: (state, payload) => {
    state.subnets.find(subnet => subnet.id === payload.id)[payload.item] = payload.state;
  },
  updateSubnetDns: (state, payload) => {
    state.subnets.find(subnet => subnet.id === payload.id)[payload.item] = payload.state;
  },
  updatePorts: (state, payload) => {
    state.ports.find(port => port.id === payload.id)[payload.item] = payload.state;
  },
  updatePortDescription: (state, payload) => {
    state.ports.find(port => port.id === payload.id)[payload.item] = payload.state;
  },
  projects: (state, { payload, action }) => {
    state.projects = payload.map(i => new OPTariff(i, action));
  },
  quotasServer: (state, payload) => {
    state.quotasServer = payload.quota_set;
  },
  keypairs: (state, payload) => {
    state.keypairs = payload.keypairs;
  },
  quotasNetwork: (state, payload) => {
    let key;
    key = Object.keys(payload.quota);
    key.forEach(k => {
      // console.log(k);
      if (!state.quotasNetworkValue.find(x => x.name === k)) {
        state.quotasNetworkValue.push({
          name: k,
          value: payload.quota[k].limit - payload.quota[k].used - payload.quota[k].reserved,
        });
      } else {
        state.quotasNetworkValue.find(x => x.name === k).value =
          payload.quota[k].limit - payload.quota[k].used - payload.quota[k].reserved;
      }
    });
    state.quotasNetwork = payload.quota;
  },
  quotasVolume: (state, payload) => {
    state.quotasVolume = payload.limits.absolute;
  },
  updateServer: (state, payload) => {
    state.servers.filter(server => server.id === payload[0].id)[0][payload[2]] = payload[1];
  },
  updateServerName: (state, payload) => {
    state.servers.find(server => server.id === payload.id)[payload.type] = payload.name;
  },
  updateFlavor: (state, payload) => {
    state.flavors.find(flavor => flavor.id === payload.id)[payload.type] = payload.name;
  },
  updateDiskName: (state, payload) => {
    try {
      state.volumes.find(disk => disk.id === payload.id)[payload.type] = payload.name;
    } catch (e) {
      console.log(e);
    }
  },
  updateSnapshotName: (state, payload) => {
    state.snapshots.find(snap => snap.id === payload.id)[payload.type] = payload.name;
  },
  updateRouterName: (state, payload) => {
    state.routers.find(snap => snap.id === payload.id)[payload.item] = payload.state;
  },
  updateFloatingIpName: (state, payload) => {
    state.floatingips.find(snap => snap.id === payload.id)[payload.type] = payload.name;
  },
  currentPort: (state, payload) => {
    state.currentPort = payload;
  },
  setMembers: (state, payload) => {
    state.members[payload.id] = payload.data;
  },
  setTypesOfDisks: (state, payload) => {
    state.typesOfDisks = payload.sort(function (a, b) {
      if (a.id > b.id) {
        return 1;
      }
      if (a.id < b.id) {
        return -1;
      }
      return 0;
    });
  },
  // updateNetwork: (state, payload) => {
  //   state.networks.filter(network => network.id === payload[0].id)[0][payload[2]] = payload[1];
  // },
};
