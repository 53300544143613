let configUrl;
switch (true) {
  case window.location.origin.includes('localhost'):
    configUrl = 'my';
    break;
  case window.location.origin.includes('mypreprod'):
    configUrl = 'mypreprod';
    break;
  case window.location.origin.includes('mystage'):
    configUrl = 'mystage';
    break;
  case window.location.origin.includes('myupdate'):
    configUrl = 'myupdate';
    break;
  case window.location.origin.includes('my'):
    configUrl = 'my';
    break;
  default:
    configUrl = 'my';
}
export const PROD = configUrl;
export const NOTWORKDAY = [
  '31-12-2024',
  '01-01-2025',
  '02-01-2025',
  '03-01-2025',
  '04-01-2025',
  '05-01-2025',
  '06-01-2025',
  '07-01-2025',
  '08-01-2025',
  '01-05-2025',
  '02-05-2025',
  '08-05-2025',
  '09-05-2025',
  '12-06-2025',
  '13-06-2025',
  '03-11-2025',
  '04-11-2025',
  '31-12-2025',
];
export const FAQLINK = {
  acronis: 'https://support.rusonyx.ru/index.php?/Knowledgebase/List/Index/109',
};
export const WORKDAY = [''];
