import BillMgrApi from '@/services/api/BillMgrApi';
import { formDataParser } from '@/utils/parsers/formDataParser';
// import { tariffByService } from '@/utils/getServices';

export const ACTIONS = {
  fetchList({ commit }, payload = {}) {
    const silent = payload.silent || false;
    if (!silent) commit('setIsLoading', true);
    return new Promise((resolve, reject) => {
      const url = '/billmgr';
      const params = {
        show_metadata: 'on',
        func: 'dashboard.items',
      };
      BillMgrApi.get(url, { params })
        .then(data => {
          commit('SET_LIST', data?.elem);
          resolve(data);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          if (!silent) commit('setIsLoading', false);
        });
    });
  },
  fetchAllList({ commit }, payload) {
    commit('resetList');
    commit('setIsLoading', true);
    return new Promise((resolve, reject) => {
      const url = `/api2/billmgr/${payload.intname}`;
      const params = {
        show_metadata: 'on',
        newface: 'on',
        p_cnt: 1000,
        // ...payload,
      };
      if (payload.intname === 'domain') {
        params.out = 'sjson';
        delete params.newface;
      }
      BillMgrApi.get(url, { params })
        .then(async data => {
          let dataRaw = data?.elem;
          if (dataRaw) {
            dataRaw = dataRaw.map(x => {
              const obj = Object.assign({}, x);
              obj.func = payload.intname;

              return obj;
            });
            await commit('SET_TARIFFS_LIST', dataRaw);
            resolve(dataRaw);
          }
        })
        .catch(error => {
          reject(error);
        })
        .finally(async () => {
          commit('setIsLoading', false);
        });
    });
  },
  fetchListBlock({ commit }, payload = {}) {
    const silent = payload.silent || false;
    if (!silent) commit('setIsLoading', true);
    return new Promise((resolve, reject) => {
      const url = '/billmgr';
      const params = {
        show_metadata: 'on',
        func: 'dashboard.items',
      };
      BillMgrApi.get(url, { params })
        .then(data => {
          commit('SET_LIST_BLOCK', data.elem);
          resolve(data);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          if (!silent) commit('setIsLoading', false);
        });
    });
  },
  updateDetail(_ctx, payload) {
    const params = {
      ...payload,
      auth: localStorage.getItem('token'),
      out: 'bjson',
      sok: 'ok',
    };
    // if (!payload.func) params.func = 'licvz.edit';
    const formData = formDataParser(params);
    return BillMgrApi.post('/billmgr', formData);
  },
  fetchItem({ commit }, payload) {
    const silent = payload.silent || false;
    const needSaveState = payload.state || false;
    if (!silent) commit('setIsLoading', true);
    return new Promise((resolve, reject) => {
      const url = `/api2/billmgr/${payload.item}`;
      const params = {
        filter: 'on',
        out: 'none',
        metadata: 'on',
        id: payload.id,
      };
      BillMgrApi.get(url, { params })
        .then(data => {
          if (data?.elem) commit('SET_ITEMS', data?.elem);
          if (data?.metadata?.toolbtndata) commit('SET_TOOLS', data?.metadata?.toolbtndata);
          if (needSaveState) commit('SET_STATE', '');
          resolve(data?.elem[0]);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          if (!silent) commit('setIsLoading', false);
        });
    });
  },
  updateTariffList({ commit }, tariff) {
    // console.log(tariff);
    commit('UPDATE_TARIFF', tariff);
  },
  setAction({ commit }, payload) {
    const state = payload.state || false;
    const current = payload.current || false;
    const flag = payload.flag || false;
    if (state) commit('SET_STATE', state === 'clear' ? '' : state);
    if (flag) commit('SET_FLAG', flag !== 'clear');
    if (current) commit('SET_CURRENT', current === 'clear' ? '' : current);
  },
  fetchItemDetail({ commit }, payload) {
    // console.log(payload);
    const silent = payload.silent || false;
    const needSaveState = payload.state || false;
    if (!silent) commit('setIsLoading', true);
    const params = {
      elid: payload.id,
      func: `${payload.item}.edit`,
    };
    return new Promise((resolve, reject) => {
      BillMgrApi.get('/billmgr', { params })
        .then(async data => {
          const { slist, model, customfields } = data;
          // console.log(data);
          commit('SET_ITEMS', model);
          if (needSaveState)
            commit('SET_STATE', {
              state: model.autoprolong ? 'on' : 'off',
              id: data.id,
            });
          if (needSaveState) commit('SET_FLAG', { id: model.id, flag: true });
          commit('SET_MODEL', { id: model.id, data: data });
          if (needSaveState) commit('SET_CURRENT', model.id);
          if (slist?.autoprolong) commit('SET_PROLONG', { id: model.id, slist: slist.autoprolong });
          resolve(data);
        })
        .catch(err => reject(err))
        .finally(() => {
          if (!silent) commit('setIsLoading', false);
        });
    });
  },
  paymethod({ commit }, payload) {
    commit('paymethod', payload);
  },

  // reset store
  reset({ commit }, delay = 500) {
    return new Promise(resolve => {
      setTimeout(() => {
        commit('SET_TARIFFS_LIST', []);
        // commit('SET_CURRENT', '');
        // commit('paymethod', '');
        commit('setIsLoading', true);
        resolve();
      }, delay);
    });
  },
};
