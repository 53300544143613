import { formDataParser } from '@/utils/parsers/formDataParser';
import BillMgrApi from '@/services/api/BillMgrApi';

export const ACTIONS = {
  async fetchList({ commit }, payload = {}) {
    // console.log('payload', payload);
    commit('SET_IS_LOADING', true);
    return new Promise((resolve, reject) => {
      // const url = '/api2/billmgr/saas_services';
      const url = ['/api2/billmgr/saas_services', '/api2/billmgr/vdc3'];
      const params = {
        show_metadata: 'on',
        newface: 'on',
        p_cnt: 1000,
        ...payload,
      };
      url.map(async item => {
        // console.log(item);
        return BillMgrApi.get(item, { params })
          .then(data => {
            // console.log(data?.elem);
            if (item.includes('vdc3')) {
              const elems = data?.elem.filter(x => x.pricelist_id === '8148');
              commit('SET_LIST', elems);
              commit('setCountDeployMe', elems.length);
              commit('SET_TOOLS', { data: data?.metadata?.toolbtndata, type: 'deploy' });
            } else {
              commit('SET_LIST', data?.elem);
              commit('SET_TOOLS', { data: data?.metadata?.toolbtndata, type: 'gitflic' });
            }
            resolve(data);
          })
          .catch(error => {
            reject(error);
          })
          .finally(() => {
            // console.log('finally');
            commit('SET_IS_LOADING', false);
          });
      });
    });
  },
  updateList({ commit }, payload) {
    console.log('updateList', payload);
    commit('SET_IS_LOADING', true);
    // console.log(payload);
    return new Promise((resolve, reject) => {
      // const url = '/api2/billmgr/saas_services';
      // const url = ['/api2/billmgr/saas_services', '/api2/billmgr/vdc3'];
      const url = ['/api2/billmgr/saas_services'];
      const params = {
        metadata: 'on',
        newface: 'on',
        p_cnt: 1000,
        ...payload,
      };
      url.forEach(item => {
        // console.log(item);
        return (
          BillMgrApi.get(item, { params })
            .then(data => {
              // console.log(data?.elem);
              if (item.includes('vdc3')) {
                // commit('UPDATE_LIST', {
                //   list: data?.elem.filter(x => x.pricelist_id === '8148'),
                //   type: 'deploy',
                // });
              } else {
                commit('UPDATE_LIST', { list: data?.elem, type: 'saas' });
              }
              resolve(data);
            })
            // .then(data => {
            //   commit('UPDATE_LIST', data.elem);
            //   resolve(data);
            // })
            .catch(error => {
              reject(error);
            })
            .finally(() => {
              commit('SET_IS_LOADING', false);
            })
        );
      });
    });
  },
  setCurrent: async (context, id) => {
    try {
      await context.commit('SET_CURRENT', id);
      const tariff = await context.getters['GET_CURRENT_HOSTING'];
      return tariff;
    } catch (err) {
      console.error(err);
    }
  },
  fetchDetail({ commit, dispatch }, payload) {
    // console.log(payload);
    const id = payload.id;
    const params = {
      elid: payload.id,
      elname: payload.name,
      func: payload.type ? 'vdc3.edit' : 'saas_services.edit',
      // out: 'xjson',
    };
    return new Promise((resolve, reject) => {
      BillMgrApi.get('/billmgr', { params })
        .then(async data => {
          const { customfields, slist, model } = data;
          // console.log(model);
          const details = await dispatch('fetchTariffCalc', model);
          commit('SET_OTHER_INFO_ABOUT_TARIFF', {
            prop: 'info',
            value: {
              customfields,
              slist,
              model,
              details: details.model.cost_details,
            },
            id,
          });
          resolve(data);
        })
        .catch(err => reject(err));
    });
  },
  updateDetail(_ctx, payload) {
    console.log('updateDetail', payload);
    const params = {
      ...payload,
      auth: localStorage.getItem('token'),
      out: 'bjson',
      sok: 'ok',
    };
    if (!payload.func) params.func = 'saas_services.edit';
    const formData = formDataParser(params);
    return BillMgrApi.post('/billmgr', formData);
  },
  fetchHistory({ commit }, id) {
    const params = {
      func: 'service.history',
      elid: id,
    };
    return new Promise((resolve, reject) => {
      BillMgrApi.get('', { params })
        .then(data => {
          commit('SET_OTHER_INFO_ABOUT_TARIFF', {
            prop: 'history',
            value: data.elem,
          });
          resolve(data);
        })
        .catch(err => reject(err));
    });
  },
  fetchTariffCalc(_ctx, model) {
    const params = {
      ...model,
      func: 'pricelist.calculate',
      auth: localStorage.getItem('token'),
      out: 'bjson',
    };
    const formData = formDataParser(params);
    return new Promise((resolve, reject) => {
      BillMgrApi.post('/billmgr', formData)
        .then(data => {
          resolve(data);
        })
        .catch(err => reject(err));
    });
  },
  fetchBillMgrToolAction(_ctx, { func, id }) {
    const params = {
      func,
      elid: id,
      newwindow: 'yes',
    };
    return BillMgrApi.get('/billmgr', { params });
  },
  updateTariff(_ctx, model) {
    const params = {
      ...model,
      func: 'vdc3.edit',
      auth: localStorage.getItem('token'),
      out: 'bjson',
      clicked_button: 'on',
      sok: 'ok',
    };
    const formData = formDataParser(params);
    return new Promise((resolve, reject) => {
      BillMgrApi.post('/billmgr', formData)
        .then(data => {
          resolve(data);
        })
        .catch(err => reject(err));
    });
  },
  sendBillMgrToolAction(_ctx, payload) {
    if (!payload.func) throw new Error('Missed required param "func"');
    const params = {
      ...payload,
      newface: 'on',
      out: 'bjson',
      sok: 'ok',
    };
    if (!payload.auth) params.auth = localStorage.getItem('token');
    const formData = formDataParser(params);
    return BillMgrApi.post('/billmgr', formData);
  },
  // rest store
  reset({ commit }, delay = 500) {
    return new Promise(resolve => {
      setTimeout(() => {
        commit('reset');
        commit('SET_CURRENT', '');
        commit('SET_IS_LOADING', true);
        resolve();
      }, delay);
    });
  },
};
