<template>
  <div class="slider-block">
    <div class="slider-block__header">
      <div class="slider-block__header-title standart-title">
        {{ label || config.title }}
        <plain-button
          v-if="hint"
          v-tooltip="{ content: hint, placement: 'top-start' }"
          icon="help"
          size="small"
          color="dim"
          tabindex="-1"
          class="slider-block__hint"
        />
      </div>
      <InputNumber
        v-model="internalValue"
        :max="config.max"
        mode="decimal"
        :disabled="config.disabled || config.max === config.min"
        :min="config.min"
        locale="ru-RU"
        :input-style="{
          'text-align': 'center',
          'max-width': '6rem',
          'min-width': '4rem',
          'margin-left': '1rem',
        }"
        class="p-inputtext-sm"
        @input="onChange('change', $event)"
      />
      <cost-label
        v-if="hasCost && !hideCostLabel"
        class="slider-block__header-label"
        :cost="sum"
        :period="isNumber(shownPeriod) ? shownPeriod : +shownPeriod"
      />
    </div>
    <div class="slider-block__slider-wrapper">
      <base-slider
        v-tooltip="tooltipSettings"
        :value="config.value"
        :min="config.min"
        :max="config.max"
        :step="config.step"
        :slider-data="config.sliderData"
        :measure="config.measure"
        :disabled="config.disabled"
        class="slider-block__slider"
        @change="onChange('slider', $event)"
      />
      <span class="slider-block__slider-text standart-text l-hidden-ms-down">
        {{ maxText || config.label }}
      </span>
    </div>
  </div>
</template>

<script>
import BaseSlider from '@/components/BaseSlider/BaseSlider.vue';
import CostLabel from '@/components/Labels/CostLabel.vue';
import SliderComponent from '@/models/base/components/slider';
import { throttle, isNumber } from 'lodash';
import { BillMgrPeriods } from '@/models/BillMgr/periods';
import InputNumber from 'primevue/inputnumber';
export default {
  name: 'SliderBlock',
  components: { InputNumber, /*BaseInput,*/ BaseSlider, CostLabel },
  inheritAttrs: false,
  props: {
    config: {
      type: Object,
      required: true,
      validator: obj => obj instanceof SliderComponent || typeof obj.value !== 'undefined',
    },
    variable: {
      type: Boolean,
      default: false,
    },
    labelLengthStyle: {
      type: Number,
      //required: true,
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    hint: {
      type: String,
      default: '',
    },
    period: {
      type: [Number, String],
      default: 1,
      validator: val => BillMgrPeriods[val],
    },
    periodKey: {
      type: String,
      default: null,
      validator: val => Object.values(BillMgrPeriods).includes(val),
    },
    shownPeriod: {
      type: [String, Number],
      default: null,
      validator: val => isNumber(val) || BillMgrPeriods[val],
    },
    cost: {
      type: Number,
      default: null,
    },
    measure: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      sum: 0,
      internalValue: this.config.value,
      backupValue: this.config.value,
      error: '',
      customValidationMsgs: {
        confirm: { patternMismatch: 'test' },
      },
    };
  },
  computed: {
    style() {
      if (!this.labelLengthStyle) return null;
      return {
        width: `${this.labelLengthStyle + 40}px`,
      };
    },
    tooltipSettings() {
      const config = this.config;
      const disabled =
        !this.config.sliderData && config.min === config.max ? this.$t('disabled') : null;
      const restrict =
        config.restrictclientchange === 'off'
          ? null
          : this.$t(`restrictclientchange.${config.restrictclientchange}`);
      return disabled ? disabled : restrict ? restrict : null;
    },
    hasCost() {
      return isNumber(this.cost);
    },
    hideCostLabel() {
      return this.cost === 0 && this.shownPeriod === 0;
    },
    // sum() {
    // let value = 0;
    // if (this.variable) {
    //   console.log('variable');
    //   value = (this.config.value - this.backupValue) * this.cost;
    // } else {
    //   value = ((this.config.value - this.config.limit) / this.config.step) * this.cost;
    // }
    // if (!this.hasCost) return null;
    // if (this.shownPeriod) {
    //   // return this.shownPeriod === this.period
    //   // ? value * this.shownPeriod
    //   return (value / this.period) * this.shownPeriod;
    // } else {
    //   console.log('else');
    //   return value;
    // }
    // },
    maxText() {
      let max = this.config.sliderData
        ? this.config.sliderData[this.config.sliderData.length - 1]
        : this.config.max;
      let formatted = new Intl.NumberFormat().format(max);
      return this.config.measure ? `${formatted} ${this.config.measure}` : formatted;
    },
    // periodKeyFinal() {
    //   return this.periodKey || BillMgrPeriods[this.period];
    // },
  },
  watch: {
    'config.value': function (val) {
      if (this.internalValue !== val) {
        this.internalValue = +val;
      }
    },
  },
  methods: {
    isNumber(n) {
      return isNumber(n);
    },
    onChange(item, value) {
      // console.log(item, value);
      if (item === 'change') {
        if (value > this.config.max) {
          this.internalValue = this.config.max;
          this.config.value = this.config.max;
          this.error = 'max';
        } else if (value < this.config.min) {
          this.error = 'min';
        } else {
          this.config.value = value;
          this.error = null;
        }
      }
      if (item === 'slider') {
        this.config.value = +value;
        this.internalValue = this.config.value;
        this.error = null;
      }

      let sum = 0;
      if (this.variable) {
        sum = (this.config.value - this.backupValue) * this.cost;
      } else {
        sum = ((this.config.value - this.config.limit) / this.config.step) * this.cost;
      }
      if (!this.hasCost) sum = null;

      if (this.shownPeriod) {
        sum = (sum / this.period) * this.shownPeriod;
      }

      this.sum = sum;

      if (!this.error) {
        throttle(
          () => {
            let params = this.config?.additionalStep ? value * 1024 : value;
            let res = {};
            res.value = params;
            if (this.hasCost) res.sum = this.sum;
            this.$emit('change', res);
          },
          100,
          { trailing: false }
        )();
      }
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "disabled": "Значение нельзя изменить"
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.vue-slider-dot-tooltip-text{
  z-index: 9999
}
.slider-block {
  &__header {
    margin-bottom: 4rem;

    +breakpoint(sm-and-up) {
      flexy(flex-start, center);

    }

    &-title,
    &-text {
      transition: color $anim-base-duration ease;
    }
    &-title {
      flexy(flex-start, baseline);
    }

    &-label {
      margin-top: 0.5rem;
      transition: all $anim-base-duration ease;
      display: flex;

      +breakpoint(sm-and-up) {
        margin-left: 2.5rem;
        margin-top: 0;
      }
    }
  }
  &__hint {
    margin-left: 0.25rem!important;
    align-self: center;
  }
  &__slider {
    flex: 1 1 auto;

    &-wrapper {
      +breakpoint(sm-and-up) {
        flexy(flex-start, center);
      }
    }

    &-text {
      flex: 0 0 6rem;
      margin-left: 1rem;
    }
  }
}
</style>
