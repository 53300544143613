<template>
  <div class="container">
    <div class="all">
      <div class="all-info">
        <div class="all-info__content">
          <page-title class="all-info__title">
            {{ $t('title') }}
          </page-title>
        </div>
      </div>
      <transition name="fade" mode="out-in" appear>
        <div class="all-content">
          <transition name="fade" mode="out-in">
            <!--              removable-sort-->
            <DataTable
              state-storage="local"
              :state-key="'all-state-local'"
              :always-show-paginator="true"
              :value="list"
              ref="list"
              :paginator="true"
              :loading="loading"
              data-key="key"
              :show-gridlines="false"
              :show-clear-button="true"
              :rows-per-page-options="[10, 25, 50, 100]"
              :row-hover="true"
              :filters.sync="filters"
              :global-filter-fields="['id', 'name']"
              class="p-datatable p-datatable-sm"
              responsive-layout="scroll"
              current-page-report-template="({currentPage} из {totalPages})"
              paginator-template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              :rows="10"
              @sort="onSort"
            >
              <template #header>
                <div class="filter">
                  <Button
                    icon="pi pi-sync"
                    v-tooltip.top="disableUpdateButton ? '' : 'Обновить'"
                    class="p-button-sm"
                    :class="disableUpdateButton ? 'p-button-secondary' : 'p-button-primary'"
                    :disabled="disableUpdateButton"
                    @click="updateAll()"
                  />
                  <div class="filter-search">
                    <span class="p-input-icon-left">
                      <i class="pi pi-search" />
                      <InputText v-model="filters['global'].value" placeholder="Поиск..." />
                    </span>
                  </div>
                </div>
              </template>
              <template #empty> Нет активных услуг. </template>
              <template #loading> Идет загрузка информации... </template>
              <Column
                filter-field="id"
                :exclude-global-filter="true"
                sort-field="id"
                field="id"
                :styles="{ width: '5%' /*, 'padding-left': '80px'*/ }"
                :sortable="true"
              >
                <template #header>
                  <div class="rowheader">ID</div>
                </template>
                <template #body="slotProps">
                  <router-link
                    v-if="slotProps.data.pricelist === 'DeployMe'"
                    :to="{ name: 'saasDetail', params: { id: slotProps.data.id } }"
                    class="link medium-text"
                  >
                    {{ slotProps.data.id }}
                  </router-link>
                  <router-link
                    v-else
                    :to="{ name: pathItem(slotProps.data), params: { id: slotProps.data.id } }"
                    class="link medium-text"
                  >
                    {{ slotProps.data.id }}
                  </router-link>
                </template>
                <template #filter="{ filterModel }">
                  <InputText
                    type="text"
                    v-model="filterModel.value"
                    class="p-column-filter"
                    placeholder="Поиск по имени"
                  />
                </template>
              </Column>
              <Column
                filter-field="name"
                :exclude-global-filter="true"
                field="name"
                :styles="{ width: '35%' /*, 'padding-left': '80px'*/ }"
                :sortable="true"
              >
                <template #header>
                  <div class="rowheader">Название услуги</div>
                </template>

                <template #body="slotProps">
                  {{ slotProps.data.name }}
                </template>
                <template #filter="{ filterModel }">
                  <InputText
                    type="text"
                    v-model="filterModel.value"
                    class="p-column-filter"
                    placeholder="Поиск по услуге"
                  />
                </template>
              </Column>
              <Column
                field="autoprolong"
                header="Автопродление"
                sort-field="autoprolong_period"
                :styles="{ width: '8%', textAlign: 'center' }"
                :sortable="true"
              >
                <template #body="slotProps">
                  <plain-button
                    v-if="isAstra"
                    :icon="state(slotProps.data) ? icons[state(slotProps.data)] : 'autoprolong'"
                    class="tariffs-table-item__autoprolong"
                  />
                  <autoprolong
                    v-else
                    :tariff="slotProps.data"
                    class="tariffs-table-item__autoprolong"
                    @change="changeLoading(slotProps.data, $event)"
                    @edit="editPaymethod(slotProps.data, $event)"
                  />
                </template>
                <template #filter="{ filterModel }">
                  <InputText
                    type="text"
                    v-model="filterModel.value"
                    class="p-column-filter"
                    placeholder="Поиск по префиксу"
                  />
                </template>
              </Column>
              <Column
                field="stored_method"
                header="Способ оплаты"
                :styles="{ width: '13%' }"
                :sortable="true"
              >
                <template #body="slotProps">
                  <base-loader
                    v-if="loading"
                    :size="true"
                    class="vps-autoprolong__trigger"
                  ></base-loader>
                  <plain-button
                    v-else-if="!loading && state(slotProps.data) === 'on'"
                    color="primary"
                    @click="paymethodChange(slotProps.data)"
                  >
                    {{ viewPaymethod(currentMethod(slotProps.data)) }}
                  </plain-button>
                </template>
                <template #filter="{ filterModel }">
                  <InputText
                    type="text"
                    v-model="filterModel.value"
                    class="p-column-filter"
                    placeholder="Поиск по методу оплаты"
                  />
                </template>
              </Column>
              <Column
                field="status"
                sort-field="status.code"
                header="Статус"
                :styles="{ width: '12%' }"
                :sortable="true"
              >
                <template #body="slotProps">
                  <main-label
                    v-if="!loading"
                    :color="slotProps.data.status.color"
                    theme="plain"
                    class="tariffs-table-item__status"
                  >
                    {{
                      slotProps.data.employeesuspend === 'on'
                        ? 'Остановлен администратором'
                        : slotProps.data.abusesuspend === 'on'
                        ? 'Остановлен за нарушения'
                        : slotProps.data.status.text
                        ? slotProps.data.status.text
                        : $t(`status.${slotProps.data.status.key}`)
                    }}
                  </main-label>
                </template>
                <template #filter="{ filterModel }">
                  <InputText
                    type="text"
                    v-model="filterModel.value"
                    class="p-column-filter"
                    placeholder="Поиск по дате"
                  />
                </template>
              </Column>
              <Column
                field="expiredate"
                sort-field="expiredate"
                header="Действует до"
                :styles="{ width: '12%' }"
                :sortable="true"
              >
                <template #body="slotProps">
                  <!--                    v-if="slotProps.data?.expiredate_type === 'date'"-->
                  <div
                    v-if="!['acronis', 'vdc3'].includes(slotProps.data.func)"
                    class="date-end-service"
                  >
                    <label :class="classes(slotProps.data)" class="tariff-table-item__server">
                      {{
                        slotProps.data.func === 'acronis'
                          ? 'Ежемесячное списание'
                          : slotProps.data.func === 'vdc3'
                          ? 'Ежедневное списание'
                          : $d(slotProps.data.expiredate, 'short')
                      }}
                    </label>
                    <plain-button
                      v-if="warningDate(slotProps?.data)"
                      v-tooltip="{
                        content: warningDate(slotProps?.data),
                      }"
                      icon="error"
                      size="medium"
                      color="del"
                      tabindex="-1"
                      class="tariffs-table__prolong"
                    />
                  </div>
                  <div v-else class="tariffs-table-item__date">
                    <label :class="classes(slotProps.data)" class="tariff-table-item__server">
                      {{ slotProps.data.expiredate }}
                    </label>
                  </div>
                </template>
                <template #filter="{ filterModel }">
                  <InputText
                    type="text"
                    v-model="filterModel.value"
                    class="p-column-filter"
                    placeholder="Поиск по дате"
                  />
                </template>
              </Column>
              <Column
                field="prolongcost"
                header="Продлить"
                sort-field="prolongcost.sum"
                :styles="{ width: '14%' }"
                :sortable="false"
              >
                <template #body="slotProps">
                  <div v-if="isLinc(slotProps.data)" class="tariff-table-item__server">
                    <plain-button
                      color="primary"
                      class="link medium-text"
                      @click="
                        slotProps.data.cost === 0
                          ? singleProlongFree(slotProps.data)
                          : singleProlong(slotProps.data)
                      "
                      ><label class="link medium-text">
                        {{ viewProlongText(slotProps.data) }}
                      </label>
                    </plain-button>
                  </div>
                </template>
                <template #filter="{ filterModel }">
                  <InputText
                    type="text"
                    v-model="filterModel.value"
                    class="p-column-filter"
                    placeholder="Поиск по дате"
                  />
                </template>
              </Column>
            </DataTable>
          </transition>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import storeMixin from './mixins/index';
import provider from '@/mixins/provider';
import pagination from '@/mixins/pagination';
import Button from 'primevue/button';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import MainLabel from '@/components/Labels/MainLabel.vue';
import prolongSingle from '@/mixins/billmgr/prolongSingle';
import prolongSingleFree from '@/mixins/billmgr/prolongSingleFree';
import paymethodChange from '@/mixins/billmgr/paymethodChange';
import popoverFix from '@/mixins/popoverFix';
import { format, parseISO } from 'date-fns';
import Autoprolong from '@/layouts/All/component/Autoprolong.vue';
import { pathNameByService /*, tariffByService*/ } from '@/utils/getServices';
import ProlongSingle from '@/layouts/All/component/ProlongSingle.vue';
import { parseDateRu } from '@/utils/parsers/dateParser';
import { left } from 'core-js/internals/array-reduce';

export default {
  name: 'All',
  components: {
    Autoprolong,
    MainLabel,
    Button,
    InputText,
    Column,
    DataTable,
  },
  mixins: [
    storeMixin,
    provider,
    pagination,
    prolongSingle,
    prolongSingleFree,
    paymethodChange,
    popoverFix,
  ],
  data() {
    return {
      stopNames: [
        'публичное облако',
        'виртуальный дата-центр',
        'cdn',
        'облачный бекап',
        'OpenStack',
      ],
      /*checkedList: [],*/
      disableUpdateButton: false,
      /*checkedAll: false,*/
      currentPaymethod: '',
      stateItem: {},
      rawData: [],
      pagin: {
        start: 0,
        step: 4,
      },
      filters: {
        global: { value: null },
        id: { value: null },
        name: { value: null },
      },
      singleProlongComponentFirst: ProlongSingle,
      singleProlongComponent: ProlongSingle,
      icons: {
        on: 'autoprolong-checked',
        off: 'autoprolong',
      },
      loading: true,
    };
  },
  computed: {
    updatedItems() {
      return this.$store.state.moduleAll.services;
    },
    list() {
      return this.$store.state.moduleAll.tariffList;
    },
    paymentList() {
      return this.$store.state.moduleBilling.modulePayment?.list;
    },
  },
  watch: {
    loading(event) {
      // console.log('loading', event);
    },
  },
  async mounted() {
    if (!this.list.length) await this.fetchDataRaw();
    else this.loading = false;
  },
  methods: {
    left,
    setPeriod(val) {
      if (val === '1') {
        return 'месяц';
      } else if (val === '3') {
        return '3 месяца';
      } else if (val === '6') {
        return 'полгода';
      } else if (val === '9') {
        return '9 месяцев';
      } else if (val === '12') {
        return 'год';
      } else if (val === '24') {
        return '2 года';
      } else if (val === '36') {
        return '3 года';
      } else return 'off';
    },
    pathItem(item) {
      return pathNameByService[item.func];
    },
    fetchDataRaw: async function () {
      const promises = [];
      if (!this.paymentList.length)
        await this.$store
          .dispatch('moduleBilling/modulePayment/fetchList')
          .catch(e => this.showError(e));
      if (this.updatedItems.length === 0)
        await this.$store
          .dispatch('moduleStart/fetchDasboardInfo', { func: 'dashboard.services' })
          .catch(e => this.showError(e));

      this.updatedItems.map(async x => {
        promises.push(this.$store.dispatch('moduleAll/fetchAllList', x));
      });
      let rawList = [];
      const detailPromises = [];
      Promise.all(promises)
        .then(async data => {
          // console.log(data);
          data.flat().map(async item => {
            detailPromises.push(
              this.fetchItems({
                item: item.func,
                id: item.id,
              })
            );
          });
          rawList = [...data];
          // console.log('rawList', rawList);
        })
        .then(() => {
          this.loading = false;
        })
        .catch(e => this.showError(e));
    },
    currentMethod(item) {
      return item.stored_method ? item.stored_method : this.paymentMethod(item);
    },
    paymentMethod(item) {
      if (!this.loading && item && item.stored_method) {
        return item.stored_method;
      } else {
        return '';
      }
    },
    viewPaymethod(item) {
      if (item && !this.loading) {
        return item === ''
          ? ''
          : item === '0'
          ? 'Лицевой счет'
          : this.$store.state.moduleBilling.modulePayment.list.find(x => x.recurring === item)
              .fullname;
      }
    },
    changeLoading(item, event) {
      this.$emit('edit', item);
      if (event === 'true') {
      }
    },
    editPaymethod(item, event) {
      this.currentPaymethod = event;
    },
    watchState(item, event) {
      this.stateItem[item.id] = event;
    },
    async updateAll() {
      this.loading = true;
      this.disableUpdateButton = true;
      setTimeout(() => (this.disableUpdateButton = false), 30 * 1000);
      await this.fetchDataRaw();
    },
    warningDate(item) {
      if (!([7, 8].includes(item.status.code) && item?.info?.model?.suspenddate_l)) return false;
      return `Остановлен ${this.$d(
        parseDateRu([7, 8].includes(item.status.code) && item?.info?.model?.suspenddate_l),
        'short'
      )}`;
    },
    classes(item) {
      if (item.employeesuspend === 'on') return 'error-color';
      if (item.abusesuspend === 'on') return 'error-color';
      if (item.func === 'vdc3') return 'success-color';
      // if (this.warningDate(item)) return 'error-color';
      return this.differenceDate(item) < 20 ? 'error-color' : 'success-color';
    },
    formattedEndDate(item) {
      return item.expiredate === 'Ежедневное списание'
        ? null
        : format(parseISO(item.expiredate.split(' ').at(-1)), 'dd.MM.yyyy');
    },
    dateTariff(item) {
      return new Date(item.expiredate);
    },
    differenceDate(item) {
      return Math.floor((this.dateTariff(item) - new Date()) / (1000 * 60 * 60 * 24));
    },
    isLinc(item) {
      if (!item.prolongcost.sum && !item.prolongcost.period) return false;
      else if (this.isAstra) return false;
      else if (['Ежедневное списание', 'Еженедельное списание'].includes(item.expiredate)) {
        return false;
      } else return !(item.abusesuspend === 'on' || item.employeesuspend === 'on');
    },
    onSort(event) {
      // console.log('onSort---------', event);
    },
    state(item) {
      return item && item.autoprolong_available === 'on' && item.autoprolong_name !== 'null'
        ? 'on'
        : 'off';
    },
    viewProlongText(item) {
      const cost = parseFloat(item.prolong.slist.find(x => x.k === item.prolongcost.period)?.cost);

      return item.prolongcost.sum === 0
        ? 'Продлить'
        : this.$t('cost', {
            sum: cost,
            period: this.setPeriod(item.prolongcost.period),
          });
    },
  },
};
</script>
<i18n>
{
  "ru": {
    "title": "Активные услуги",
    "titleStop": "Остановленные услуги",
    "prolong": {
      "title": "Продление тарифа",
      "payment": "Смена метода оплаты автопродления",
      "error": "К сожалению, что-то пошло не так",
      "success_basket": "Продление тарифа успешно добавлено в корзину",
      "success_order": "Продление тарифа прошло успешно. Данные скоро обновятся.",
      "confirm": "Оплатить",
      "free": "Активировать",
      "order": "В корзину",
      "cancel": "Отмена",
      "change": "Сменить",
      "result": {
        "title": "Смена метода оплаты",
        "ok": "Хорошо",
        "desc": "Метод оплаты автопродления изменен."
      },
      "action": "Продлить"
    },
    "cost": "{sum} ₽ / {period}",
    "tableHead": {
      "name": "Название услуги",
      "title": "Название услуги",
      "id": "#id",
      "ap": "Автопродление",
      "cost": "Стоимость",
      "payment": "Способ оплаты",
      "date": "Действует до",
      "dateCreate": "Дата создания",
      "status": "Статус",
      "prolongcost": "Продлить"
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.container {
  display: flex;
  flex-direction: column;
  max-width: 85rem;
  margin: auto;
}
.date-end-service{
  flexy(flex-start, flex-start);
  flex-direction: row;
}
.p-datatable-header-up{
  background: #fff;
  border: 0;
}
.datatable-footer {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  gap: 1rem;
}
.rowheader{
    margin-left: 1rem;
  }
.filter{
  display: flex;
  //justify-content: space-between;
  justify-content: flex-end;
  margin-bottom: 0.75rem;
  flex-wrap:wrap;
  gap: 15px;
  align-items: center;
  +breakpoint(ms-and-down) {
  }
  &-search{
    +breakpoint(ml-and-down) {
      margin-top: 0.5rem;
      order: 2;
    }
    //+breakpoint(ms-and-down) {
    //  order: 3;
    //margin-top: 0.5rem;
    //order: 2;
    //width: 45%
    //}
  }
  & + & {
    margin-left: 2rem
  }
}
.time {
  width: 10rem;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.75rem

  &__select {
    width: 100%;
    margin: 1rem 0.65rem 0.5rem 0.65rem;

    //margin: auto 1rem auto 0.65rem;
    display: flex;
    //justify-content: space-between;
    align-items: center;
    &-selected {
      margin-left: 1rem;
    }

    &-buttons {

      &__btn {
        margin: auto 0.5rem;
      }
    }

  }
}
.field-checkbox{
  display flex
}
.time-buttons {
  width: 3rem
  min-width: 3rem;
}
.buttons-context{
  display flex;
  flex-direction column;
  justify-content flex-end;
  align-items: flex-end;
}
.all {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 1.5rem;
  flex: 1 1 100%;
  &-info {
    +breakpoint(sm-and-up) {
      flexy(space-betweeb, center);
    }

&__content {
  flexy(space-between, center);

  +breakpoint(sm-and-up) {
    justify-content: flex-start;
    margin-right: 1.5rem;
    flex: 0 1 auto;
      }
    }

    &__loader {
      flexy(center, center);
      flex: 1 1 auto;
    }

    &__create {
      flex: 0 0 auto;
      margin-top: 1.5rem;
      max-width: 160px;
      visibility: hidden;

      &.visible {
        visibility: visible;
      }

      +breakpoint(sm-and-up) {
        margin: 0 0 0 auto;
      }
    }
  }

  &-content {
    margin-top: 1rem;

    //+breakpoint(sm-and-up) {
    //  margin-top: 1rem;
    //}
    //+breakpoint(md-and-up) {
    //  margin-top: 1rem;
    //}
  }
}
.link {
  text-decoration: none;
  color: $primary-color;

  &:hover {
    color: darken($primary-color, 20%);
  }
}
  .tariffs-table {
    width: 100%;
  }
  .tariffs-table__pagination {
    margin-top: 1.5rem;
  }
    .tariffs{
      width: 100%;
    }
</style>
