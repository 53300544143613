<template>
  <main-card :to="to" class="start-card" :class="classes">
    <div class="start-card__inner">
      <!--      <base-loader v-if="loading" />-->
      <div class="start-card__content">
        <div class="start-card__number">
          {{ number || 0 }}
        </div>
        <div v-if="text" class="start-card__text big-text">
          {{ text }}
        </div>
      </div>
    </div>
  </main-card>
</template>

<script>
import MainCard from '@/components/MainCard/MainCard';
export default {
  name: 'StartCard',
  components: { MainCard },
  props: {
    // loading: {
    //   type: Boolean,
    //   default: false,
    // },
    number: {
      type: [String, Number],
      default: '0',
    },
    text: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'primary',
      validator: val => ['primary', 'success', 'error', 'warning', 'alert'].includes(val),
    },
    to: {
      type: [String, Object],
      default: null,
    },
  },
  computed: {
    classes() {
      const base = 'start-card';
      return {
        [`${base}--${this.color}`]: !!this.color,
      };
    },
  },
};
</script>

<style lang="stylus" scoped>
// global variables
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
// local variables
$sc-primary = {
  title: $primary-color,
  text: var(--main-card-color-notice),
}
$sc-success = {
  title: $success-color,
  text: var(--main-card-color-notice),
}
$sc-error = {
  title: $error-color,
  text: $error-color,
}
$sc-warning = {
  title: $warning-color,
  text: $warning-color,
}
$sc-alert = {
  title: $alert-color,
  text: $alert-color,
}
$sc-colors = {
  primary: $sc-primary,
  success: $sc-success,
  error: $sc-error,
  alert: $sc-alert,
  warning: $sc-warning,
}
.start-card {
  padding: 2rem 0.25rem;

  +breakpoint(sm-and-up) {
    padding: 1.75rem 1rem;
  }
  +breakpoint(md-and-up) {
    padding: 1rem;
  }

  &__inner {
    position: relative;
    +breakpoint(md-and-up) {
      padding-bottom: 100%;
      margin: 0;
    }
  }

  &__content {
    +breakpoint(md-and-up) {
      absolute-center();
      width: 100%;
    }
  }

  &__number {
    font-size: 42px;
    font-weight: 500;
    font-style: normal;
    letter-spacing: normal;
    line-height: 40px;
    text-align: center;
    transition: color $anim-base-duration ease;
  }
  &__text {
    text-align: center;
    margin-top: 0.5rem;
    transition: color $anim-base-duration ease;

    +breakpoint(ms-and-down) {
      font-size: $font-size-medium;
    }

    +breakpoint(sm-and-up) {
      margin-top: 1rem;
    }
  }

  for $key, $color in $sc-colors {
    &--{$key} {
      & ^[0]__number {
        color: $color.title;
      }
      & ^[0]__text {
        color: $color.text;
      }
    }
  }
}
</style>
