/**
 * @prop {Array} list - required list
 */
export default {
  data() {
    return {
      //stepOptions: [10, 25, 50, 100, 'all'],
      pagin: {
        start: 0,
        step: 10,
        page: 0,
      },
    };
  },
  computed: {
    paginList() {
      const { start, step } = this.pagin;
      const end = start + step;
      return this.list.slice(start, end);
    },
    paramsId() {
      return Object.values(this.$route?.params).join('-');
    },
    paramsName() {
      return this.$route.name;
    },
    userId() {
      return this.$store.state?.moduleProfile?.profile?.id;
    },
    allSettings() {
      return JSON.parse(localStorage.getItem('paginatorSettings')) || {};
    },
    paginatorUid() {
      return `${this.userId}-${this.paramsName}-${this.paramsId}`;
    },
  },
  mounted() {
    this.loadSettings();
  },
  methods: {
    loadSettings() {
      this.pagin = this.allSettings[this.paginatorUid] || {
        start: 0,
        step: 10,
        page: 0,
      };
    },
    setStep(value) {
      this.loadSettings();
      if (this.pagin?.step < value) this.pagin.step = value;
      this.saveSettings();
    },
    saveSettings() {
      this.allSettings[this.paginatorUid] = this.pagin;
      localStorage.setItem('paginatorSettings', JSON.stringify(this.allSettings));
    },
    changePagination(value) {
      this.pagin.step = value.rows;
      this.pagin.start = value.first;
      this.pagin.page = value.page;
      this.saveSettings();
    },
  },
};
