<template>
  <div class="checkbox-block">
    <base-checkbox
      :true-value="'on'"
      :false-value="'off'"
      :value="internalValue"
      class="checkbox-block__checkbox"
      @change="onChange"
    >
      {{ policy.label }}
      <span v-if="policy.required" class="checkbox-block__asterisk error-color">*</span>
      <div v-if="policy.link_url" class="checkbox-block__link">
        <plain-button :href="policy.link_url" target="_blank" color="primary">
          {{ policy.link_msg }}
        </plain-button>
      </div>
    </base-checkbox>
  </div>
</template>

<script>
import BaseCheckbox from '@/components/Checkboxes/BaseCheckbox.vue';
export default {
  name: 'Policy',
  components: { BaseCheckbox },
  props: {
    policy: {
      type: Object,
      required: true,
      // validator: obj => obj instanceof CheckboxComponent || typeof obj.value !== 'undefined',
    },
  },
  data() {
    return {
      internalValue: this.policy.value,
    };
  },
  computed: {
    tooltipSettings() {
      return {
        content: this.$t('restrictclientchange.on'),
        placement: 'top-start',
      };
    },
  },
  watch: {
    config: function (newconfig) {
      if (this.internalValue != newconfig.value) this.internalValue = newconfig.value;
    },
  },
  methods: {
    onChange(value) {
      this.policy.value = value;
      this.internalValue = this.policy.value;
      this.$emit('change', {
        value: this.policy.value,
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
.checkbox-block {
  &__checkbox {
    max-width: max-content;
  }

  &__link {
    margin-top: 0.25rem;
  }
}
</style>
