<template>
  <div class="confidencial">
    <transition name="fade" mode="out-in">
      <base-loader v-if="loading || !isReady" class="personal__loader" />
      <card-block v-else :title="$t('title')" class="confidencial__block">
        <main-card class="confidencial__card">
          <div class="confidencial__desc standart-text">
            {{ $t('desc') }}
          </div>
          <br />
          <div v-if="getPolicyLink">
            <div class="confidencial__desc standart-text">
              <plain-button :href="getPolicyLink.link" color="primary">
                {{ getPolicyLink.name }}
              </plain-button>
            </div>
          </div>
          <div class="confidencial__links">
            <plain-button
              v-for="item in actions"
              :key="item.key"
              color="primary"
              class="confidencial__link"
              @click="showConfirmation(item)"
            >
              {{ $te(`actions.${item.key}`) ? $t(`actions.${item.key}`) : item.label }}
            </plain-button>
          </div>
        </main-card>
      </card-block>
    </transition>
  </div>
</template>

<script>
import CardBlock from '../components/CardBlock';
import MainCard from '@/components/MainCard/MainCard';
import Vue from 'vue';
import BaseLoader from '@/components/BaseLoader/BaseLoader';
import qs from 'qs';
import storeMixin from '@/layouts/Support/mixins/index';
export default {
  name: 'Confidencial',
  components: { CardBlock, MainCard },
  mixins: [storeMixin],
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    profile() {
      return this.$store.state.moduleProfile.profile;
    },
    actions() {
      if (!this.profile || !this.profile.confidencialActions) return [];
      return this.profile.confidencialActions;
    },
    isReady() {
      return this.profile && this.actions.length;
    },
    getPolicyLink() {
      if (this.profile._list.documents) {
        return this.profile._list.documents[0];
      } else {
        return '';
      }
    },
  },
  methods: {
    showConfirmation(action) {
      let self;
      const selfName = 'Confirmation';
      this.$modals.open({
        name: selfName,
        title: this.$te(`confirm.${action.key}.title`)
          ? this.$t(`confirm.${action.key}.title`)
          : action.label,
        text: this.$t(`confirm.${action.key}.desc`),
        onOpen: modal => (self = modal),
        footer: {
          confirm: {
            props: { title: this.$t('submit') },
            on: {
              click: () => {
                Vue.set(self, 'title', null);
                Vue.set(self, 'text', null);
                Vue.set(self, 'component', BaseLoader);
                Vue.set(self, 'footer', null);
                let msg;
                let redirect;
                this.submit(qs.parse(action.value))
                  .then(data => {
                    if (data && data.ok && data.func === 'gdpr_remove_personaldata') {
                      // alert('!');
                      redirect = true;
                      this.$modals.close({ name: selfName });
                      this.addNewTicket({
                        ticketThemes: ' Удаление персональных данных',
                        propsOptions: 'Запрос не связан с услугой',
                        ticketText:
                          'Добрый день! Прошу удалить персональные данные, используемые в данной системе',
                      });
                    } else {
                      console.log(data);
                      redirect = false;
                      msg = this.$te(`result.success.${action.key}`)
                        ? this.$t(`result.success.${action.key}`)
                        : this.$t('result.success.general');
                      console.log(msg);
                    }
                  })
                  .catch(e => {
                    msg = e;
                    redirect = false;
                  })
                  .finally(() => {
                    if (!redirect) {
                      this.showResult(msg);
                      this.$modals.close({ name: selfName });
                    }
                  });
              },
            },
          },
          cancel: {
            on: { click: () => this.$modals.close() },
          },
        },
      });
    },
    showResult(msg) {
      this.$modals.open({
        name: 'Result',
        text: msg,
        footer: {
          center: true,
          confirm: {
            props: { title: this.$t('ok') },
            on: { click: () => this.$modals.close() },
          },
        },
      });
    },
    submit(payload) {
      const data = {
        id: this.profile.id,
        data: { clicked_button: 'ok', ...payload },
      };
      return this.$store.dispatch('moduleProfile/updateProfileInfo', data);
    },
  },
};
</script>

<i18n>
  {
    "ru": {
      "someText": "Политика конфиденциальности",
      "new_ticket": "Запрос в поддержку",
      "title": "Что можно сделать с персональными данными",
      "desc": "В данном разделе вы можете ознакомиться с обновлениями политики конфиденциальности, а также посмотреть список действий",
      "actions": {
        "export": "Экспортировать персональные данные",
        "restrict": "Ограничить использование моих персональных данных",
        "remove": "Запросить удаление персональных данных (удаление аккаунта)"
      },
      "confirm": {
        "export": {
          "desc": "Отправить персональные данные в формате CSV? Данные будут отправлены на Email, указанный в настройках пользователя"
        },
        "restrict": {
          "title": "Запрос на ограничение использования персональных данных",
          "desc": "Отправить запрос на ограничение использования персональных данных?"
        },
        "remove": {
          "desc": "Отправить запрос на удаление персональных данных?"
        }
      },
      "result": {
        "success": {
          "export": "Персональные данные успешно отправлены на email",
          "general": "Запрос успешно отправлен"
        },
        "error": {
          "general": "Кажется, что-то пошло не так"
        }
      },
      "submit": "Отправить",
      "ok": "Понятно"
    }
  }
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.confidencial {
  &__card {
    +breakpoint(md-and-up) {
      max-width: 40rem;
    }
  }
  &__desc {
    max-width: 32em;
  }

  &__links {
    margin-top: 1.5rem;
    flexy(flex-start, flex-start, nowrap, column);

    +breakpoint(sm-and-up) {
      margin-top: 2.5rem;
    }
  }

  &__link {
    text-align: left;
    & + & {
      margin-top: 1rem;
    }
  }
}
</style>
