import container from '@/layouts/Stack/container';

export const RouteStack = {
  name: 'Stack',
  path: '/stack',
  component: container,
  redirect: {
    name: 'stackMain',
  },
  meta: {
    isShow: true,
    isLink: true,
    name: window.location.href.includes('astracloud') ? 'Облако Астра' : 'Публичное облако',
    navigation: {
      service: {
        icon: 'vdc3',
        key: window.location.href.includes('astracloud') ? 'astra' : 'stack',
        order: 3,
      },
    },
    access: ['mainmenuservice.vdc3'],
  },
  children: [
    {
      path: '',
      name: 'stackMain',
      meta: {
        isShow: true,
        isLink: false,
        name: 'Публичное облако',
      },
      component: () => import(/* webpackChunkName: "stack-main" */ './pages/Main/Main.vue'),
    },
    {
      path: ':id',
      component: () =>
        import(/* webpackChunkName: "stack-info-wrapper" */ './pages/Information/Wrapper_stat.vue'),
      props: true,
      redirect: { name: 'StatisticOfflineStat' },
      name: 'StatisticOffline',
      children: [
        {
          path: 'statistic',
          component: () =>
            import(
              /* webpackChunkName: "stack-info-history" */ './pages/Information/views/Statistic.vue'
            ),
          // props: true,
          name: 'StatisticOfflineStat',
        },
      ],
    },

    {
      path: 'order/plans',
      name: 'StackOrder',
      meta: {
        isShow: true,
        isLink: false,
        name: 'Создание Публичного облака',
      },
      component: () => import(/* webpackChunkName: "stack-order" */ './pages/Order/StackOrder.vue'),
      children: [
        {
          path: '/stack/order',
          redirect: { name: 'stackOrder' },
        },
        {
          path: '/stack/order/:id/:period',
          name: 'stackOrderDetails',
          props: true,
          component: () =>
            import(
              /* webpackChunkName: "stack-order-details" */ './pages/Order/StackOrderDetails.vue'
            ),
        },
      ],
    },
    {
      path: ':id',
      name: 'tariffStack',
      meta: {
        isShow: true,
        isLink: true,
        name: 'OpenStack #',
      },
      component: () =>
        import(/* webpackChunkName: "stack-info" */ './pages/Information/container.vue'),
      // redirect: { name: 'viewStack' },
      redirect: { name: 'containerServer', query: { filter_by: 'name', value: '' } },
      props: true,
      children: [
        {
          path: 'server',
          name: 'containerServer',
          component: () =>
            import(
              /* webpackChunkName: "stack-server" */ './pages/Information/views/containerServer.vue'
            ),
          redirect: { name: 'viewStack' },
          props: true,
          meta: {
            isShow: true,
            isLink: true,
            name: 'Серверы',
          },
          children: [
            {
              path: '',
              component: () =>
                import(
                  /* webpackChunkName: "stack-info-view" */ './pages/Information/views/View.vue'
                ),
              name: 'viewStack',
              props: true,
              meta: {
                isShow: true,
                isLink: false,
                name: 'Серверы',
              },
            },
            {
              path: 'create',
              name: 'CreateServer',
              meta: {
                isShow: true,
                isLink: false,
                name: 'Новый сервер',
              },
              component: () =>
                import(
                  /* webpackChunkName: "stack-server-create-view" */ './pages/Order/StackOrderDetails_old.vue'
                ),
              props: true,
            },
            {
              path: ':serverid',
              name: 'Server',
              meta: {
                isShow: true,
                isLink: false,
              },
              component: () =>
                import(/* webpackChunkName: "stack-server-container" */ './server/container'),
              props: true,
              redirect: { name: 'ServerView' },
              children: [
                {
                  path: 'info',
                  name: 'ServerView',
                  props: true,
                  meta: {
                    isShow: true,
                    isLink: false,
                    name: 'Конфигурация',
                  },
                  component: () =>
                    import(/* webpackChunkName: "stack-server-info" */ './server/ServerView'),
                },
                {
                  path: 'disk',
                  name: 'ServerDisk',
                  meta: {
                    isShow: true,
                    isLink: false,
                    name: 'Сетевые диски',
                  },
                  component: () =>
                    import(/* webpackChunkName: "stack-server-disk" */ './server/ServerDisk'),
                },
                {
                  path: 'port',
                  name: 'ServerPort',
                  meta: {
                    isShow: true,
                    isLink: false,
                    name: 'Порты',
                  },
                  component: () =>
                    import(/* webpackChunkName: "stack-server-port" */ './server/ServerPort'),
                },
                {
                  path: 'vnc',
                  name: 'ServerVnc',
                  meta: {
                    isShow: true,
                    isLink: false,
                    name: 'Консоль VNC',
                  },
                  component: () =>
                    import(/* webpackChunkName: "stack-server-vnc" */ './server/ServerVnc'),
                },
                {
                  path: 'log',
                  name: 'ServerLog',
                  meta: {
                    isShow: true,
                    isLink: false,
                    name: 'Лог',
                  },
                  component: () =>
                    import(/* webpackChunkName: "stack-server-log" */ './components/Log'),
                },
              ],
            },
          ],
        },
        {
          path: 'disk',
          name: 'containerDisk',
          meta: {
            isShow: true,
            isLink: true,
            name: 'Диски',
          },
          component: () =>
            import(
              /* webpackChunkName: "stack-disk" */ './pages/Information/views/containerDisk.vue'
            ),
          redirect: { name: 'viewStackDisk' },
          props: true,
          children: [
            {
              path: '',
              component: () =>
                import(
                  /* webpackChunkName: "stack-disk-view" */ './pages/Information/views/ViewDisk.vue'
                ),
              name: 'viewStackDisk',
              props: true,
              meta: {
                isShow: true,
                isLink: false,
                name: 'Диски',
              },
            },
            {
              path: ':diskid',
              name: 'Disk',
              meta: {
                isShow: true,
                isLink: false,
              },
              component: () =>
                import(/* webpackChunkName: "stack-disk-container" */ './server/containerDisk'),
              props: true,
              redirect: { name: 'DiskView' },
              children: [
                {
                  path: 'info',
                  name: 'DiskView',
                  meta: {
                    isShow: true,
                    isLink: false,
                    name: 'Настройки',
                  },
                  component: () =>
                    import(/* webpackChunkName: "stack-disk-info" */ './server/DiskView'),
                },
                {
                  path: 'snapshots',
                  name: 'DiskSnapshots',
                  meta: {
                    isShow: true,
                    isLink: false,
                    name: 'Снапшоты',
                  },
                  component: () =>
                    import(/* webpackChunkName: "stack-server-disk" */ './server/DiskSnapshots'),
                },
                // {
                //   path: 'port',
                //   name: 'ServerPort',
                //   meta: {
                //     isShow: true,
                //     isLink: false,
                //     name: 'Порты',
                //   },
                //   component: () =>
                //     import(/* webpackChunkName: "stack-server-port" */ './server/ServerPort'),
                // },
                // {
                //   path: 'stat',
                //   name: 'ServerStat',
                //   component: () =>
                //     import(/* webpackChunkName: "stack-server-stat" */ './server/ServerStat'),
                // },
              ],
            },
          ],
        },
        {
          path: 'snapshots',
          name: 'containerSnapshot',
          meta: {
            isShow: true,
            isLink: true,
            name: 'Снапшоты',
          },
          component: () =>
            import(/* webpackChunkName: "stack-image" */ './server/containerSnapshot.vue'),
          redirect: { name: 'viewStackSnapshot' },
          props: true,
          children: [
            {
              path: '',
              component: () =>
                import(
                  /* webpackChunkName: "stack-snap-view" */ './pages/Information/views/ViewSnaps.vue'
                ),
              name: 'viewStackSnapshot',
              meta: {
                isShow: true,
                isLink: false,
                name: 'Снапшоты',
              },
            },
          ],
        },
        {
          path: 'network',
          name: 'containerNetwork',
          meta: {
            isShow: true,
            isLink: false,
            name: 'Сети',
          },
          component: () =>
            import(
              /* webpackChunkName: "stack-network" */ './pages/Information/views/containerNetwork.vue'
            ),
          redirect: { name: 'NetworkType' },
          props: true,
        },
        {
          path: 'network',
          name: 'NetworkType',
          meta: {
            isShow: true,
            isLink: true,
            name: 'Сети',
          },
          component: () =>
            import(
              /* webpackChunkName: "stack-network-type" */ './pages/Information/views/containerNetworkNav.vue'
            ),
          redirect: { name: 'viewStackPrivate' },
          children: [
            {
              path: 'private',
              component: () =>
                import(
                  /* webpackChunkName: "stack-network-private-view" */ './pages/Information/views/ViewNetwork.vue'
                ),
              name: 'viewStackPrivate',
              props: true,
              meta: {
                isShow: true,
                isLink: false,
                name: 'Приватные сети',
              },
            },
            {
              path: 'private/:privateid',
              component: () =>
                import(
                  /* webpackChunkName: "stack-network-private-view-detail" */ './server/containerNetworkPrivate'
                ),
              name: 'Private',
              redirect: { name: 'SubnetView' },
              props: true,
              meta: {
                isShow: true,
                isLink: true,
                name: 'Приватные сети',
              },
              children: [
                {
                  path: 'sub',
                  name: 'SubnetView',
                  meta: {
                    isShow: true,
                    isLink: false,
                    name: 'Подсети',
                  },
                  component: () =>
                    import(
                      /* webpackChunkName: "stack-subnet-private-info" */ './server/SubnetView'
                    ),
                },
                {
                  path: 'port',
                  name: 'PortView',
                  meta: {
                    isShow: true,
                    isLink: false,
                    name: 'Порты',
                  },
                  component: () =>
                    import(/* webpackChunkName: "stack-private-port-info" */ './server/PortView'),
                },
              ],
            },
            {
              path: 'floatingips',
              component: () =>
                import(
                  /* webpackChunkName: "stack-network-floatip-view" */ './pages/Information/views/ViewFloatingIp.vue'
                ),
              name: 'viewStackFloatingIp',
              props: true,
              meta: {
                isShow: true,
                isLink: false,
                name: 'Плавающие IP',
              },
            },
            {
              path: 'public',
              component: () =>
                import(
                  /* webpackChunkName: "stack-network-public-view" */ './pages/Information/views/ViewNetworkPublic.vue'
                ),
              name: 'viewStackPublic',
              props: true,
              meta: {
                isShow: true,
                isLink: false,
                name: 'Публичные подсети',
              },
            },
            {
              path: 'public/:publicid',
              component: () =>
                import(
                  /* webpackChunkName: "stack-network-public-view-detail" */ './server/containerNetworkPublic'
                ),
              name: 'Public',
              // redirect: { name: 'SubnetPublicView' },
              redirect: { name: 'PortPublicView' },
              props: true,
              meta: {
                isShow: true,
                isLink: true,
                name: 'Публичные подсети',
              },
              children: [
                {
                  path: 'sub',
                  name: 'SubnetPublicView',
                  meta: {
                    isShow: true,
                    isLink: false,
                    name: 'Обзор',
                  },
                  component: () =>
                    import(
                      /* webpackChunkName: "stack-subnet-public-info" */ './server/SubnetPublicView'
                    ),
                },
                {
                  path: 'port',
                  name: 'PortPublicView',
                  meta: {
                    isShow: true,
                    isLink: false,
                    name: 'Порты',
                  },
                  component: () =>
                    import(
                      /* webpackChunkName: "stack-public-port-info" */ './server/PortPublicView'
                    ),
                },
              ],
            },
            {
              path: 'router',
              component: () =>
                import(
                  /* webpackChunkName: "stack-network-public-view" */ './pages/Information/views/ViewRouter.vue'
                ),
              name: 'viewStackRouter',
              props: true,
              meta: {
                isShow: true,
                isLink: false,
                name: 'Роутеры',
              },
            },
            {
              path: 'router/:routerid',
              component: () =>
                import(
                  /* webpackChunkName: "stack-network-router-view-detail" */ './server/containerRouter'
                ),
              name: 'Router',
              redirect: { name: 'RouterView' },
              props: true,
              meta: {
                isShow: true,
                isLink: true,
                name: 'Роутеры',
              },
              children: [
                {
                  path: '',
                  name: 'RouterView',
                  meta: {
                    isShow: true,
                    isLink: false,
                    name: 'Подсети',
                  },
                  component: () =>
                    import(/* webpackChunkName: "stack-router-info" */ './server/RouterView'),
                },
              ],
            },
            {
              path: 'firewall',
              component: () =>
                import(
                  /* webpackChunkName: "stack-network-firewall-public-view" */ './pages/Information/views/ViewFirewall.vue'
                ),
              name: 'viewStackFirewall',
              props: true,
              meta: {
                isShow: true,
                isLink: false,
                name: 'Файрвол',
              },
            },
            {
              path: 'firewall/:firewallid',
              component: () =>
                import(
                  /* webpackChunkName: "stack-network-firewall-view-detail" */ './server/containerFirewall'
                ),
              name: 'Firewall',
              redirect: { name: 'FirewallView' },
              props: true,
              meta: {
                isShow: true,
                isLink: true,
                name: 'Файрвол',
              },
              children: [
                {
                  path: '',
                  name: 'FirewallView',
                  meta: {
                    isShow: true,
                    isLink: false,
                    name: 'Правила',
                  },
                  component: () =>
                    import(/* webpackChunkName: "stack-Firewall-info" */ './server/FirewallView'),
                },
              ],
            },
          ],
        },
        {
          path: 'service',
          name: 'containerService',

          component: () =>
            import(
              /* webpackChunkName: "stack-service" */ './pages/Information/views/containerService.vue'
            ),
          redirect: { name: 'ServiceType' },
          props: true,
        },
        {
          path: 'service',
          name: 'ServiceType',
          meta: {
            isShow: true,
            isLink: true,
            name: 'Сервисы',
          },
          component: () =>
            import(
              /* webpackChunkName: "stack-service-type" */ './pages/Information/views/containerServiceNav.vue'
            ),
          redirect: { name: 'viewStorage' },
          children: [
            {
              path: 'balancer',
              meta: {
                isShow: true,
                isLink: false,
                name: 'Балансировщики',
              },
              component: () =>
                import(
                  /* webpackChunkName: "stack-network-private-view" */ './pages/Information/views/ViewBalancer.vue'
                ),
              name: 'viewBalancer',
              props: true,
            },
            {
              path: 'balancer/new',
              name: 'NewBalancer',
              props: true,

              component: () =>
                import(
                  /* webpackChunkName: "stack-order-balancer" */ './pages/Order/NewBalancer.vue'
                ),
            },
            {
              path: 'balancer/:balancerid',
              component: () =>
                import(
                  /* webpackChunkName: "stack-network-private-view-detail" */ './server/containerBalancer'
                ),
              name: 'Balancer',
              redirect: { name: 'containerRuleStart' },
              props: true,
              meta: {
                isShow: true,
                isLink: true,
                name: 'Балансировщики',
              },
              children: [
                {
                  path: 'rule',
                  component: () =>
                    import(/* webpackChunkName: "stack-rule-view" */ './server/containerRuleStart'),
                  name: 'containerRuleStart',
                  // redirect: { name: 'Rule' },
                  props: true,
                  meta: {
                    isShow: true,
                    isLink: false,
                    name: 'Правила',
                  },
                },
                {
                  path: 'view',
                  name: 'BalancerView',
                  meta: {
                    isShow: true,
                    isLink: false,
                    name: 'Обзор',
                  },
                  component: () =>
                    import(
                      /* webpackChunkName: "stack-subnet-private-info" */ './server/BalancerView'
                    ),
                },
                {
                  path: 'rule/:ruleid',
                  component: () =>
                    import(
                      /* webpackChunkName: "stack-network-firewall-view-detail" */ './server/containerRule'
                    ),
                  name: 'Rule',
                  redirect: { name: 'RuleServers' },
                  props: true,
                  meta: {
                    isShow: true,
                    isLink: false,
                    name: 'Правила',
                  },
                  children: [
                    {
                      path: 'servers',
                      name: 'RuleServers',
                      meta: {
                        isShow: true,
                        isLink: false,
                        name: 'Серверы',
                      },
                      component: () =>
                        import(
                          /* webpackChunkName: "stack-Firewall-info" */ './pages/Main/components/RuleServers'
                        ),
                    },
                    {
                      path: 'algoritm',
                      name: 'RuleAlgorithm',
                      meta: {
                        isShow: true,
                        isLink: false,
                        name: 'Алгоритм',
                      },
                      component: () =>
                        import(
                          /* webpackChunkName: "stack-Firewall-info" */ './pages/Main/components/RuleAlgorithm'
                        ),
                    },
                    {
                      path: 'check',
                      name: 'RuleCheck',
                      meta: {
                        isShow: true,
                        isLink: false,
                        name: 'Проверка',
                      },
                      component: () =>
                        import(
                          /* webpackChunkName: "stack-Firewall-info" */ './pages/Main/components/RuleCheck'
                        ),
                    },
                    {
                      path: 'connections',
                      name: 'RuleConnect',
                      meta: {
                        isShow: true,
                        isLink: false,
                        name: 'Соединения',
                      },
                      component: () =>
                        import(
                          /* webpackChunkName: "stack-Firewall-info" */ './pages/Main/components/RuleConnect'
                        ),
                    },
                    {
                      path: 'headers',
                      name: 'RuleHeaders',
                      meta: {
                        isShow: true,
                        isLink: false,
                        name: 'Заголовки',
                      },
                      component: () =>
                        import(
                          /* webpackChunkName: "stack-Firewall-info" */ './pages/Main/components/RuleHeaders'
                        ),
                    },
                    {
                      path: 'view',
                      name: 'PoolView',
                      meta: {
                        isShow: true,
                        isLink: false,
                        name: 'Обзор',
                      },
                      component: () =>
                        import(
                          /* webpackChunkName: "stack-subnet-private-info" */ './server/PoolView'
                        ),
                    },
                    {
                      path: 'ssl',
                      name: 'RuleSsl',
                      component: () =>
                        import(
                          /* webpackChunkName: "stack-Firewall-info" */ './pages/Main/components/RuleSsl'
                        ),
                    },
                  ],
                },
              ],
            },
            {
              path: 's3',
              name: 'ServiceType',
              meta: {
                isShow: true,
                isLink: true,
                name: 'Контейнеры',
              },
              component: () =>
                import(
                  /* webpackChunkName: "stack-service-type" */ './pages/Information/views/containerS3Nav.vue'
                ),
              redirect: { name: 'viewStorage' },
              children: [
                {
                  path: 'bucket',
                  component: () =>
                    import(
                      /* webpackChunkName: "stack-network-private-view" */ './pages/Information/views/ViewStorage.vue'
                    ),
                  name: 'viewStorage',
                  meta: {
                    isShow: true,
                    isLink: false,
                    name: 'Хранилище S3',
                  },
                  props: true,
                },
                // {
                //   path: 'storage/new',
                //   name: 'NewStorage',
                //   props: true,
                //
                //   component: () =>
                //     import(
                //       /* webpackChunkName: "stack-order-balancer" */ './pages/Order/NewStorage.vue'
                //     ),
                // },
                {
                  path: 'bucket/:storageid',
                  component: () =>
                    import(
                      /* webpackChunkName: "stack-network-private-view-detail" */ './server/containerStorage'
                    ),
                  name: 'Storage',
                  redirect: { name: 'containerObjects' },
                  props: true,
                  meta: {
                    isShow: true,
                    isLink: true,
                    name: 'Хранилище S3',
                  },
                  children: [
                    {
                      path: 'container',
                      component: () =>
                        import(
                          /* webpackChunkName: "stack-rule-view" */ './server/containerObjects'
                        ),
                      name: 'containerObjects',
                      // redirect: { name: 'Rule' },
                      props: true,
                      meta: {
                        isShow: true,
                        isLink: false,
                        name: 'Объекты',
                      },
                    },
                    {
                      path: 'policy',
                      name: 'BucketPolicy',
                      meta: {
                        isShow: true,
                        isLink: false,
                        name: 'Политики доступа',
                      },
                      component: () =>
                        import(
                          /* webpackChunkName: "stack-subnet-private-info" */ './server/BucketPolicy'
                        ),
                    },
                    {
                      path: 'config',
                      name: 'ObjectsView',
                      meta: {
                        isShow: true,
                        isLink: false,
                        name: 'Конфигурация',
                      },
                      component: () =>
                        import(
                          /* webpackChunkName: "stack-subnet-private-info" */ './server/ObjectsView'
                        ),
                    },
                    {
                      path: 'container/:containerid',
                      component: () =>
                        import(
                          /* webpackChunkName: "stack-network-firewall-view-detail" */ './server/ObjectDetails'
                        ),
                      name: 'ObjectDetails',
                      // redirect: { name: 'RuleServers' },
                      props: true,
                      meta: {
                        isShow: true,
                        isLink: false,
                        name: 'Правила',
                      },
                    },
                  ],
                },
                {
                  path: 'view',
                  name: 'S3View',
                  meta: {
                    isShow: true,
                    isLink: false,
                    name: 'Обзор',
                  },
                  component: () =>
                    import(/* webpackChunkName: "stack-subnet-private-info" */ './server/S3View'),
                },
              ],
            },
          ],
        },

        {
          path: 'image',
          name: 'containerImage',
          meta: {
            isShow: true,
            isLink: true,
            name: 'Образы',
          },
          component: () =>
            import(
              /* webpackChunkName: "stack-image" */ './pages/Information/views/containerImage.vue'
            ),
          redirect: { name: 'viewStackImage' },
          props: true,
          children: [
            {
              path: '',
              component: () =>
                import(
                  /* webpackChunkName: "stack-image-view" */ './pages/Information/views/ViewImage.vue'
                ),
              name: 'viewStackImage',
              props: true,
              meta: {
                isShow: true,
                isLink: false,
                name: 'Образы',
              },
            },
          ],
        },
        {
          path: 'access',
          component: () =>
            import(/* webpackChunkName: "stack-info-access" */ './server/containerAccess.vue'),
          name: 'containerAccess',
          redirect: { name: 'viewSsh' },
          props: true,
          meta: {
            isShow: true,
            isLink: true,
            name: 'Инструменты',
          },
          children: [
            {
              path: '',
              component: () =>
                import(
                  /* webpackChunkName: "stack-ssh-view" */ './pages/Information/views/ViewSsh.vue'
                ),
              name: 'viewSsh',
              props: true,
              meta: {
                isShow: true,
                isLink: false,
                name: 'Инструменты',
              },
            },
          ],
        },
        {
          path: 'stat',
          component: () =>
            import(
              /* webpackChunkName: "stack-info-history" */ './pages/Information/views/Statistic.vue'
            ),
          name: 'Statistic',
          meta: {
            isShow: true,
            isLink: false,
            name: 'Статистика',
          },
        },
        {
          path: 'quotas',
          component: () =>
            import(
              /* webpackChunkName: "stack-info-history" */ './pages/Information/views/Quotas.vue'
            ),
          name: 'quotasStack',
          meta: {
            isShow: true,
            isLink: false,
            name: 'Квоты',
          },
        },
      ],
    },
  ],
};
