import { parseDateRu } from '@/utils/parsers/dateParser';
import { flatten } from 'lodash';
import { parseCost } from '@/utils/parsers/costParser';
import { BillMgrMeasure } from './measures';
import { BillMgrStatus } from './status';
// import { VpsAddon } from './VpsAddons';
// import { VpsServer } from '../VM/VpsServer';

// const osMock = {
//   name: 'CentOS 8 64bit',
//   img: 'centos.png',
// };

export class VpsIpTariff extends Object {
  constructor(args) {
    super(args);
    // this._info = null;
    // this._addonsExt = null;
    // this._serverInfo = null;
    // this._history = [];
    if (this.isValid(args)) {
      try {
        this._init(args);
      } catch (e) {
        console.error(e);
      }
    }
  }

  isValid(args) {
    return args.id;
  }

  _init(args) {
    this.id = +args.id;
    this.addonlimit = args.addonlimit;
    this.status = new BillMgrStatus(args.ip_status);
    this.name = args.name;
    this.domain = args.domain || '';
    this.is_main = args.is_main;
    this.type = args.type;
    this.no_delete = args.no_delete;
    this.enable_delete = args.enable_delete;
    this.abusesuspend = args.abusesuspend || 'off';
    if (args.disc) this.disc = new BillMgrMeasure(args.disc.value, args.disc.measure_str) || '';
    // autoprolong
  }

  updateResources(list) {
    list.forEach(i => {
      if (this[i.intname]) this[i.intname].value = i.value;
    });
  }

  update(args) {
    this._init(args);
  }

  get title() {
    return this.domain || this.pricelist;
  }

  get serverInfo() {
    return this._serverInfo;
  }

  set serverState(str) {
    if (!this._serverInfo instanceof VpsServer) throw new Error('Server info is not defined yet');
    this._serverInfo.state = str;
  }

  get serverState() {
    if (!this._serverInfo || !this._serverInfo instanceof VpsServer) return null;
    return this._serverInfo.state;
  }
}
