<template>
  <div>
    <ul v-if="this.$store.getters[`${address}/GET_FILES`].length > 0">
      <li v-for="(tempAttachment, index) in tempAttachments" :key="index">
        <div class="file-details">
          <div class="file-name">
            <div class="file-name__title">
              <p ref="attachmentTitle" class="file-name__text">
                {{ tempAttachment.title }}
              </p>
            </div>
            <div class="file-name__close">
              <button class="file-name__close-btn" @click="removeFile(index)">
                <svg-icon name="close" class="file-name__close-icon" />
              </button>
            </div>
          </div>
          <div class="file-info">
            <span class="uploaded-date"
              >{{
                tempAttachment.size >= 1024 * 1024 * 1024
                  ? (tempAttachment.size / (1024 * 1024 * 1024)).toPrecision(2)
                  : Math.ceil(tempAttachment.size / (1024 * 1024))
              }}
              {{ tempAttachment.size >= 1024 * 1024 * 1024 ? $t('gb') : $t('mb') }}
            </span>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>
// : (tempAttachment.size / (1024 * 1024)).toPrecision(1)

<script>
export default {
  name: 'AttachmentList',
  props: {
    tempAttachments: {
      type: Array,
      default: () => [],
    },
    attachments: {
      type: Array,
      default: () => [],
    },
    address: {
      type: String,
      default: 'moduleSupport',
    },
    length: {
      type: Number,
      default: 40,
    },
  },
  computed: {
    width() {
      return `max-width: ${this.length}px`;
    },
  },
  methods: {
    removeFile(key) {
      this.$store.dispatch(`${this.address}/deleteFiles`, key);
      this.tempAttachments.splice(key, 1);
      this.$emit('remove-attachment');
    },
  },
  beforeRouteLeave(_to, _from, next) {
    this.$store.dispatch(`${this.address}/resetFiles`);
    next();
  },
};
</script>

<i18n>
{
  "ru": {
    "mb": "МБ",
    "gb": "ГБ"
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
}
li {
  margin-bottom: 8px;
  border-radius: 8px;
  margin-right: 8px;
  padding: 0 10px;
}
.file-name {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
  height: 100%;
  &__text{
    overflow:hidden
  }
}
.file-name__title {
  max-width:100%;
  font-size: 13px;
}
.file-name__close {
  padding-top: -20px;
  align-self: flex-start;
  &-btn{
    background: none;
    border: none;
  }
  &-icon{
    color: $active-icon;
  }
}
.uploaded-date {
  font-size: 12px;
  min-width : 10rem;
}
.upload-progress {
  font-size: 12px;
}
.file-info {
  display: flex;
  justify-content: space-between;
  //margin-bottom: 10px;
  margin-bottom: 1rem;
}
</style>
