import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import { hasPermission } from '@/store/utils/permissions';
//pages

import LoginPage from '@/layouts/LoginPage/container.vue';
import ThinkPage from '@/layouts/ThinkPage/container.vue';
import LoginRoutes from '../layouts/LoginPage/route';
import ThinkPageRoutes from '../layouts/ThinkPage/route';
import MainLayout from '@/layouts/MainLayout/MainLayout.vue';
import ErrorLayout from '@/layouts/ErrorLayout/ErrorLayout.vue';
import { RouteVPS } from '@/layouts/VPS/route';
import { RouteBitrix } from '@/layouts/Bitrix/route';
import { RouteVirtual } from '@/layouts/Virtual/route.js';
import { RouteSSL } from '@/layouts/SSL/route.js';
import { RouteCDN } from '@/layouts/CDN/route.js';
// import { RouteLicenses } from '@/layouts/Licenses/route.js';
// import { RouteBackup } from '@/layouts/Backup/route.js';
import { RouteAll } from '@/layouts/All/route.js';
import { RoutePartners } from '@/layouts/Partners/route.js';
import { RouteStartLayout } from '@/layouts/StartLayout/route.js';
import { RouteProfile } from '@/layouts/Profile/route.js';
import { RouteDomains } from '@/layouts/Domains/route.js';
import { RouteBilling } from '@/layouts/Billing/route.js';
import { RouteRent } from '@/layouts/RentServer/route';
import { RouteMo365 } from '@/layouts/MOffice365/route';
import { RouteDev } from '@/layouts/DevSandbox/route.js';
import { RouteStack } from '@/layouts/Stack/route.js';
import { RouteZmail } from '@/layouts/Zmail/route.js';
import { RouteSupport } from '@/layouts/Support/route.js';
import { RouteAcronis } from '@/layouts/CloudsBackup/route';
import { RouteDedic } from '@/layouts/Dedic/route';
import { RouteColo } from '@/layouts/Colo/route';
import { RoutePlesk } from '@/layouts/Plesk/route';
import { RouteVirtuozzo } from '@/layouts/Virtuozzo/route';
import { RouteVhost } from '@/layouts/Vhost/route';
import { RouteIdprotect } from '@/layouts/Idprotect/route';
import { RouteMicrosoft } from '@/layouts/Microsoft/route';
import { RouteSoft } from '@/layouts/Soft/route';
import {
  hostToProvider,
  IS_PROD,
  projectToHost,
  urlToProject,
} from '@/layouts/LoginPage/mixins/currentProvider';
import { RouteAntiDdos } from '@/layouts/AntiDdosGcore/route';
import { RoutePrivate } from '@/layouts/PrivateCloud/route';
import qs from 'qs';
import BaseLoader from '@/components/BaseLoader/BaseLoader';
import { RouteSaas } from '@/layouts/Saas/route';
Vue.use(VueRouter);

// const bmUrl = window.location.href;
// if (bmUrl.includes('?')) {
//   const bmRequest = qs.parse(bmUrl.split('?')[1].split('#')[0]);
//   if (bmRequest.func && bmRequest.key && bmRequest.func === 'register.activate') {
//     // console.log('tadam', bmRequest);
//     sendBillMgrAction({
//       func: bmRequest.func,
//       key: bmRequest.key,
//       obj: bmRequest.obj,
//     }).then(data => {
//       if (data.func && data.func === 'register.activate' && data.ok) {
//         // console.log(data);
//         localStorage.setItem('greating', true);
//       }
//       // window.location.href = window.location.origin + ;
//     });
//   }
// }
function clearUrl() {
  const url = window.location.origin + '/' + window.location.hash;
  localStorage.removeItem('auth');
  window.location.href = url;
}
// if (window.location.hash.includes('func=register&redirect=startpage')) {
// console.log('currentURL', window.location.href.split('?')[1]);
// window.location.search = window.location.hash
// }
// if (IS_PROD) localStorage.setItem('host', hostToProvider[window.location.origin]);
// if (!IS_PROD) localStorage.setItem('host', 'https://bill.rusonyx.ru');
// console.log('1111', window.location);
function getUrl(parseUrl) {
  // console.log(parseUrl);
  const service = {
    vds: 'vds',
    vdc2: 'vdc2',
    vdc3: 'vdc3',
    pleskshared: 'pleskshared',
    rps: 'rps',
    licplesk: 'licplesk',
    licvz: 'licvz',
    zmail: 'zmail',
    cdn: 'cdnedge',
    saas_services: 'saas_services',
    vhost: 'vhost',
    soft: 'soft',
    bitrix_license: 'bitrix_license',
    dummy_ssl_rsnx_oa_migrate: 'ssl',
    dedic: 'dedic',
    colo: 'colocation',
    antiddosgcore: 'antiddosgcore',
    domain: 'domains/order',
    idprotect: 'idprotect',
    microsoft_office: 'microsoft_office',
    private_cloud: 'private_cloud',
    acronis: 'cloudsBackup',
  };
  const search = parseUrl.split('?')[1];
  const redirectAuth = qs.parse(search);
  const url = `${service[redirectAuth.startpage]}/order/${redirectAuth.pricelist}/${
    redirectAuth.period
  }`;
  localStorage.setItem('newUrl', url);
  return url;
}

function parseUrlNewLk() {
  if (window.location.search.includes('startpage')) {
    // window.location.hash = '';
    // window.location.href = window.origin + '/#/' + getUrl(window.location.href);
    window.location.href = localStorage.getItem('urlHash') + getUrl(window.location.href);
  }
}
// parseUrlNewLk();
// console.log(window.location.hash);

if (window.location.hash.includes('usercp')) {
  // console.log(window.location.hash);
  const hash = window.location.hash.split('/')[1];
  // console.log(hash);
  // const hash = window.location.hash.split('/').find(x => x.includes('func'));
  if (hash.includes('auth=')) {
    localStorage.setItem('token', hash.split('=')[1]);
    window.location.href = window.location.origin;
  } else {
    const parseHash = qs.parse(hash);
    // console.log(window.location.hash);
    // console.log(parseHash, hash);
    sendBillMgrAction({
      func: parseHash.func,
      key: parseHash.key,
    }).then(data => {
      localStorage.setItem('token', data.model.auth);
      window.location.href = window.location.origin;
    });
  }
}
const url = window.location.origin + '/#' + window.location.pathname + window.location.search;
localStorage.setItem('urlHash', url);
// console.log('out', window.location.href);
if (
  window.location.href.includes('billmgr?auth') ||
  window.location.href.includes('billmgr?&auth')
) {
  // console.log('in', window.location.href);
  const auth = window.location.search.split('=')[1];
  localStorage.setItem('token', auth);
  clearUrl();
}

// if (window.location.href.includes('sfrom=loginform')) clearUrl();
// if (window.location.href.includes('?func=relogin')) clearUrl();

if (IS_PROD) {
  // console.log('IS_PROD', window.location.href);
  // console.log(window.location.origin);
  // console.log(urlToProject);
  // console.log(urlToProject[window.location.origin]);
  store
    .dispatch('moduleApp/setHost', { elid: urlToProject[window.location.origin] }, { root: true })
    .then(() => {
      localStorage.setItem('project', projectToHost[localStorage.getItem('host')]);
    });
}

const childrens = [
  RouteVPS,
  RouteSaas,
  RouteVirtual,
  RouteSSL,
  RouteCDN,
  // RouteLicenses,
  // RouteBackup,
  RouteZmail,
  RouteVhost,
  RouteStartLayout,
  RouteProfile,
  RouteDomains,
  RouteBilling,
  RouteBitrix,
  RouteAll,
  RoutePartners,
  // RouteServices,
  RouteRent,
  RouteStack,
  RouteMicrosoft,
  RoutePlesk,
  RouteSoft,
  RouteVirtuozzo,
  RouteMo365,
  RouteSupport,
  RouteAcronis,
  RouteAntiDdos,
  RoutePrivate,
  RouteDedic,
  RouteColo,
  RouteIdprotect,
  // RouteOpenStack,
];

if (process.env.NODE_ENV === 'development') childrens.push(RouteDev);

childrens.forEach(item => {
  item.meta = { requiredAuth: true, ...item.meta };
  if (item.children && item.meta.access) {
    item.children.forEach(ch => {
      if (!ch.meta) ch.meta = {};
      if (!ch.meta.access) ch.meta.access = [];
      ch.meta.access.push(...item.meta.access);
    });
  }
});
const routes = [
  {
    path: '/login',
    name: 'Login',
    component: LoginPage,
    redirect: {
      name: 'Auth',
    },
    children: LoginRoutes,
  },
  {
    path: '/thinking',
    name: 'ThinkPage',
    component: ThinkPage,
    redirect: {
      name: 'Success',
    },
    children: ThinkPageRoutes,
  },

  {
    path: '/logout',
    name: 'Logout',
    redirect: {
      name: 'Auth',
    },
  },
  {
    path: '/await',
    component: BaseLoader,
    name: 'Await',
  },
  {
    path: '/',
    name: 'Main-Layout',
    meta: {
      isShow: true,
      isLink: true,
      name: 'Главная ',
      isAuth: true,
    },
    redirect: {
      name: 'start',
    },
    component: MainLayout,
    children: childrens,
  },
  // temp route 'lol'
  // TODO: remove after fix
  {
    path: '/lol',
    name: 'lol',
    redirect: {
      name: 'start',
    },
  },
  {
    path: '/401',
    component: ErrorLayout,
    props: { code: '401' },
  },
  // {
  //   path: '/all',
  //   component: MainLayout,
  //   name: 'All',
  //   props: { code: '401' },
  // },
  {
    path: '*',
    component: ErrorLayout,
    props: { code: '404' },
  },
];

const router = new VueRouter({
  /**
   * @TODO разобраться
   */
  // mode: 'history',
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, _from, savedPosition) {
    if (savedPosition) return savedPosition;
    else if (to.hash) return { selector: to.hash };
    else
      return new Promise(resolve => {
        // sync scroll changing with page animation duration
        setTimeout(() => {
          resolve({ x: 0, y: 0 });
          // resolve({ x: 0, y: 500 });
        }, 100);
      });
  },
});
async function sendBillMgrAction(params) {
  return store.dispatch('sendBillMgrToolAction', params);
}

async function isAuthorized() {
  // console.log('isAuthorized');
  localStorage.removeItem('auth');
  if (IS_PROD) localStorage.setItem('host', hostToProvider[window.location.origin]);
  if (window.location.search.includes('redirect')) {
    const currentUrl = localStorage.getItem('urlHash').slice(1, -1).split('/#/')[1].slice(1, -1);
    // console.log(window.location.search);
    const parseUrl = qs.parse(currentUrl);
    // console.log('parseUrl', parseUrl);
    if (parseUrl && IS_PROD) {
      // console.log('isAuthorized');
      await store.dispatch('authUser', qs.parse(currentUrl, { root: true })).then(() => {
        localStorage.removeItem('urlHash');
        // console.log(data);
      });
      if (IS_PROD) {
        // this.$store.dispatch('moduleStart/fetchDasboardInfo', {func: 'dashboard.services'});

        await store
          .dispatch('moduleStart/fetchDasboardInfo', { func: 'dashboard.info' }, { root: true })
          .then(() => {
            // console.log(data);
          });
        clearUrl();
      }
    }
  }

  if (!localStorage.getItem('token')) {
    localStorage.removeItem('vmrogue');
    return false;
  }

  if (!store.state.moduleProfile.access) {
    // need load user info
    const isAuth = await store
      .dispatch('moduleProfile/setProfileInfo')
      .then(data => {
        // console.log(data.id);
        // window.dataLayer.push({ userId: data.id });
      })
      .catch(e => {
        if (e.type === 'auth') return false;
      });
    return isAuth;
  }

  return true;
}

router.beforeEach(async (to, from, next) => {
  // console.log(to, from);

  const splitted = window.location.search.slice(1);

  const newSplit = splitted.includes('func=register&redirect=')
    ? splitted
    : 'func=register&redirect=' + splitted.replaceAll('=', '%3D');
  // console.log('splitted', splitted);
  // const redirectAuth = qs.parse(splitted);
  const redirectAuth = qs.parse(newSplit);
  // console.log(redirectAuth);
  if (localStorage.getItem('token') && redirectAuth.func === 'auth') {
    // console.log('redirect');
    window.open('/', '_self');
  }
  if (window.location.hash === '#/thinking/success') {
    // console.log(window.location);
    next();
  }
  if (window.location.hash === '#/thinking/error') {
    // console.log(window.location);
    next();
  }
  // if (window.location.hash.includes('#/support/incidents')) {
  //   // console.log(window.location);
  // console.log(window.location.href);
  // next();
  // }

  const service = {
    vdc: 'vdc',
    vdc2: 'vdc2',
    vdc3: 'vdc3',
    zmail: 'zmail',
    pleskshared: 'pleskshared',
    rps: 'rps',
    soft: 'soft',
    vhost: 'vhost',
    licmic: 'licmic',
    licplesk: 'licplesk',
    cdnedge: 'cdn',
    'saas%5Fservices': 'saas_services',
    idprotect: 'idprotect',
    licvz: 'licvz',
    'bitrix%5Flicense': 'bitrix',
    'dummy%5Fssl%5Frsnx%5Foa%5Fmigrate': 'ssl',
    dedic: 'dedic',
    colocation: 'colo',
    antiddosgcore: 'antiddosgcore',
    domain: 'domains/order',
    'microsoft%5Foffice': 'microsoft_office',
    'private%5cloud': 'private_cloud',
  };
  let isAuth = await isAuthorized();
  // console.log(to.query);
  if (!isAuth && to.query.filter_by === 'report' && to.query.value.length > 0) {
    localStorage.setItem('url', window.location.href); //TODO перенести в store
  }
  if (!isAuth && to.query.func === 'register') {
    localStorage.setItem('url', window.location.href); //TODO перенести в store
    next({ path: '/login/reg', replace: true });
  }
  if (!isAuth && localStorage.getItem('greating') === true) {
    next({ path: '/login/', replace: true });
  }
  // if (isAuth && localStorage.getItem('url') && localStorage.getItem('url').includes('incident')) {
  //   const path = '#' + localStorage.getItem('url').split('#')[1];
  //   localStorage.setItem('path', path);
  //   // console.log('path', path);
  //   // const toPath = path.split('?')[0]
  //   const query = qs.parse(path.split('?')[1].split('#')[0]);
  //   // console.log(query);
  //   // localStorage.removeItem('url');
  //   // console.log('removeUrl');
  //   // next({ path: path, query: query, replace: true });
  //   next({ path: '/' + path, replace: true });
  // }
  if (!isAuth && to.name == 'vpsOrderDetails') {
    localStorage.setItem('toPath', to.path);
    next();
  }
  if (isAuth && localStorage.getItem('toPath')) {
    const redirectUrl = localStorage.getItem('toPath');
    localStorage.removeItem('toPath');
    next({ path: redirectUrl, replace: true });
  }
  if (
    isAuth &&
    localStorage.getItem('path') &&
    localStorage.getItem('token') &&
    localStorage.getItem('url') &&
    !localStorage.getItem('url').includes('incident')
  ) {
    // console.log('+++++++++');
    localStorage.removeItem('path');
    // console.log('removePath');
    next();
  }
  if (isAuth && localStorage.getItem('url') && localStorage.getItem('url').includes('incident')) {
    const path = '#' + localStorage.getItem('url').split('#')[1];
    localStorage.setItem('path', path);
    // console.log('path', path);
    // const toPath = path.split('?')[0]
    const query = qs.parse(path.split('?')[1].split('#')[0]);
    // console.log(query);
    localStorage.removeItem('url');
    localStorage.removeItem('path');
    // console.log('removeUrl & path');
    next({ path: path, query: query, replace: true });
    // next({ path: '/' + path, replace: true });
  } else if (isAuth && from.name === 'Auth' && localStorage.getItem('url')) {
    // console.log('url', localStorage.getItem('url'));
    // console.log('1');
    const path = '#' + localStorage.getItem('url').split('#')[1];
    // console.log('path', path);
    localStorage.setItem('path', path);
    localStorage.removeItem('url');
    // console.log('removeUrl');
    next({ path: path, replace: true });
    // } else if (isAuth && (!to.redirectedFrom || to.redirectedFrom !== '/logout')) {
  } else if (isAuth && (!to.redirectedFrom || to.redirectedFrom !== '/logout')) {
    // console.log(to);
    // console.log('2');
    // console.log('333');
    if (
      to.name === 'Auth' &&
      to.hash &&
      to.hash.includes('#/support/incident?filter_by=report&value=')
    ) {
      // console.log('hash', to.hash);
      // console.log('hash', to.fullPath);
      // next({ path: '/' + to.hash, replace: true });
      next({ path: '/' + to.hash, replace: true });
    } else if (to.name === 'Auth') {
      // console.log('auth');
      next({ name: 'start', replace: true });
    } else if (window.location.hash.includes('register')) {
      // console.log('3');
      // console.log('358---', window.location.hash);
      const search = window.location.href.split('?')[1];
      const redirectAuth = qs.parse(search);
      let newFullPath = redirectAuth.redirect
        .split('&')
        .map(x => x.split('=').pop())
        .map(i => (i.indexOf('order') > 0 ? 'order' : i))
        .filter(k => k !== 'client')
        .map(x => (service[x] === undefined ? x : service[x]))
        .join('/')
        .slice(0, -1);
      // заглушка для облаков - предусмотреть доступ к этой услуге
      newFullPath = newFullPath.includes('vdc2') ? '#/cloud/order/plans' : newFullPath;
      newFullPath = newFullPath.includes('vdc3') ? '#/stack/order/plans' : newFullPath;
      // заглушка для облачного бэкапа
      newFullPath = newFullPath.includes('acronis') ? 'cloudsBackup/order' : newFullPath;
      // заглушка для доменов
      newFullPath = newFullPath.includes('domains') ? 'domains/order' : newFullPath;
      newFullPath = newFullPath.includes('private') ? 'private%5Fcloud/order' : newFullPath;
      window.location.hash = '';
      next({ path: '/' + newFullPath, replace: true });
    } else if (!to.meta.access || hasPermission(store.state.moduleProfile.access, to)) {
      // console.log('4');
      // console.log(localStorage.getItem('path'));
      // console.log(to);
      if (to.fullPath.includes('/start#/support/incident?')) {
        const path = to.fullPath;
        // to.fullPath = path.replace('start', '');
        // to.fullPath.replace('start', '');
        // console.log(to);
        // console.log(to.fullPath);

        window.open(to.hash, '_self');
      }
      if (to.fullPath.includes('/start#/billmgr?func=register')) {
        // const path = to.fullPath;
        // to.fullPath = path.replace('start', '');
        // to.fullPath.replace('start', '');
        // console.log(to);
        // console.log(localStorage.getItem('path'));

        localStorage.removeItem('path');
        // console.log(to.fullPath);
        window.open(to.hash, '_self');
      }
      if (
        !!localStorage.getItem('path') &&
        localStorage.getItem('path').includes('#/billmgr?func=register')
      ) {
        const path = localStorage.getItem('path');
        // to.fullPath = path.replace('start', '');
        // to.fullPath.replace('start', '');
        // console.log(to);
        // console.log(localStorage.getItem('path'));

        localStorage.removeItem('path');
        // console.log(to.fullPath);
        window.open(path, '_self');
      }
      next();
    } else {
      // console.log('5');
      next({ path: '/401', replace: true });
    }
  } else if (!to.meta || !to.meta.requiredAuth) {
    // console.log('6');
    next();
  } else {
    if (localStorage.getItem('token') && to.name === 'start') {
      next({ name: 'start', replace: true });
    } else next({ name: 'Auth', replace: true });
  }
});

export default router;
