<template>
  <Toast v-if="!isHide" position="bottom-right" :group="group" ref="container">
    <template #message="slotProps">
      <button
        class="p-toast-icon-close questionnaire-icon-close p-link"
        @click="onCloseClick"
        type="button"
      >
        <span class="p-toast-icon-close-icon pi pi-times"></span>
      </button>
      <div class="flex flex-column">
        <div class="text-center">
          <h4>{{ slotProps.message.summary }}</h4>
          <p>{{ slotProps.message.detail }}</p>
        </div>
        <div class="grid p-fluid">
          <div class="col-6">
            <base-button class="toast-button-questionnaire" label="Yes" @click="setMode()">{{
              $t('button.questionnaire')
            }}</base-button>
          </div>
        </div>
      </div>
    </template>
  </Toast>
</template>

<script>
import Vue from 'vue';
import Toast from 'primevue/toast';
import OrderConfig from '@/components/Configurator/OrderConfig.vue';
import store from '@/store';
import BaseLoader from '@/components/BaseLoader/BaseLoader.vue';
import ToastEventBus from 'primevue/toastservice/ToastEventBus.js';

export default {
  name: 'ToastQuestionnaire',
  data() {
    return {
      configurator: {},
      group: 'questionnaireLk',
    };
  },
  components: {
    Toast,
  },
  props: {},
  computed: {
    isUnsubmitted() {
      let tariffFields = this.tariff._fields;
      return Object.keys(tariffFields).reduce((acc, key) => {
        if (tariffFields[key].type === 'textarea') {
          acc[key] = '$closed';
        } else if (tariffFields[key].type === 'radio') {
          acc[key] = '0';
        } else acc[key] = tariffFields[key].value;
        return acc;
      }, {});
    },
    isHide() {
      return sessionStorage.getItem('QualityLkHide');
    },
    tariff() {
      return this.$store.state.moduleSupport.qualityLk;
    },
  },
  watch: {
    tariff: {
      handler: function (event) {
        this.config(event, { ticket: this.plid, theme: this.theme });
      },
    },
  },
  mounted() {
    this.openToast();
  },
  methods: {
    onCloseClick() {
      const repeat = { key: true };
      // sessionStorage.setItem('QualityLkHide', JSON.stringify(repeat));
      sessionStorage.setItem('QualityLkHide', 'close');
      ToastEventBus.$emit('remove-group', this.group);
    },
    openToast() {
      if (localStorage.getItem('QualityLkHide')) return;
      this.$toast.add({
        label: 'success',
        severity: 'success',
        detail: this.$t('toast.detail'),
        group: this.group,
        closable: false,
        styleClass: 'toast-message-questionnaire',
        contentStyleClass: 'toast-message-questionnaire-content',
        // life: 5000,
      });
    },
    init() {
      return store.dispatch('moduleSupport/setLkQuality');
    },
    showEditModal(props) {
      sessionStorage.setItem('QualityLkHide', 'open');
      return new Promise(async (resolve, reject) => {
        this.showLoadingModal();
        await this.init();
        if (!this.modal) this.makeModal('QualityLk');
        // this.$emit('close-toast', this.message);
        ToastEventBus.$emit('remove-group', this.group);
        let formData = {};
        // const title = 'Уточните вашу оценку.';
        let formDataBackup = null;
        // Vue.set(this.modal, 'title', title);
        Vue.set(this.modal, 'onDismiss', () => {
          this.$modals.close({ name: this.modal.name });
          setTimeout(() => this.showResModal('Вы отменили оценку.', 'Thanks'), 100);
          // this.showResModal('Спасибо за вашу оценку!', 'Thanks');
          // reject({ res: 'thanks' });
        });
        Vue.set(this.modal, 'props', props);
        Vue.set(this.modal, 'props', { period: 12, configurator: this.configurator });
        Vue.set(this.modal, 'text', null);
        Vue.set(this.modal, 'on', {
          init: data => {
            Object.assign(formData, data);
            formDataBackup = { ...formData };
            Vue.set(this.modal.footer.confirm.props, 'loading', false);
          },
          ready: data => {
            formData = this.getUpdatedFormData(formData, data);
            Vue.set(this.modal.footer.confirm.props, 'disabled', false);
          },
          notready: data => {
            formData = this.getUpdatedFormData(formData, data);
            Vue.set(this.modal.footer.confirm.props, 'disabled', true);
          },
          change: data => {
            formData = this.getUpdatedFormData(formData, data);
          },
        });
        Vue.set(this.modal, 'component', OrderConfig);
        Vue.set(this.modal, 'closable', true);
        Vue.set(this.modal, 'closeOnBackdrop', false);
        Vue.set(this.modal, 'footer', {
          confirm: {
            props: {
              title: this.$t('confirm'),
              disabled: true,
              loading: false,
            },
            on: {
              click: () => {
                Vue.set(this.modal.footer.confirm.props, 'loading', true);
                // resolve({ data: formData, res: this.res.next });
                resolve({ data: formData, res: 'send' });
              },
            },
          },
          cancel: {
            props: {
              title: this.$t('cancel'),
              loading: false,
            },
            on: {
              click: () => {
                reject({ res: 'cancel' });
              },
            },
          },
        });
      });
    },
    showLoadingModal() {
      if (!this.modal) this.makeModal('QualityLk');
      Vue.set(this.modal, 'text', null);
      Vue.set(this.modal, 'component', BaseLoader);
      Vue.set(this.modal, 'closable', false);
      Vue.set(this.modal, 'footer', false);
    },
    config(data, payload) {
      this.configurator = this.tariff.makeConfigurator(
        data,
        this.tariff.qualityLk,
        this.tariff.info,
        { ticket: payload.ticket }
      );
    },
    getUpdatedFormData(formData, data) {
      let tData = { ...formData };
      Object.assign(tData, data);
      Object.keys(data).forEach(key => {
        if (data[key] == null && tData.hasOwnProperty(key)) {
          delete tData[key];
        }
      });
      return { ...tData };
    },
    async setMode() {
      let result = '';
      await this.showEditModal({})
        .then(data => {
          result = data;
          return this.$store.dispatch('moduleSupport/sendLkQuality', data.data);
        })
        .then(response => {
          this.$modals.close();
          if (response && response.func && response.ok && response.ok === true) {
            this.showResModal('Спасибо за вашу оценку!', 'Thanks');
          } else
            this.showResModal(
              'Ошибка!\n' +
                'Форма отзыва временно не доступна по техническим причинам, но мы записали вашу оценку обращения.',
              'ErrorForm'
            );
        })
        .catch(e => {
          result = e;
          if (e && e.res) {
            this.$modals.close();
            this.showResModal(this.$t('button.text'), 'Cancel');
          } else
            this.showResModal(
              'Ошибка!\n' +
                'Форма отзыва временно не доступна по техническим причинам, но мы записали вашу оценку обращения.',
              'ErrorForm'
            );
        });
      // if (result.res === 'send')
      //   this.sendBillMgrAction({
      //     func: 'rsnx_lk_quality_report',
      //     auth: localStorage.getItem('token'),
      //     plid: this.plid,
      //     elid: this.elid,
      //   }).then(data => {
      //     this.$emit('reactions', data);
      //   });
    },
    // async sendBillMgrAction(params) {
    //   return store.dispatch('sendBillMgrToolActionLk', params);
    // },
    showResModal(res, name) {
      if (!this.modal) this.makeModal(name);
      Vue.set(this.modal, 'component', null);
      Vue.set(this.modal, 'closable', true);
      Vue.set(this.modal, 'closeOnBackdrop', false);
      Vue.set(this.modal, 'size', 'average');
      Vue.set(this.modal, 'text', res);
      // if (name === 'Cancel') {
      //   Vue.set(this.modal, 'footer', {
      //     centered: true,
      //     confirm: {
      //       props: { title: this.$t('button.later') },
      //       on: {
      //         click: () => {
      //           const repeat = { key: true };
      //           sessionStorage.setItem('QualityLkHide', JSON.stringify(repeat));
      //           this.$modals.close();
      //         },
      //       },
      //     },
      //     cancel: {
      //       props: {
      //         title: this.$t('button.cancel'),
      //       },
      //       on: {
      //         click: () => {
      //           this.$store.dispatch('moduleSupport/sendLkQuality', this.isUnsubmitted);
      //           this.$modals.close();
      //         },
      //       },
      //     },
      //   });
      // } else {
      Vue.set(this.modal, 'footer', {
        centered: true,
        confirm: {
          props: {
            title: this.$t('close'),
          },
          on: {
            click: () => {
              this.$modals.close();
            },
          },
        },
      });
      // }
    },
    makeModal(name) {
      this.$modals.open({
        name: name,
        html: true,
        size: 'big',
        onOpen: inst => (this.modal = inst),
        onClose: () => (this.modal = null),
      });
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "toast": {
      "detail": "Оцените удобство личного кабинета. Это займёт не более 3-х минут."
    },
    "button": {
    "text": " Вы отменили оценку.",
    "questionnaire": " Пройти опрос",
    "later": " Пройти позже",
    "cancel": " Не показывать"
  }
  }
}
</i18n>

<style scoped lang="stylus">

.text-center > p {
  margin: 0.25rem 0 0 0;
}

.questionnaire-icon-close{
  width: 1rem !important;
  justify-self: end;
}

.pi {
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  display: inline-block;
}

.p-toast-icon-close{
  color: white !important;
  height: 100% !important;
  border-radius: 50%;
  background: transparent;
  transition: box-shadow .15s;
}

.toast-button-questionnaire{
  margin: 1.5rem 0 1rem 0;
  background:  white !important;
  color: black !important;
  height: 40px;
}
</style>
