import { AllTools } from '@/models/BillMgr/Tools';
import { AllTariff } from '@/models/BillMgr/AllTariff';
import { BillMgrStatus } from '@/models/BillMgr/status';
import { OPBalancerStatusOperating, OPBalancerStatusProvisioning } from '@/models/OP/OPBalancer';
import { OPTariff } from '@/models/OP/OPTariff';
import { parseDateRu } from '@/utils/parsers/dateParser';
const sortOrders = ['asc', 'desc'];

export const MUTATIONS = {
  SET_LIST: (state, list) => {
    try {
      state.list = [...list];
    } catch (e) {
      console.error(e);
    }
  },
  SET_TARIFFS_LIST: (state, list) => {
    // console.log('setTariff');
    try {
      list.map(i => {
        if (!state.tariffList.map(x => x.id).includes(i.id))
          state.tariffList.push(new AllTariff(i));
      });
    } catch (e) {
      console.error(e);
    }
  },
  UPDATE_TARIFF: (state, payload) => {
    const tariff = payload.data;
    try {
      if (payload.type === 'prolong')
        state.tariffList.find(i => i.id === tariff.id).expiredate = new Date(tariff.expiredate);
      state.tariffList.find(i => i.id === tariff.id).autoprolong_name =
        tariff.autoprolong_name || tariff.autoprolong || 'null';
      state.tariffList.find(i => i.id === tariff.id).autoprolong_period =
        tariff.autoprolong_period || 'null';
      state.tariffList.find(i => i.id === tariff.id).stored_method = tariff.stored_method || 'null';
    } catch (e) {
      console.error(e);
    }
  },
  SET_SERVICES: (state, list) => {
    try {
      state.services = list;
    } catch (e) {
      console.error(e);
    }
  },
  SET_LIST_BLOCK: (state, listPaymentBlock) => {
    try {
      state.listPaymentBlock = listPaymentBlock;
    } catch (e) {
      console.error(e);
    }
  },
  SET_ITEMS: (state, tariff) => {
    try {
      const find = state.tariffList.find(el => el.id === tariff.id);
      if (find) {
        Object.keys(tariff).forEach(key => {
          if (key === 'status') {
            find[key] = new BillMgrStatus(
              tariff.in_pay === 'on'
                ? 6
                : tariff.employeesuspend === 'on'
                ? 8
                : tariff.abusesuspend === 'on'
                ? 7
                : tariff.status
            );
          }
        });
      }
    } catch (e) {
      console.error(e);
    }
  },
  SET_PROLONG: (state, payload) => {
    try {
      state.tariffList.find(x => +x.id === +payload.id).prolong = payload;
    } catch (e) {
      console.error(e);
    }
  },
  SET_MODEL: (state, payload) => {
    try {
      state.tariffList.find(x => +x.id === +payload.id).info = payload.data;
    } catch (e) {
      console.error(e);
    }
  },
  SET_STATE: (state, payload) => {
    try {
      state.state[payload.id] = payload.state;
    } catch (e) {
      console.error(e);
    }
  },
  SET_FLAG: (state, payload) => {
    // console.log('setFlag', payload);
    try {
      state.flag[payload.id] = payload.flag;
    } catch (e) {
      console.error(e);
    }
  },
  SET_TOOLS: (state, value) => {
    try {
      state.tools = new AllTools(value);
    } catch (e) {
      console.error(e);
    }
  },
  SET_CURRENT: (state, payload) => {
    state.current = payload;
  },
  paymethod: (state, payload) => {
    state.paymethod = payload;
  },
  setIsLoading: (state, value) => {
    state.isLoading = value;
  },
  resetList: state => (state.tariffList = []),
  SET_SORT: (state, payload) => {
    if (payload.prop && sortOrders.includes(payload.order)) {
      state.sort = payload;
    }
  },
};
