import { SaasTools, StackTools } from '@/models/BillMgr/Tools';
import Vue from 'vue';
import { SaasTariff } from '@/models/BillMgr/SaasTariff';
import SaasPricelist from '@/models/BillMgr/SaasPricelist';
import { StackTariff } from '@/models/BillMgr/StackTariff';
const sortOrders = ['asc', 'desc'];
const vpsModes = ['grid', 'table'];

export const MUTATIONS = {
  SET_LIST: (state, list) => {
    try {
      // Проверяем, есть ли пересечение по id между state.list и list
      const existingIds = new Set(state.list.map(item => item.id));
      const isIncluded = list.some(i => existingIds.has(+i.id));

      if (isIncluded) {
        // Обновляем существующие элементы
        state.list = state.list.map(item => {
          const newItem = list.find(i => +i.id === item.id);
          if (newItem && item.isDeployMe) {
            return new StackTariff(newItem);
          }
          return item;
        });
      } else {
        // Добавляем новые элементы
        const newListItems = list.map(i => {
          return i.pricelist_id === '8148' ? new StackTariff(i) : new SaasTariff(i);
        });
        state.list = [...state.list, ...newListItems];
      }
    } catch (e) {
      console.error('Ошибка при обновлении списка:', e);
    }

    // const isIncluded = list.some(i => {
    //   return state.list.map(x => x.id).includes(+i.id);
    // });
    // try {
    //   if (isIncluded) {
    //     let arr = [];
    //
    //     state.list.forEach(item => {
    //       list.forEach(i => {
    //         if (item.id == i.id) {
    //           if (item.isDeployMe) {
    //             arr = state.list.map(item => (item.id == i.id ? new StackTariff(i) : item));
    //             state.list = arr;
    //           }
    //         }
    //       });
    //     });
    //   } else {
    //     list.forEach(i => {
    //       if (i.pricelist_id === '8148') state.list.push(new StackTariff(i));
    //       else state.list.push(new SaasTariff(i));
    //     });
    //   }
    // } catch (e) {
    //   console.error(e);
    // }
  },
  setCountDeployMe: (state, count) => {
    state.countOfDeployMe = count;
  },
  reset: state => {
    state.list = [];
    state.countOfDeployMe = 0;
  },
  UPDATE_LIST: (state, { list, type }) => {
    const compareList = list.map(i => ({ id: i.id, isset: false, data: { ...i } }));
    const filteredOldList = state.list.reduce((acc, item) => {
      const compare = compareList.find(i => i.id == item.id);
      if (!!compare) {
        item.update(compare.data);
        acc.push(item);
        compare.isset = true;
      }
      return acc;
    }, []);
    const newList = compareList
      .filter(i => !i.isset)
      .map(i => (type === 'saas' ? new SaasTariff(i.data) : new StackTariff(i.data)));
    state.list = [...state.list, ...newList];
  },
  SET_CURRENT: (state, payload) => {
    state.current = payload;
  },
  SET_OTHER_INFO_ABOUT_TARIFF: (state, { prop, value, id }) => {
    const innerId = id || state.current;
    const tariff = state.list.find(i => i.id === innerId);
    if (tariff && (tariff instanceof SaasTariff || tariff instanceof StackTariff)) {
      Vue.set(tariff, prop, value);
    }
  },
  SET_IS_LOADING: (state, value) => {
    state.isLoading = value;
  },
  SET_SORT: (state, payload) => {
    if (payload.prop && sortOrders.includes(payload.order)) {
      state.sort = payload;
    }
  },
  SET_MODE: (state, value) => {
    if (vpsModes.includes(value)) state.mode = value;
  },
  SET_TOOLS: (state, { data, type }) => {
    try {
      if (type === 'deploy') state.toolsDeployMe = new StackTools(data);
      else state.toolsGitFlic = new SaasTools(data);
    } catch (e) {
      console.error(e);
    }
  },
};
