<template>
  <div v-click-outside="onClickOutside" class="vps-autoprolong">
    <base-loader v-if="isLoad" :size="true" class="vps-autoprolong__trigger"></base-loader>
    <label v-else-if="isAutoprolongNone"></label>
    <v-popover
      v-else
      placement="top"
      :popper-options="popperOptions"
      :auto-hide="true"
      :open="isPopoverOpen"
      trigger="manual"
      popover-class="tariff-card__popover"
    >
      <plain-button
        :icon="state ? icons[state] : 'autoprolong'"
        class="vps-autoprolong__trigger"
        @click="togglePopoverState"
      />
      <template #popover>
        <div ref="popover" class="vps-autoprolong__popover">
          <div class="vps-autoprolong__text standart-text">
            {{
              state
                ? $t(`autoprolong.state.${state}`, {
                    period: currentTariff.setPeriod(autoprolongSettings.value),
                  })
                : 'Выключено'
            }}
          </div>
          <div class="vps-autoprolong__btns">
            <base-button
              size="small"
              class="vps-autoprolong__btn"
              :loading="isLoading"
              @click="onEdit(tariff, state === 'on' ? 'update' : 'on')"
            >
              {{ state === 'on' ? $t('autoprolong.actions.update') : $t('autoprolong.actions.on') }}
            </base-button>
            <base-button
              v-if="state === 'on'"
              theme="outlined"
              size="small"
              class="vps-autoprolong__btn"
              @click="autoprolongAll(tariff, 'off')"
            >
              {{ $t('autoprolong.actions.off') }}
            </base-button>
          </div>
        </div>
      </template>
    </v-popover>
  </div>
</template>

<script>
import ProlongSinglePayments from './ProlongSinglePayments.vue';
import prolongAutoAll from '@/mixins/billmgr/prolongAutoAll';
import { moduleMain } from '@/utils/getServices';
import { AllTariff } from '@/models/BillMgr/AllTariff';
export default {
  name: 'Autoprolong',
  mixins: [prolongAutoAll],
  props: {
    tariff: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      icons: {
        on: 'autoprolong-checked',
        off: 'autoprolong',
      },
      // flag: false, // управляем ообновлением state
      // savedState: '', // сюда подгружаем сохраненный state
      currentTariff: null,
      stopNames: ['vdc2', 'vdc3', 'cdnedge', 'acronis'],
      isLoad: true,
      isPopoverOpen: false,
      popperOptions: {
        modifiers: {
          flip: {
            padding: { top: 60 },
          },
        },
      },
      isLoading: false,
      baseClass: 'vps-autoprolong',
      moduleMain: moduleMain[this.tariff.func],
      singleProlongComponent: ProlongSinglePayments,
    };
  },
  computed: {
    hasAutoprolong() {
      return (
        (this.currentTariff &&
          this.currentTariff.autoprolong_available &&
          this.currentTariff.autoprolong_available === 'on') ||
        (this.tariff &&
          this.tariff.autoprolong_available &&
          this.tariff.autoprolong_available === 'on')
      );
    },
    isAutoprolongNone() {
      return this.stopNames.includes(this.tariff.itemtype || this.tariff.func);
    },
    state() {
      return this.currentTariff &&
        this.currentTariff.autoprolong_available === 'on' &&
        this.currentTariff.autoprolong_name !== 'null'
        ? 'on'
        : 'off';
    },
    current() {
      return this.$store.state.moduleAll.current;
    },
    flag() {
      return this.$store.state.moduleAll.flag;
    },
    savedState() {
      return this.$store.state.moduleAll.state;
    },
    autoprolongSettings() {
      const value = this.currentTariff.autoprolong_period; //|| this.info.model.autoprolong;
      return {
        list: this.currentTariff.slist ? this.currentTariff.slist : [],
        value,
        state: value && value !== 'null' ? 'on' : 'off',
        period: this.currentTariff.autoprolong_name || '',
      };
    },
    autoprolongList() {
      return this.autoprolongSettings.list;
    },
  },
  watch: {
    isLoad: {
      handler: function (oldValue, newValue) {
        if (newValue === true) {
          this.$emit('change', 'true');
        }
      },
      immediate: true,
    },
    payments(event) {
      this.$emit('edit', event); //'Банковская карта'
    },
    state(event) {
      // this.$emit('state', event);
    },
    tariff: {
      handler: function (event) {
        if (event) this.fetch(event);
      },
      immediate: true,
      deep: true,
    },
    savedState: {
      handler: function (event) {
        if (this.tariff.id === this.current && !event) {
          this.$store.dispatch('moduleAll/setAction', { flag: 'clear', current: 'clear' });
          this.fetch(this.tariff);
        } else if (this.tariff.id === this.current && event === 'off') {
          this.$store.dispatch('moduleAll/setAction', {
            state: 'clear',
            flag: 'clear',
            current: 'clear',
          });
          this.fetch(this.tariff);
        } else if (this.tariff.id === this.current && this.flag) {
        } else {
        }
      },
      immediate: true,
    },
    flag: {
      handler: function (event) {},
      immediate: true,
    },
    currentTariff: {
      handler: function (event) {},
      immediate: true,
    },
    hasAutoprolong: {
      handler: function (event) {},
      immediate: true,
    },
  },
  mounted() {
    this.fetch(this.tariff);
  },
  methods: {
    togglePopoverState(value) {
      this.isPopoverOpen = !(value instanceof Event) ? value : !this.isPopoverOpen;
    },
    onClickOutside(e) {
      if (e.usedByTooltip) return;
      if (this.$refs.popover !== e.target.closest(`.${this.baseClass}__popover`)) {
        // здесь убираем из хранилища, если оно заполнено, а оно должно быть заполнено
        // снимаем флаг
        this.togglePopoverState(false);
      }
    },
    fetch(payload) {
      this.currentTariff = new AllTariff(payload);
      this.isLoad = false;
    },
    onEdit(tariff, action) {
      if (this.autoprolongSettings.list.length) {
        this.autoprolongAll(tariff, action);
      } else {
        this.isLoading = true;
        this.$store
          .dispatch('moduleAll/fetchItemDetail', {
            id: tariff.id,
            item: tariff.itemtype || tariff.func,
            silent: true,
            state: true,
          })
          .then(data => {
            // console.log('---edit---', data);
            this.autoprolongAll(tariff, action);
          })
          .finally(() => (this.isLoading = false));
      }
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "autoprolong": {
      "title": "Автопродление",
      "state": {
        "on": "Включено автоматическое продление на {period}",
        "off": "Автоматическое продление выключено"
      },
      "actions": {
        "on": "Включить",
        "off": "Выключить",
        "update": "Изменить"
      },
      "modals": {
        "off": {
          "title": "Отключить автопроделние",
          "desc": "Автопродление для услуги #{id} будет отключено",
          "confirm": "Да, отключить"
        },
        "update": {
          "title": "Изменить настройки автопродления",
          "confirm": "Сохранить"
        },
        "on": {
          "title": "Включить автопродление",
          "confirm": "Включить"
        }
      },
      "result": {
        "success": {
          "title": "Отправлено успешно",
          "desc": "Настройки автопродления для услуги #{id} были успешно изменены",
          "confirm": "Хорошо"
        },
        "error": {
          "title": "Произошла ошибка",
          "desc": "Настройки автопродления для услуги #{id} изменить не удалось. Попробуйте позднее или обратитесь за помощью в поддержку",
          "confirm": "Понятно"
        }
      }
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.vps-autoprolong {
  line-height: 1;

  &__btns {
    margin: 1rem -0.5rem -0.5rem;
    flexy(flex-start, center, nowrap);
  }

  &__btn {
    margin: 0.5rem 0.5rem;
  }

  &__popover {
    max-width: 100%;
    width: calc(100vw - 3.25rem);

    +breakpoint(sm-and-up) {
      width: 260px;
    }
  }
}
</style>
