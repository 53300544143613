<template>
  <div class="signup">
    <transition name="fade" mode="out-in" @after-enter="setFocus()">
      <base-loader v-if="isLoading" class="signup__loader" />
      <div v-else class="signup__content">
        <div v-if="!['4', '5'].includes(provider)" class="signup__form" @keyup.enter="regUser">
          <template v-for="item in fields">
            <base-select
              v-if="item.name === 'country'"
              :key="item.name"
              v-model="item.value"
              :options="countries"
              label="v"
              track-by="k"
              :searchable="true"
              :allow-empty="!item.required"
              :required="item.required"
              :preselect-first="item.required"
              :placeholder="$t('fields.placeholder')"
              :show-labels="false"
              class="signup__field"
              @select="onChangeCountry"
            >
              {{ $t(`fields.${item.name}.label`) }}
              <template v-if="$te(`fields.${item.name}.note`)" #after>
                {{ $t(`fields.${item.name}.note`) }}
              </template>
            </base-select>
            <base-select
              v-else-if="item.name === 'state' && filtredState.length"
              :key="item.name"
              v-model="item.value"
              :options="filtredState"
              label="v"
              track-by="k"
              :searchable="true"
              :allow-empty="true"
              :show-labels="false"
              :required="false"
              :placeholder="$t('fields.placeholder')"
              :preselect-first="item.required"
              class="signup__field"
            >
              {{ $t(`fields.${item.name}.label`) }}
            </base-select>
            <base-input
              v-else-if="item.name !== 'policy' && item.name !== 'state'"
              :ref="item.name"
              :key="item.name"
              v-model="item.value"
              :name="item.name"
              :type="item.type"
              :show-warning="item.type === 'email'"
              :required="item.required || item.type === 'tel'"
              :invalid="item.invalid"
              :tooltip="item.tooltip || null"
              use-reactive-validation
              :mask="item.type === 'tel' ? '+7 (999) 999-99-99' : ''"
              class="signup__field"
              :class="classes(item)"
              @focus="onFocus(item)"
              @blur="onBlur(item)"
              @input="onChange(item, $event)"
            >
              {{ $t(`fields.${item.name}.label`) }}
              <template v-if="item.name === 'passwd'" #icon>
                <plain-button icon="key" color="dark" tabindex="-1" @click="generatePass(item)" />
              </template>
              <template v-if="item.name === 'passwd'" #fieldAfter>
                <plain-button
                  :icon="item.type === 'text' ? 'eye-closed' : 'eye-open'"
                  color="dark"
                  tabindex="-1"
                  @click="showPassword(item.name)"
                />
              </template>
              <template v-if="item.name === 'passwd' && secureLevel !== 'none'" #labelEnd>
                <span :class="`${secureColors[secureLevel]}-color`">
                  {{ $t(`security.${secureLevel}`) }}
                </span>
              </template>
            </base-input>
          </template>
          <policy
            v-if="policy.required"
            :policy="policy"
            :name="policy.name"
            :required="policy.required || false"
            :label="policy.label"
            value=""
            class="signup__field"
            use-reactive-validation
            @change="onChange('policy', $event)"
          >
          </policy>
          <policy
            v-if="oferta.required"
            :policy="oferta"
            :name="oferta.name"
            :required="oferta.required || false"
            :label="oferta.label"
            value=""
            class="signup__field"
            use-reactive-validation
            @change="onChange('oferta', $event)"
          >
          </policy>
        </div>

        <div v-if="['4', '5'].includes(provider)" class="signup__form">
          <p v-html="text"></p>
        </div>
        <div v-if="error" class="signup__error standart-text error-color">
          {{ error }}
        </div>
        <div class="signup__footer">
          <base-button
            v-if="['4', '5'].includes(provider)"
            :loading="isSending"
            class="signup__submit"
            @click="link"
            @keyup.enter.stop
          >
            {{ $t('submitR') }}
          </base-button>
          <base-button
            v-else
            :disabled="!isValid"
            :loading="isSending"
            class="signup__submit"
            @click="regUser"
            @keyup.enter.stop
          >
            {{ $t('submit') }}
          </base-button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import BaseInput from '@/components/BaseInput/BaseInput';
import BaseSelect from '@/components/Select/BaseSelect';
import { isObject } from 'lodash';
import setFocus from '@/mixins/setFocus';
import showSuccess from '../mixins/showSuccess';
import generatePassword from '@/mixins/generatePassword';
import Policy from '@/components/Policy/Policy';
import { currentProvider, findAllByKey, IS_PROD } from '@/layouts/LoginPage/mixins/currentProvider';
export default {
  name: 'Auth',
  components: {
    BaseInput,
    BaseSelect,
    Policy,
  },
  mixins: [setFocus, showSuccess, generatePassword],
  data() {
    return {
      country: 'Российская федерация',
      state: '',
      partner: '',
      isLoading: true,
      isSending: false,
      error: '',
      fields: [
        {
          name: 'email',
          type: 'email',
          invalid: false,
          value: '',
        },
        {
          name: 'passwd',
          type: 'password',
          invalid: false,
          value: '',
          tooltip: this.$t('fields.passwd.tooltip'),
        },
        {
          name: 'realname',
          type: 'text',
          invalid: false,
          value: '',
        },
        {
          name: 'phone',
          type: 'tel',
          invalid: false,
          value: '',
        },
      ],
      policy: {},
      oferta: {
        type: 'checkbox',
        name: 'oferta',
        required: 'yes',
        show_required: 'yes',
        link_url: 'https://www.rusonyx.ru/support/dokumenty/',
        link_msg: 'Подробнее...',
        label: 'Я ознакомлен и принимаю оферту ООО "Астра Облако"',
        hint: '',
        value: 'off',
        desc: '',
      },
      countries: [],
      states: [],
    };
  },
  computed: {
    isValid() {
      const validatedFields =
        !this.fields[5].value && this.filtredState.length === 0
          ? this.fields.slice(0, 5)
          : this.fields;
      // console.log(validatedFields);
      // return this.fields.every(i => !i.required || (i.required && i.value));
      return (
        validatedFields.every(i => !i.required || (i.required && i.value)) &&
        this.oferta.value === 'on' &&
        this.policy.value === 'on' &&
        this.secureLevel === 'high' &&
        /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.fields.find(x => x.name === 'email').value)
      );
    },
    currentField() {
      return findAllByKey(currentProvider, this.currentHost).toString();
    },
    currentHost() {
      return IS_PROD ? localStorage.getItem('host') : this.$store.state.moduleApp.host;
    },
    currentCountry() {
      return this.fields.find(x => x.name === 'country').value.k;
    },
    provider() {
      if (IS_PROD) {
        if (this.currentHost.includes('infobox')) {
          return '5';
        } else if (this.currentHost.includes('zenon')) {
          return '4';
        } else if (this.currentHost.includes('astracloud')) {
          return '6';
        } else if (this.currentHost.includes('rusonyx')) {
          return '3';
        } else if (this.currentHost.includes('caravan')) {
          return '2';
        } else return '3';
      } else {
        return this.$store.state.moduleProviders.current
          ? this.$store.state.moduleProviders.current
          : null;
      }
    },

    text() {
      return `Компания ${
        this.provider === '4' ? 'ООО "ЗЕНОН Н.С.П."' : 'ООО "Национальные Телекоммуникации"'
      }
       // находится в процессе  <a href="${
         this.provider === '4'
           ? 'https://www.rusonyx.ru/blog/post/reorganizaciya-kompaniy-rusoniks/'
           : 'https://www.rusonyx.ru/blog/post/reorganizaciya-kompaniy-rusoniks/'
       }" target="_blank">реорганизации</a>
       в форме присоединения к ООО "Русоникс".`;
    },
    filtredState() {
      return this.states.filter(x => x.depend === this.currentCountry);
    },
    // partner() {
    // return this.$route.query.value;
    // },
  },
  mounted() {
    this.fetch();
    this.partner =
      this.$route.query && this.$route.query.partner ? this.$route.query.partner : null;
    // console.log(this.$route.query.partner);
  },
  methods: {
    classes(item) {
      const base = 'signup__field';
      return {
        // [`${base}--warning`]: item.type === 'password',
        [`${base}--warning`]: item.name === 'passwd',
      };
    },
    showPassword(item) {
      if (item === 'passwd' && this.fields.find(x => x.name === 'passwd').type === 'password') {
        this.fields.find(x => x.name === 'passwd').type = 'text';
      } else if (item === 'passwd' && this.fields.find(x => x.name === 'passwd').type === 'text') {
        this.fields.find(x => x.name === 'passwd').type = 'password';
      }
    },
    onChangeCountry() {
      this.fields[5].value = undefined;
    },
    link() {
      //https://my.rusonyx.ru/#/login/reg
      // return console.log('link');
      window.open('https://my.rusonyx.ru/#/login/reg');
    },
    onChange(item, e) {
      if (item.name === 'phone') {
        let res = '';
        res = '+7' + this.fields.find(x => x.name === 'phone').value.slice(1);
        this.fields.find(x => x.name === 'phone').value = res;
      }
      const { value } = e;
      if (item === 'policy') {
        this.policy.value = value;
      }
    },
    regUser() {
      if (!this.isValid) return false;
      this.isSending = true;
      const formData = this.fields.reduce((acc, item) => {
        if (isObject(item.value) && item.value.k) acc[item.name] = item.value.k;
        else if (item.value) acc[item.name] = item.value;
        return acc;
      }, {});
      // вытаскиваем поле полльзовательского соглашения, если оно включено, в противном - пусто
      const field =
        Object.keys(this.policy).length !== 0
          ? Object.fromEntries([[this.currentField, this.policy.value]])
          : null;
      const payload = {
        ...formData,
        sok: 'ok',
        func: 'register',
        ...field,
      };
      if (this.partner) payload.partner = this.partner;
      this.$store
        .dispatch('send', payload)
        .then(() => {
          this.showSuccess();
          // this.$gtm.trackEvent({
          //   event: '_event_registration', // Event type [default = 'interaction'] (Optional)
          //   category: 'Login',
          //   action: 'click',
          //   label: 'Authenticator',
          //   value: 5000,
          //   noninteraction: false, // Optional
          // });
        })
        .catch(e => {
          this.error = e.msg;
          this.fields.forEach(i => {
            if (i.name === e.object) i.invalid = true;
            else i.invalid = false;
          });
        })
        .finally(() => (this.isSending = false));
    },
    // gtm() {
    // this.$gtm.trackEvent({
    //   event: '_event_registration', // Event type [default = 'interaction'] (Optional)
    //   category: 'Login',
    //   action: 'click',
    //   label: 'Authenticator',
    //   value: 5000,
    //   noninteraction: false, // Optional
    // });
    // },
    fetch() {
      this.isLoading = true;
      this.$store
        .dispatch('fetch', 'register')
        .then(data => {
          const { required, slist } = data;
          // выгружаем пользовательское соглашение
          const policy = data.customfields ? data.customfields[this.currentField] : null;
          this.policy = { ...policy };
          if (slist && slist.country) this.addCountryField(slist.country);
          if (slist && slist.state) this.addStateField(slist.state);
          if (required) this.setRequiredFields(required);
        })
        .finally(() => (this.isLoading = false));
    },
    setRequiredFields(obj) {
      this.fields.forEach(i => {
        if (obj[i.name] && obj[i.name] === 'true') i.required = true;
      });
    },
    addCountryField(list) {
      const data = list.reduce((acc, item) => {
        if (!acc.list) acc.list = [];
        if (item.k !== 'null') acc.list.push(item);
        if (item.v === this.$t('fields.country.default')) acc.value = item;
        return acc;
      }, {});
      this.countries = data.list;
      this.fields.push({
        name: 'country',
        type: 'select',
        value: data.value,
      });
    },
    addStateField(list) {
      const data = list.reduce((acc, item) => {
        if (!acc.list) acc.list = [];
        if (item.k !== 'null') acc.list.push(item);
        if (item.v === this.$t('fields.state.default')) acc.value = item;
        return acc;
      }, {});
      this.states = data.list;
      this.fields.push({
        name: 'state',
        type: 'select',
        value: data.value,
      });
    },
  },
};
</script>

<i18n>
  {
    "ru": {
      "fields": {
        "placeholder": "Введите название",
        "realname": {
          "label": "ФИО"
        },
        "email": {
          "label": "Email"
        },
        "phone": {
          "label": "Телефон"
        },
        "passwd": {
          "label": "Пароль",
          "tooltip": "Сгенерировать надежный пароль"
        },
        "country": {
          "label": "Страна",
          "note": "Укажите страну правильно! Выбор страны влияет на ставку налогообложения при оплате",
          "default": "Российская Федерация"
        },
        "state":{
          "label": "Регион",
          "default": " "
        }
      },
      "submit": "Зарегистрироваться",
      "submitR": "Зарегистрироваться в Русоникс",
      "success": {
        "title": "Подтвердите email",
        "text": "Запрос успешно отправлен, для окончания регистрации перейдите по ссылке в письме, отправленном на указанный email.",
        "ok": "Хорошо"
      }
    }
  }
</i18n>

<style lang="stylus" scoped>
.signup {
  &__field {
    &--warning{
      margin-top: 3.25rem !important;
    }
    & + & {
      margin-top: 1.25rem;
    }
  }
  &__footer {
    margin-top: 2.5rem;
    text-align: center;
  }
  &__submit {
    min-width: 165px;
  }
  &__error {
    margin-top: 1.25rem;
  }
}
</style>
