import { parseDateRu } from '@/utils/parsers/dateParser';
import { parseCost } from '@/utils/parsers/costParser';
import BaseConfigurator from '../base/BaseConfigurator';
import { BillMgrAddon } from './BillMgrAddon';
import { BillMgrStatus, BillMgrStatusSpecial } from './status';

// export class CloudTariff extends Object {
//   constructor(args) {
//     super(args);
//     if (this.isValid(args)) this._init(args);
//   }
export class CloudTariff extends BaseConfigurator {
  constructor(args) {
    super(args, BillMgrAddon);
    if (this.isValid(args)) this._init(args);
  }

  isValid(args) {
    return args.id && args.pricelist && args.status;
  }

  _init(args) {
    this.id = +args.id;
    this.abusesuspend = args.abusesuspend;
    this.employeesuspend = args.employeesuspend;
    this.autosuspend = args.autosuspend;
    this.change_pricelist = args.change_pricelist;
    this.name = args.name;
    this.costsuffix = args.cost_suffix;
    this.activationflag = args.activationflag === 'Вкл.' ? 'on' : 'off';
    this.pricelist = args.pricelist;
    this.cost = parseCost(args.cost);
    this.full_cost = parseCost(args.full_cost);
    this.period = args.period;
    this.datacentername = args.datacentername;
    this.expiredate = args.expiredate;
    this.notify_treshhold = args.notify_treshhold;
    this.createdate = Date.parse(args.createdate) || parseDateRu(args.createdate);
    this.in_pay = args.in_pay;
    this.hourly_can = args.hourly_can || '';
    this.order_disabled = args.order_disabled;
    this.prolong_available = args.prolong_available;
    this.stat_enabled = args.stat_enabled;
    this.subaccount = args.subaccount;
    this.sync_feature = args.sync_feature;
    this.transition = args.transition;
    // this.status = new BillMgrStatus(args.status);
    this.status = new BillMgrStatus(
      this.employeesuspend === 'on' ? 8 : this.abusesuspend === 'on' ? 7 : args.status
    );
    this.specialstatus = new BillMgrStatusSpecial(args.specialstatus);
    this._info = null;
    this._serverInfo = null;
    this._history = [];
    // autoprolong
    this.autoprolong_available = args.autoprolong_available;
  }

  update(args) {
    this._init(args);
  }

  get specs() {
    return {
      datacentername: this.datacentername,
      cost: this.cost,
    };
  }

  get title() {
    return this.name;
  }

  get hasPanel() {
    if (!this.info || !this.info.customfields) return false;
    const { username, userpassword } = this.info.customfields;
    const vals = { yes: true, no: false };
    return username && vals[username.added] && userpassword && vals[userpassword.added];
  }

  get allow_processing_prolong() {
    return this.info && this.info.model && this.info.model.allow_processing_prolong
      ? this.info.model.allow_processing_prolong
      : this.prolong_available
      ? this.prolong_available
      : 'off';
  }
  get prolongAvailable() {
    if (this.employeesuspend === 'on' || this.abusesuspend === 'on') return false;
    return this.prolong_available ? this.prolong_available === 'on' : false;
  }

  get autoprolongAvailable() {
    if (this.employeesuspend === 'on' || this.abusesuspend === 'on') return false;
    return this.autoprolong_available ? this.autoprolong_available === 'on' : false;
  }

  get autoprolongSettings() {
    const value = this.autoprolong_period || this.info.model.autoprolong;
    return {
      list:
        this.info && this.info.model && this.info.slist.autoprolong
          ? this.info.slist.autoprolong
          : [],
      value,
      state: value && value !== 'null' ? 'on' : 'off',
      period: this.autoprolong_name || '',
    };
  }

  set info({ customfields, slist, model, groups, details }) {
    const hidden = [
      'needreboot',
      'panelid',
      'panels',
      'password',
      'reboot',
      'username',
      'userpassword',
      'applicense',
      'appsecretkey',
    ];
    const addons = details.addon.reduce((acc, i) => {
      acc[`addon_${i.id}`] = i;
      return acc;
    }, {});
    this._info = {
      customfields,
      slist,
      model,
      details: {
        main: details.main,
        addons,
      },
    };
  }

  get info() {
    return this._info;
  }
  get addons() {
    if (!this.info || !this.info.details || !this.info.details.addons) return null;
    return this.info.details.addons;
  }

  set history(elem) {
    this._history = elem;
  }

  get history() {
    return this._history;
  }
}
