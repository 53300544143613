import BillMgrApi from '@/services/api/BillMgrApi';
import { formDataParser, parserMultipart } from '@/utils/parsers/formDataParser';
import CrmApi from '@/services/api/CrmApi';

export const ACTIONS = {
  fetchAllTickets({ commit }) {
    // commit('SET_IS_LOADING', true);
    const params = {
      func: 'clientticket',
      p_cnt: 1000,
    };
    return new Promise((rs, rj) => {
      BillMgrApi.get('', { params })
        .then(data => {
          // исправление косяка с выводом
          if (data.elem)
            commit(
              'SET_ALL_TICKETS',
              data.elem.filter(status => status.alt_status !== '6')
            );
          if (data.elem)
            commit(
              'SET_NEW_TICKETS',
              data.elem.filter(status => status.tstatus_orig === '100_client')
            );
          rs(data);
        })
        .catch(err => rj(err))
        .finally(() => {
          commit('SET_IS_LOADING', false);
        });
    });
  },
  fetchTicketsFilter({ commit }) {
    // commit('SET_IS_LOADING', true);
    const params = {
      func: 'clientticket.filter',
      newface: 'on',
      abuse: null,
      create_user: null,
      currdate: '',
      id: '',
      incident: null,
      message: '',
      message_post: 'other',
      message_postend: '',
      message_poststart: '',
      name: '',
      saved_filters: '',
      tax_hidden: '',
      sok: 'ok',
      out: 'bjson',
      auth: localStorage.getItem('token'),
      // p_cnt: 1000,
    };
    const formData = formDataParser(params);
    return new Promise((rs, rj) => {
      BillMgrApi.post('/billmgr', formData)
        .then(data => {
          rs(data);
        })
        .catch(err => rj(err))
        .finally(() => {
          // commit('SET_IS_LOADING', false);
        });
    });
  },
  fetchAllIncedent({ commit }) {
    // commit('SET_IS_LOADING', true);
    const params = {
      func: 'incident_docs',
      p_cnt: 1000,
    };
    return new Promise((rs, rj) => {
      BillMgrApi.get('', { params })
        .then(data => {
          // исправление косяка с выводом
          if (data.elem)
            commit(
              'SET_ALL_INCIDENT',
              data.elem
              // data.content.filter(status => status.alt_status !== '6')
            );
          rs(data);
        })
        .catch(err => rj(err))
        .finally(() => {
          commit('SET_IS_LOADING', false);
        });
    });
  },
  fetchAllTicketsArhive({ commit }) {
    const params = {
      func: 'clientticket_archive',
    };
    return new Promise((rs, rj) => {
      BillMgrApi.get('', { params })
        .then(data => {
          // console.log(data);
          if (data?.elem) commit('GET_ALL_TICKETS_ARHIVE', data.elem);
          rs(data);
        })
        .catch(err => rj(err));
    });
  },
  setQuality({ commit }) {
    const params = {
      func: 'rsnx_support_quality_report',
      show_metadata: 'on',
      newface: 'on',
    };
    return new Promise((resolve, reject) => {
      BillMgrApi.get('/billmgr', { params })
        .then(data => {
          commit('SET_QUALITY', data.model);
          commit('UPDATE_INFO', { prop: 'quality', value: data.model });
          if (data.fields) commit('UPDATE_INFO', { prop: 'fields', value: data.fields });
          if (data.slist) commit('UPDATE_INFO', { prop: 'slist', value: data.slist });
          resolve(data);
        })
        .catch(e => reject(e));
    });
  },
  setLkQuality({ commit }) {
    const params = {
      func: 'rsnx_lk_quality_report',
      show_metadata: 'on',
      newface: 'on',
    };
    return new Promise((resolve, reject) => {
      BillMgrApi.get('/billmgr', { params })
        .then(data => {
          commit('SET_QUALITY_LK', data.model);
          commit('UPDATE_INFO_LK', { prop: 'qualityLk', value: data.model });
          if (data.fields) commit('UPDATE_INFO_LK', { prop: 'fields', value: data.fields });
          if (data.slist) commit('UPDATE_INFO_LK', { prop: 'slist', value: data.slist });
          resolve(data);
        })
        .catch(e => reject(e));
    });
  },
  // setQuality({ commit }) {
  //   const params = {
  //     func: 'rsnx_support_quality_report',
  //     show_metadata: 'on',
  //     newface: 'on',
  //   };
  //   return new Promise((rs, rj) => {
  //     BillMgrApi.get('', { params })
  //       .then(data => {
  //         console.log(data);
  //         commit('SET_QUALITY', data);
  //         const { slist, required, model } = data;
  //         console.log(slist, required, model);
  //         commit('SET_OTHER_INFO_ABOUT_TARIFF', {
  //           prop: 'info',
  //           value: {
  //             slist,
  //             required,
  //             model,
  //           },
  //         });
  //         rs(data);
  //       })
  //       .catch(err => rj(err));
  //   });
  // },
  fetchALLNotifications({ commit }) {
    const params = {
      func: 'notification',
    };
    return new Promise((rs, rj) => {
      BillMgrApi.get('', { params })
        .then(data => {
          commit('GET_ALL_NOTIFICATIONS', data.elem);
          rs(data.elem);
        })
        .catch(err => rj(err));
    });
  },
  // запрос загружает данные в стор - текущий тикет, id, тему
  fetchCurrentTicket({ commit }, id) {
    const params = {
      func: 'clientticket.edit',
      elid: id,
      newface: 'on',
      show_metadata: 'on',
      out: 'bjson',
    };
    return new Promise((rs, rj) => {
      BillMgrApi.get('', { params })
        .then(data => {
          commit('SET_CURRENT_TICKET', data.mlist.history);
          commit('SET_CURRENT_TICKET_THEME', data.model.subject);
          commit('SET_CURRENT_TICKET_ID', id);
          commit('SET_CURRENT_STATUS', data.model.alt_status);
          // commit('toArhive', data.model.tstatus);
          commit('toArhive', data.model.toarchive);
          rs(data);
        })
        .catch(err => rj(err))
        .finally(() => {
          commit('SET_IS_LOADING', false);
        });
    });
  },
  sendQuality(_ctx, payload) {
    const params = {
      ...payload,
      auth: localStorage.getItem('token'),
      func: 'rsnx_support_quality_report',
      sok: 'ok',
      elid: '',
      clicked_button: 'ok',
      out: 'bjson',
    };
    const formData = formDataParser(params);
    const decode = parserMultipart(formData);

    return BillMgrApi.post('/billmgr', decode, {
      // return BillMgrApi.post('/billmgr', formData, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
    });
  },
  sendLkQuality(_ctx, payload) {
    const params = {
      ...payload,
      auth: localStorage.getItem('token'),
      func: 'rsnx_lk_quality_report',
      sok: 'ok',
      elid: '',
      clicked_button: 'ok',
      out: 'bjson',
    };
    const formData = formDataParser(params);
    const decode = parserMultipart(formData);

    return BillMgrApi.post('/billmgr', decode, {
      // return BillMgrApi.post('/billmgr', formData, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
    });
  },
  sendMessageArh(_ctx, payload) {
    const params = {
      ...payload,
      auth: localStorage.getItem('token'),
      sok: 'ok',
    };
    const formData = formDataParser(params);
    return BillMgrApi.post('/billmgr', formData);
  },
  sendMessage(_ctx, payload) {
    return new Promise((rs, rj) => {
      BillMgrApi.post('/billmgr', payload)
        .then(data => {
          rs(data);
        })
        .catch(err => {
          rj(err);
        });
    });
  },
  sendMessageCrm(_ctx, payload) {
    return new Promise((rs, rj) => {
      // CrmApi.post('/0/rest/GstCaseService/CreateCase', payload, {
      CrmApi.post('ServiceModel/GstCaseService.svc/CreateCase', payload, {
        // headers: { BPMCSRF: 'GEHQPe9vi903sjH1GAHT5u' },
      })
        .then(data => {
          rs(data);
        })
        .catch(err => {
          rj(err);
        });
    });
  },
  // http://31.130.202.5:535/0/rest/GstCaseService/CreateCase
  addFiles({ commit }, payload) {
    commit('SET_FILES', payload);
  },
  deleteFiles({ commit }, payload) {
    commit('DELETE_FILES', payload);
  },
  resetFiles({ commit }) {
    commit('RESET_FILES');
  },
  fetchServiceList({ commit }) {
    const params = {
      func: 'clientticket.edit',
    };
    return new Promise((rs, rj) => {
      BillMgrApi.get('', { params })
        .then(data => {
          commit('SET_SERVICE_LIST', data.slist.ticket_item);
          commit('SET_DEPARTMENT', data.slist.client_department);
          rs(data => console.log(data.slist.ticket_item, 'data'));
        })
        .catch(err => rj(err));
    });
  },
  fetchCurrentNotification({ commit }, id) {
    const params = {
      func: 'notification.view.result',
      elid: id,
    };
    return new Promise((rs, rj) => {
      BillMgrApi.get('', { params })
        .then(data => {
          commit('SET_CURRENT_NOTIFICATION', data.model.body);
          rs(data => console.log(data));
        })
        .catch(err => rj(err));
    });
  },
  reset({ commit }) {
    commit('SET_CURRENT_TICKET', []);
    commit('SET_NEW_TICKETS', []);
    commit('SET_ALL_INCIDENT', []);
  },
};
